import React, { useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

const GET_GRADES = gql`
  query MyQuery($turma: Int) {
    disciplina_grades(where: { turma: { id: { _eq: $turma } } }) {
      id
      grade
      attempts
      person {
        id
        first_name
        last_name
      }
      disciplina {
        name
        id
      }
    }
  }
`;

const GradesMonitors = () => {
  const store = useContext(StoreContext);
  var variables = { turma: store.ui.turma.id };
  const { data, loading } = useQuery(GET_GRADES, {
    variables: variables,
  });

  if (loading) {
    return <div>Carregando...</div>;
  }

  const allSubjects = new Set();

  data.disciplina_grades.forEach((grade) => {
    allSubjects.add(grade.disciplina.name);
  });

  const columns = [
    { name: "Nome", options: { filter: true, sort: true } },
    ...Array.from(allSubjects).map((subject) => ({
      name: subject,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const grade = value?.grade;
          const color = grade < 7 ? "red" : "black";
          const attempts = value?.attempts;
          const attemptsStr =
            attempts !== undefined ? ` (${attempts}/${3})` : "";
          return (
            <p style={{ color, fontWeight: "bold" }}>
              {grade ? grade + attemptsStr : "-"}
            </p>
          );
        },
      },
    })),
  ];

  const students = [
    ...new Set(
      data.disciplina_grades
        .filter((grade) => grade.person)
        .map((grade) => `${grade.person.first_name} ${grade.person.last_name}`)
    ),
  ];
  const rows = students.map((student) => {
    const studentGrades = data.disciplina_grades.filter(
      (grade) =>
        grade.person &&
        `${grade.person.first_name} ${grade.person.last_name}` === student
    );

    const row = { Nome: student };

    studentGrades.forEach((grade) => {
      if (grade.person) {
        row[grade.disciplina.name] = {
          grade: grade.grade,
          attempts: grade.attempts,
        };
      }
    });

    return row;
  });
  const theme = createTheme({
    typography: {
      useNextVariants: true,
      h6: {
        color: "#801819",
        fontWeight: "bold",
      },
    },
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: "400px",
        },
      },
    },
  });

  const options = {
    responsive: "scroll",
    rowsPerPageOptions: [10, 50, 200],
    rowsPerPage: 200,
    selectableRows: false,
    filterType: "multiselect",
    sortOrder: {
      name: "Nome",
      direction: "asc",
    },
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable
        title="Notas dos seus monitorandos"
        data={rows}
        columns={columns}
        options={options}
      />
    </MuiThemeProvider>
  );
};

export default GradesMonitors;
