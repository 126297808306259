import { createTheme } from "@material-ui/core/styles";

const palette = {
  primary: { main: "#801819" },
  secondary: { main: "#FBB20D" },
};
const themeName = "Fern Pizazz Bush Dog";

export default createTheme({
  palette,
  themeName,
  typography: {
    useNextVariants: true
  }
});
