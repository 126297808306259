import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { History } from "history";

const defaultToolbarStyles = {
  iconButton: {},
  deleteIcon: {}
};

interface ThemeClasses {
  iconButton: string;
  deleteIcon: string;
}

interface CustomToolbarProps {
  classes: ThemeClasses;
  history: History;
}

class CustomToolbar extends React.Component<CustomToolbarProps> {
  handleClick = () => {
    console.log("clicked on icon!");
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"adicionar aluno"}>
          <IconButton
            className={classes.iconButton}
            onClick={() => this.props.history.push("/edit/")}
          >
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
