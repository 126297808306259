/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../store";
import {
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  Checkbox,
  Paper,
  Dialog,
  Tooltip,
} from "@material-ui/core";
import gql from "graphql-tag";
import SavedSnack from "./SavedSnack";
import "../css/AddAttendances.css";
import AttendanceCheck from "./AttendanceCheck";

const query = gql`
  query getAttendances($turma: Int, $lesson: Int) {
    lesson(
      where: {
        turma: { _eq: $turma }
        deleted_at: { _is_null: true }
        number: { _gte: 0.1, _lt: 99 }
      }
      order_by: { start_date: desc }
    ) {
      id
      number
      start_date
      title
      live_attendances {
        id
        personByPerson {
          id
          first_name
          last_name
          email
        }
      }
      liveAttendancesByReferenceLesson {
        id
        date
        personByPerson {
          id
          first_name
          last_name
          email
        }
      }
    }
    live_schedule(
      where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
    ) {
      day
    }
    register(
      where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
      order_by: {
        enrollmentByEnrollment: { person: { first_name: asc, last_name: asc } }
      }
    ) {
      enrollmentByEnrollment {
        person {
          id
          first_name
          last_name
          email
          monitorandos {
            monitor
          }
        }
      }
      is_professor
      is_tutor
      is_team_member
      is_monitor
      is_audit
      is_evaluator
      locked
      dependency
    }
    turma(
      where: {
        courseByCourse: { turmas: { id: { _eq: $turma } } }
        deleted_at: { _is_null: true }
      }
    ) {
      name
      lessons {
        id
        number
      }
    }
  }
`;
const mutation = gql`
  mutation upsert($objects: [live_attendance_insert_input!]!) {
    insert_live_attendance(objects: $objects) {
      affected_rows
    }
  }
`;
const AddAttendances = observer(({ props }) => {
  const store = useContext(StoreContext);
  const [lesson, setLesson] = React.useState();
  const [snack, setSnack] = React.useState({ msg: "", open: false });
  const showMsg = (msg) => {
    setSnack({ msg: msg, open: true });
  };
  const [list, setList] = React.useState({});
  const [open, setOpen] = React.useState({
    open: false,
    data: {
      student: null,
      turmas: null,
      person: null,
      lesson: null,
    },
  });

  const [update] = useMutation(mutation);

  var turma = store.ui.turma.id;
  var { data, error, loading } = lesson
    ? useQuery(query, { variables: { turma: turma, lesson: lesson } })
    : useQuery(query, { variables: { turma: turma } });
  if (loading) return <p>Carregando do banco de dados... </p>;
  if (error) return <p>ERROR (AddAttendances.jsx): {error.message}</p>;
  var live_days = data.live_schedule;
  data.lesson.forEach((lesson) => {
    var dates = [];
    if (lesson.title.toUpperCase().includes("WORKSHOP")) {
      let ldate = new Date(lesson.start_date + "T12:00:00.000Z")
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse();
      ldate.pop();
      dates.push(ldate.join("/"));
    } else {
      live_days.forEach(({ day }) => {
        let start = new Date(lesson.start_date + "T12:00:00.000Z");
        let weekday = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
          day.toLowerCase()
        );
        start.setDate(7 + weekday - start.getDay() + start.getDate());
        start = start
          .toISOString()
          .split("T")[0]
          .split("-");
        dates.push(`${start[2]}/${start[1]}`);
      });
    }
    if (dates.length === 0) dates = [lesson.start_date];
    dates.sort((a, b) => {
      const [dayA, monthA] = a.split("/").map(Number);
      const [dayB, monthB] = b.split("/").map(Number);
      return monthA - monthB || dayA - dayB;
    });
    dates = dates.join(", ");
    lesson.dates = dates;
  });
  var lessonObj = data.lesson.filter((l) => l.id == lesson)[0];
  var attendances = lessonObj
    ? lessonObj.live_attendances.map((attendance) =>
        attendance.personByPerson ? attendance.personByPerson.id : null
      )
    : [];
  var retakeAttendances = lessonObj
    ? lessonObj.liveAttendancesByReferenceLesson
    : [];
  return (
    <>
      <div>
        <FormControl className="formControl">
          <InputLabel htmlFor="lesson">Lição</InputLabel>
          <NativeSelect
            value={lesson}
            onChange={(e) => setLesson(e.target.value)}
            inputProps={{
              name: "lesson",
              id: "lesson",
            }}
          >
            <option value={""}></option>
            {data.lesson.map((lesson) => {
              return (
                <option value={lesson.id}>
                  Lição {lesson.number} - {lesson.dates}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </div>
      {lesson && (
        <div>
          <h3>Atenção: não é possível desmarcar presenças já salvas</h3>
          <Paper
            style={{ maxHeight: "50VH", overflow: "scroll" }}
            className="p30"
          >
            {data.register.map((e) => {
              let position = [];
              if (e.is_professor) position.push("professor");
              if (e.locked) position.push("Matrícula trancada");
              if (e.dependency) position.push("fazendo dependência");
              if (e.is_audit) position.push("ouvinte");
              if (e.is_monitor) position.push("monitor");
              if (e.is_tutor) position.push("tutor");
              if (e.is_team_member) position.push("coordenação");
              if (e.is_audit) position.push("Ouvinte");
              if (e.is_evaluator) position.push("Avaliador");
              position = position.length > 0 ? ` (${position.join(", ")})` : "";
              let person = e.enrollmentByEnrollment.person;
              let retakeAttendance = retakeAttendances.filter(
                (att) => att.personByPerson.id === person.id
              )[0];
              return (
                <>
                  <Tooltip title="Atribuir presença para outra turma">
                    <button
                      className="btn-assign-presence"
                      onClick={() =>
                        setOpen({
                          open: true,
                          data: {
                            student: person.first_name + " " + person.last_name,
                            turmas: data.turma,
                            person: person.id,
                            lesson: lesson,
                          },
                        })
                      }
                    >
                      {" "}
                      +{" "}
                    </button>
                  </Tooltip>

                  <Checkbox
                    checked={
                      attendances.includes(person.id) ||
                      list[person.id] ||
                      retakeAttendance
                    }
                    onClick={(event) =>
                      setList({ ...list, [person.id]: event.target.checked })
                    }
                  />
                  {attendances.includes(person.id) ? (
                    <b>
                      {person.first_name} {person.last_name}
                      {position} <br />
                    </b>
                  ) : retakeAttendance ? (
                    <b>
                      {person.first_name} {person.last_name}
                      {position} (
                      {retakeAttendance.date
                        ?.split("-")
                        ?.reverse()
                        ?.join("/")}
                      )<br />
                    </b>
                  ) : (
                    <span>
                      {person.first_name + " " + person.last_name + position}
                      <br />
                    </span>
                  )}
                  <br />
                </>
              );
            })}
          </Paper>
        </div>
      )}
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "30px" }}
        onClick={async () => {
          var objects = [];
          var date = "";
          data.lesson.forEach((l) => {
            // eslint-disable-next-line eqeqeq
            if (l.id == lesson) date = l.start_date;
          });
          Object.keys(list).forEach((personId) => {
            if (list[personId])
              objects.push({
                person: personId,
                lesson: lesson,
                date: date,
              });
          });
          try {
            await update({ variables: { objects: objects } });
            showMsg("Atualizado");
            window.location.reload();
          } catch (e) {
            showMsg(e);
          }
        }}
        disabled={!lesson}
      >
        Salvar Presenças
      </Button>
      <SavedSnack
        open={snack.open}
        msg={snack.msg}
        setOpen={(o) => setSnack({ open: o })}
      />
      <Dialog
        open={open.open}
        onClose={() => setOpen({ ...open, open: false })}
        className="dialog"
      >
        <div className="dialog-content">
          <p>Aluno: {open.data.student} </p>
          <h3>Escolha uma turma e a lição </h3>
          {open.data.turmas?.map((turma) => {
            return (
              <select
                className="option-lesson"
                onChange={async (e) => {
                  let reference_lesson = e.target.value;
                  if (
                    window.confirm(
                      `Atribuir presença para a turma ${turma.name}?`
                    )
                  ) {
                    try {
                      // eslint-disable-next-line no-unused-vars
                      let { data } = await update({
                        variables: {
                          objects: [
                            {
                              person: open.data.person,
                              lesson: open.data.lesson,
                              reference_lesson,
                            },
                          ],
                        },
                      });
                      showMsg("Atualizado");
                    } catch (e) {
                      showMsg(e);
                    }
                  }
                }}
              >
                <option> {turma.name} </option>
                {turma.lessons?.map((lesson) => {
                  return <option value={lesson.id}> {lesson.number} </option>;
                })}
              </select>
            );
          })}
          <Button variant="contained" color="primary" onClick={async () => {}}>
            Associar
          </Button>
        </div>
      </Dialog>
      <AttendanceCheck />
    </>
  );
});

export default AddAttendances;
