import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient, ApolloQueryResult } from "apollo-client";
import { observable } from "mobx";
import { createContext } from "react";
interface uiInterface {
  turma: turma;
  lessonPos: number;
  setLessonPos: (pos: number) => void;
  lessonDirty: boolean;
  refetch?: (
    variables?:
      | {
          email: string | undefined;
        }
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  alunos: {
    rowsPerPage: number;
    columns: {
      id: boolean;
      first_name: boolean;
      last_name: boolean;
      email: boolean;
      city: boolean;
      state: boolean;
      time: boolean[];
      lessons: boolean[];
    };
  };
  painel: {
    pendentes: boolean;
  };
}

const lt = localStorage.getItem("lastTurma");
const lastTurma = lt
  ? JSON.parse(lt)
  : {
      id: 9999,
      name: "Painel do aluno",
      category_name: "",
      welcome_message: "",
      max_activity_characters: 999,
      max_activity_words: 999,
      course: "Especialização ACT",
      deadline: "2022-05-02",
      org: "Ceconte",
    };
if (!lastTurma.course) lastTurma.course = lastTurma.curso;

const uiInitial = {
  turma: lastTurma,
  lessonPos: 0,
  setLessonPos(pos: number) {
    this.lessonPos = pos;
  },
  lessonDirty: false,
  alunos: {
    rowsPerPage: 200,
    columns: {
      id: false,
      first_name: true,
      last_name: true,
      title: true,
      email: false,
      city: true,
      state: false,
      time: [],
      lessons: [],
    },
  },
  painel: {
    pendentes: false,
  },
  defaulting_courses: [],
};

export interface turma {
  id: number;
  name: string;
  category_name: string;
  welcome_message: string;
  max_activity_characters: number;
  max_activity_words: number;
  course: string;
  deadline: string;
  org?: string; // Add this line
}

export interface payment {
  turma: number;
  date: string;
}

export interface storeInterface {
  ui: uiInterface;
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  email?: string;
  personId?: number;
  turmas: turma[];
  default_role?: string;
  auth_expires?: number;
  not_in_db: boolean;
  is_monitor: boolean;
  is_evaluator: boolean;
  is_consultant: boolean;
  consultantId: number;
  authorized: boolean;
  expired: boolean;
  logged_in: boolean;
  admin: boolean;
  is_tutor: boolean;
  version: string;
  courses_managed: string[];
  defaulting_courses: payment[];
}

export const store: storeInterface = observable({
  ui: uiInitial,
  not_in_db: false,
  is_monitor: false,
  is_evaluator: false,
  is_consultant: false,
  is_tutor: false,
  consultantId: 0,
  turmas: [],
  version: "",
  courses_managed: [],
  defaulting_courses: [],
  get authorized() {
    const self = this as storeInterface; // Type assertion
    return (
      !!(self.email && !self.expired) &&
      (!self.not_in_db || self.default_role === "admin")
    );
  },
  get expired() {
    const self = this as storeInterface; // Type assertion
    return self.auth_expires ? new Date().getTime() > self.auth_expires : true;
  },
  get logged_in() {
    const self = this as storeInterface; // Type assertion
    return !!self.email; // && !self.expired);
  },
  get admin() {
    const self = this as storeInterface; // Type assertion
    return (
      self.default_role === "admin" ||
      (self.default_role === "manager" &&
        self.courses_managed.includes(self.ui.turma.course))
    );
  },
});

export const StoreContext = createContext(store);
