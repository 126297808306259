import { log } from "./queriesAndMutations";
import { store } from "./store";
import { setPersonId } from "./Auth/Auth";

export enum LogType {
  Login = "login",
  Logout = "logout",
  Route = "route",
  VideoPlay = "video_play",
  VideoCompleted = "video_completed",
  ActivityView = "activity_view",
  ActivityEdit = "activity_edit",
  StudentsViewAll = "students_view_all",
  Error = "error"
}

export const logAction = async (action: LogType, payload: string) => {
  if (!store.personId) {
    await setPersonId(store);
  }
  if (!store.personId || !store.apolloClient) return null;
  const result = await store.apolloClient.mutate({
    mutation: log,
    variables: {
      personId: store.personId,
      action: action,
      payload
    }
  });
  return result;
};
