/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { StoreContext } from "../../store";
import axios from "axios";

const Teste = observer((props) => {
  var forms = {
    "Dados pessoais": ["email", "name", "cpf"],
    "Outras informações": ["utm", "utm_others"],
  };
  const course = "Meditações Individuais no MF I";
  const priceBRL = 400;
  const store = useContext(StoreContext);
  const { data, loading, error } = useQuery(query, {
    variables: {
      course,
      email: store.email,
    },
  });
  if (error) return <p>Erro: {error.message}</p>;
  if (loading) return <p>Carregando...</p>;
  var missingRequirements = [];
  data.prerequisite_course.forEach(async (relation) => {
    let courseCompletion = false;
    //check if student completed the course:
    relation.courseByPrerequisite?.enrollments.forEach((enrollment) => {
      let enrollmentCompletion = false;
      enrollment.registers.forEach((register) => {
        try {
          let { data } = axios.get(
            "/.netlify/functions/re_enroll?" +
              `email=${store.email}` +
              `&turma=${register.turmaByTurma.name}` +
              `&course=${course}`
          );
          console.log(data);
          enrollmentCompletion &&= data.passed;
        } catch (e) {
          alert(e);
        }
      });
      courseCompletion ||= enrollmentCompletion; //student has to pass in at least one enrollment of each prerequisite course
    });
    if (!courseCompletion) missingRequirements.push(relation.prerequisite);
  });
  if (missingRequirements.length > 0)
    return (
      <p>
        Erro: Para se inscrever neste curso, falta ser aprovado nos cursos:{" "}
        <b>{missingRequirements.join(", ")}</b>
      </p>
    );
  return (
    <>
      <div className="center">
        <div>
          <h1>{course}</h1>
          <img
            src="https://images.unsplash.com/photo-1515871204537-49a5fe66a31f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aGVhcnRzfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
            style={{ maxWidth: "600px", maxHeight: "600px" }}
          />
          <p>Valor: R$ {priceBRL.toString().replace(".", ",")}</p>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.history.push("/checkout-asaas", {
                items: [
                  {
                    description: `Inscrição - ${course}`,
                    quantity: 1,
                    price_cents: priceBRL * 100,
                  },
                ],
                course,
                semestre: "2022-2",
                forms,
              });
            }}
          >
            Prosseguir
          </Button>
        </div>
      </div>
    </>
  );
});

export default Teste;

const query = gql`
  query Requisites($course: String!, $email: String!) {
    prerequisite_course(
      where: { target: { _eq: $course }, deleted_at: { _is_null: true } }
    ) {
      prerequisite
      courseByPrerequisite {
        name
        enrollments(
          where: { email: { _eq: $email }, deleted_at: { _is_null: true } }
        ) {
          id
          registers {
            turmaByTurma {
              name
            }
          }
        }
      }
    }
  }
`;
