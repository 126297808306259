import gql from "graphql-tag";

      
const FieldsFragment = gql`
  fragment turmaFields on turma {
    name
    category_name
    welcome_message
    max_activity_characters
    max_activity_words
    course
    deadline
  }
`;
export const GET = gql`
query getTurma($id: Int, $limit: Int) {
    turma(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
        id
        ...turmaFields 
        lessons(order_by:{id:asc}){
          id
          number
          title
        }
    }
    course{
      id
      name
    }
}
${FieldsFragment}
`;

export const fieldtypes = { //mudar tipos dos campos
  deadline: "date"
}
export const INSERT = gql`
    mutation newturma($objects: [turma_insert_input!]!) {
        insert_turma(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`;

export const EDIT = gql`
  mutation updateTurma($_set: turma_set_input={}, $id: Int){
    update_turma(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`;
export function flatten(data){
  data.forEach(d=>{
    Object.keys(d).forEach(key=>{
      if(Array.isArray(d[key]))
        delete d[key]
    })
  })    
  return data
}

export function lists(data){
  data.course.forEach(course=>{
    course.id = course.name
  })
  return data
}
