import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "react-apollo-hooks";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { GRAPHQL_URL } from "./constants";
import muiTheme from "./theme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import { StoreContext, store } from "./store";
import { setBaseStudentData, setTutor } from "./Auth/Auth";
import axios from "axios";

const httpLink = createHttpLink({
  uri: GRAPHQL_URL
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("auth0:id_token");
  // return the headers to the context so httpLink can read them
  if (!token) return { headers: { ...headers } };
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const params = new URLSearchParams(window.location.search);
const paramEmail = params.get("email");
const paramToken = params.get("token");

const verifyToken = (token: string) => {
  axios
    .post(`/.netlify/functions/verifytoken?token=${token}`)
    .then(({ data }) => {
      console.log("Verification result:", data);
      if (data.error || !data.exp) {
        // logout
      } else {
        const expiration = data.exp * 1000;
        localStorage.setItem("auth0:expires_at", expiration.toString());
        store.auth_expires = expiration;
        var default_role = data["https://hasura.io/jwt/claims"]["x-hasura-default-role"]
        localStorage.setItem("auth0:id_token:default_role", default_role);
        store.default_role=default_role;
        localStorage.removeItem("lastTurma");
        let lastPage = localStorage.getItem("lastPage");
        if(lastPage) { //goes to last page saved on browser
          localStorage.removeItem("lastPage");
          //window.location.href = lastPage;
        };
      }
    });
};

if (paramEmail && paramToken) {
  localStorage.setItem("auth0:id_token", paramToken);
  localStorage.setItem("auth0:id_token:email", paramEmail);
  localStorage.setItem("auth0:id_token:default_role", "user");
  store.default_role = "user"; // in future read from token
  store.email = paramEmail;
  verifyToken(paramToken);
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({}),
  defaultOptions: {
    query: {
      errorPolicy: "all"
    }
  }
});

store.apolloClient = client;
if (store.email && store.apolloClient) {
  setBaseStudentData(store);
  setTutor(store);
}


ReactDOM.render(
  <ApolloProvider client={client}>
    <StoreContext.Provider value={store}>
      <MuiThemeProvider theme={muiTheme}>
        <App />
      </MuiThemeProvider>
    </StoreContext.Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
