import gql from "graphql-tag";

export const GET = gql`
query getDisciplina($id: Int, $limit: Int) {
  disciplina(where:{deleted_at: {_is_null: true}}) {
    id
    org
    name    
  }
  org{
    sigla
  }
}
`;

export const INSERT = gql`
  mutation newDisciplina($objects: [disciplina_insert_input!]!) {
    insert_disciplina(objects: $objects) {
      affected_rows
    }
  }
`;

export const EDIT = gql`
  mutation updateDisciplina($_set: disciplina_set_input={}, $id: Int){
    update_disciplina(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`;

export function lists(data){
  data.org.forEach(org=>{
    org.id = org.sigla
    org.name = org.sigla
  })
  return data
}