import React, { useContext } from "react";
import { useMutation } from "react-apollo-hooks";
import { Formik, Field, Form } from "formik";
import { Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { observer } from "mobx-react-lite";
import { Dialog, DialogTitle } from "@material-ui/core";
import axios from "axios";

const updateObs = gql`
  mutation updateObs($id: Int!, $obs: String) {
    update_activity_completion_by_pk(
      pk_columns: { id: $id }
      _set: { monitor_comment: $obs }
    ) {
      id
    }
  }
`;

const Obs = observer(({ open, onClose, data }) => {
  const [update] = useMutation(updateObs);
  const store = useContext(StoreContext);
  const showMsg = (msg) => {
    document.getElementById("message").innerHTML = msg;
  };
  return (
    <>
      <div>
        <Dialog open={open} onClose={onClose}>
          {data.file &&
            data.file.split(" ").map((file, i) => {
              var date = data.file_date
                ? ` (${data.file_date
                    .split("-")
                    .reverse()
                    .join("/")})`
                : "";
              return (
                <p style={{ textAlign: "center" }}>
                  <a
                    href={"https://drive.google.com/uc?export=view&id=" + file}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Arquivo {i + 1}
                    {date}
                  </a>
                </p>
              );
            })}
          {data.monitor_file && (
            <p style={{ textAlign: "center" }}>
              <a
                href={
                  "https://drive.google.com/uc?export=view&id=" +
                  data.monitor_file
                }
                target="_blank"
                rel="noreferrer"
              >
                Arquivo de resposta do avaliador
              </a>
            </p>
          )}
          <DialogTitle>{`Observação: `}</DialogTitle>
          <Formik
            onSubmit={async (values, actions) => {
              try {
                update({ variables: { id: data.id, obs: values.obs } });
                showMsg("Observação salva");
                if (store.ui.refetch) store.ui.refetch();
                const config = {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("auth0:id_token"),
                  },
                };
                var body = {
                  to: data.email,
                  text: `Uma observação foi adicionada sobre a sua atividade ${data.activity} na lição ${data.lesson}:
            ${values.obs}`,
                };
                await axios.post(
                  "/.netlify/functions/mailnotification",
                  body,
                  config
                );
              } catch (e) {
                showMsg("Erro");
              }
              actions.setSubmitting(false);
            }}
            initialValues={{ obs: data.obs }}
            render={({ values, setFieldValue }) => (
              <Form style={{ padding: "30px", minWidth: "600px" }}>
                <Field
                  type="text"
                  name="obs"
                  component={TextField}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  multiline={true}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ margin: "30px" }}
                  disabled={data.activity.includes("Projeto")}
                >
                  Enviar
                </Button>
                <p id="message" style={{ textAlign: "center" }} />
              </Form>
            )}
          />
        </Dialog>
      </div>
    </>
  );
});
export default Obs;
