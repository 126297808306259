import React, { useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { textLabels } from "../dataTablesLocalization";
import { StoreContext } from "../../store";
import { Grid } from "@material-ui/core";
import gql from "graphql-tag"



const query = gql`
query getPayments($email: String, $date_limit: date) {
  payment_plan(where: {enrollmentByEnrollment: {email: {_eq: $email}},deleted_at: {_is_null: true}}, order_by: {id: desc}) {
    id
    payments (order_by: {due_date: asc}, where: {due_date: {_lte: $date_limit},deleted_at: {_is_null: true}}){
      payment_id
      due_date
      status
      value
      link
      updated_at
      description
      disabled
    }
    enrollmentByEnrollment {
      course
    }
  }
}
`;

const PaymentsStudent = observer((props) => {  
  const store = useContext(StoreContext);
  let variables = {email:store.email};
  if(store.ui.turma.course.includes("ACT") || store.ui.turma.course.includes("FAP")){
    let date_limit = new Date();
    date_limit.setMonth(date_limit.getMonth()+1);
    variables.date_limit = date_limit.toISOString().split("T")[0];
  }
  var { data, error, loading } = useQuery(query,{variables});
  if (loading) return <p>Carregando do banco de dados... </p>;
  if (error) return <p>ERROR (Payments.jsx): {error.message}</p>;
  data=data['payment_plan']
  data=JSON.parse(JSON.stringify(data)) //clone data to preserve original object
  var columns = [{name:"due_date", label:"vencimento"},
  {
    name: "status",
    options:{
      customBodyRender: (value, tableMeta, updateValue) => {
        var style = {
          borderRadius: "5px",
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center"
        };
        switch(value){
          case "pago": style.background = "lightgreen";
          break;
          case "ATRASO": style.background = "orange";
          break;
          case "aberto": style.background = "yellow";
          break;
          default: style.background = "white"
        }
        return (
          <>
          <div style={style}>
          {value}
          </div>
          </>              
        );
      }
    }
  },
  {name:"value",label:"valor (R$)"},
  {name:"description",label:"Descrição"},
  'link',
  {name:"updated_at",label:'última atualização'}];
  var plans = data.map(plan=>{
    var dataView=[]
    var addPending = true;
    var course = plan.enrollmentByEnrollment.course;
    plan.payments.forEach(payment=>{
      let date = payment.due_date.split('-');
      if(payment.status==='pending' && addPending){
        if(payment.due_date>=new Date().toISOString().split('T')[0]){
          payment.status='aberto';
          payment.due_date = date[2]+'/'+date[1]+'/'+date[0];
        }else{
          payment.status='ATRASO';
          payment.due_date = date[2]+'/'+date[1]+'/'+date[0];
        };
        dataView.push(payment);
      }else if(payment.status==='paid'){
        payment.status='pago';
        payment.due_date = date[2]+'/'+date[1]+'/'+date[0];
        dataView.push(payment);
      }else if(payment.status==='expired' && !payment.disabled){
        payment.status='ATRASO';
        payment.due_date = date[2]+'/'+date[1]+'/'+date[0];
        dataView.push(payment);
        if(course.includes("ACT") || course.includes("FAP")) addPending = false;
      }
      var att = new Date(payment.updated_at);
      att.setTime(att.getTime()-att.getTimezoneOffset()*60*1000);
      att=att.toISOString().split('-');
      payment.updated_at = att[2].split('T')[0]+'/'+att[1]+'/'+att[0]+' '+att[2].split('T')[1]
    });
    return{
      name: course,
      dataView 
    }
  });

  return (
    <>    
    <Grid container>
    {plans.map(plan=>(
      <div style={{display: 'table', tableLayout:'fixed', width:'100%', margin: "10px"}}>
        <MUIDataTable
          title={'Pagamentos - course '+plan.name}
          data={plan.dataView}
          columns={columns}
          options={{
            responsive: "scroll",
            rowsPerPageOptions: [10, 50, 200],
            rowsPerPage:200,
            selectableRows: false,
            filterType: "multiselect",
            textLabels,
            onCellClick: (_, cmeta) => {              
              var fatura = plan.dataView[cmeta.dataIndex];
              window.open(fatura.link,'_blank')
            },
          }}
        />
      </div>
    ))}
    </Grid>
    </>
  );
});
  
export default PaymentsStudent;