import React from "react";
import { observer } from "mobx-react-lite";
import { store } from "../store";
import LoginPasswordless from "./LoginPasswordless";

const Login = observer(() => {
  if (!store.email) return noLogin;
  else if (store.expired) {
    return sessionExpired;
  } else if (store.default_role !== "admin") {
    return adminOnly;
  } else return <h3>Logando...</h3>;
});

const noLogin = (
  <div>
    <h3>Você precisa se logar para entrar!</h3>
    <LoginPasswordless />
  </div>
);

const sessionExpired = (
  <div>
    <h3>Sua sessão expirou, você precisa se logar novamente.</h3>
    <LoginPasswordless />
  </div>
);

const adminOnly = (
  <h3>
    Erro de permissão, talvez você tenha tentado acessar uma página protegida.
  </h3>
);

export default Login;
