import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo-hooks";
import "../css/AttachmentsAdminPage.css";
import { store } from "../store";

const GET_ENROLLMENTS_QUERY = gql`
  query MyQuery($turma: Int) {
    enrollment(
      order_by: { person: { first_name: asc, last_name: asc } }
      where: {
        registers: {
          turma: { _eq: $turma }
          is_professor: { _eq: false }
          is_monitor: { _eq: false }
          is_team_member: { _eq: false }
          locked: { _eq: false }
          cancelled: { _eq: false }
        }
      }
    ) {
      person {
        first_name
        last_name
      }
      documents {
        id
        comment
        statusDocumentsStudentByStatusIdAi {
          status_name
          id
        }
        type_documents_student {
          description
          id
        }
        url
        status_documents_student {
          status_name
        }
      }
    }
  }
`;

const GET_TYPE_DOCUMENTS_STUDENTS_QUERY = gql`
  query GetTypeDocumentsStudents {
    type_documents_students {
      description
      id
    }
  }
`;

const UPDATE_DOCUMENT_STATUS_MUTATION = gql`
  mutation UpdateDocumentStatus($id: Int!, $statusId: Int!, $comment: String) {
    update_documents_students_by_pk(
      pk_columns: { id: $id }
      _set: { status_id: $statusId, comment: $comment }
    ) {
      id
      status_id
      comment
    }
  }
`;

const DocumentModal = ({ isOpen, onClose, document, onUpdate }) => {
  const [comment, setComment] = useState("");
  const [updateDocumentStatus] = useMutation(UPDATE_DOCUMENT_STATUS_MUTATION);
  const [message, setMessage] = useState("");

  if (!isOpen) return null;

  const handleApproval = async (isApproved) => {
    const statusId = isApproved ? 1 : 4; // 1 para Aprovado, 4 para Reprovado
    try {
      const { data } = await updateDocumentStatus({
        variables: {
          id: document.id,
          statusId,
          comment,
        },
      });
      setMessage("Status atualizado com sucesso!");
      onUpdate({
        ...document,
        status_documents_student: {
          ...document.status_documents_student,
          status_name: isApproved ? "Aprovado" : "Reprovado",
        },
        comment: data.update_documents_students_by_pk.comment,
      });
      setTimeout(() => {
        setMessage("");
        onClose();
      }, 2000);
    } catch (error) {
      setMessage("Erro ao atualizar o status do documento.");
      console.error("Erro ao atualizar o status do documento:", error);
    }
  };

  return (
    <div className="modal">
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
      <h3>Validar Documento</h3>
      <p>Aluno: {document.aluno}</p>
      <p>Tipo do Documento: {document.tipo}</p>
      <p>
        Documento:{" "}
        <a href={document.url} target="_blank" rel="noopener noreferrer">
          Abrir documento
        </a>
      </p>
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Adicionar comentário (opcional)"
      />
      <div className="modal-contentAdmin">
        <button className="b-aprovar" onClick={() => handleApproval(true)}>
          Validar
        </button>
        <button className="b-reprovar" onClick={() => handleApproval(false)}>
          Rejeitar
        </button>
      </div>
      {message && <p>{message}</p>}
    </div>
  );
};

export function AttachmentsAdminPage() {
  const turma = store.ui.turma.id;
  const {
    data: typeDocumentsData,
    loading: loadingTypeDocuments,
    error: errorTypeDocuments,
  } = useQuery(GET_TYPE_DOCUMENTS_STUDENTS_QUERY);
  const { data, loading, error } = useQuery(GET_ENROLLMENTS_QUERY, {
    variables: { turma },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [enrollments, setEnrollments] = useState([]);

  useEffect(() => {
    if (data) {
      setEnrollments(data.enrollment);
    }
  }, [data]);

  const verificarDocumentosFaltantes = (
    documentosSubmetidos,
    documentosObrigatorios
  ) => {
    const idsSubmetidos = documentosSubmetidos
      .filter((doc) => doc.type_documents_student)
      .map((doc) => doc.type_documents_student.id);
    return documentosObrigatorios.filter(
      (docObrigatorio) => !idsSubmetidos.includes(docObrigatorio.id)
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Aprovado":
        return "status-aprovado-admin";
      case "Aprovado IA":
        return "status-aprovado-admin";
      case "Pendente":
        return "status-pendente-admin";
      case "Reprovado":
        return "status-reprovado-admin";
      case "Não reconhecido pela IA":
        return "status-reprovado-admin";
      case "Reprovado IA":
        return "status-reprovado-admin";
      default:
        return "";
    }
  };

  const openModal = (doc, enroll) => {
    if (!doc || !doc.type_documents_student) {
      console.error("Documento ou tipo de documento inválido");
      return;
    }

    setSelectedDocument({
      id: doc.id,
      aluno: `${enroll.person.first_name} ${enroll.person.last_name}`,
      tipo: doc.type_documents_student.description,
      url: `https://drive.google.com/file/d/${doc.url}/view`,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDocument(null);
  };

  const handleUpdate = (updatedDocument) => {
    setEnrollments((prevEnrollments) => {
      return prevEnrollments.map((enrollment) => {
        if (enrollment.documents.some((doc) => doc.id === updatedDocument.id)) {
          return {
            ...enrollment,
            documents: enrollment.documents.map((doc) => {
              if (doc.id === updatedDocument.id) {
                return { ...doc, ...updatedDocument };
              }
              return doc;
            }),
          };
        }
        return enrollment;
      });
    });
  };

  if (loadingTypeDocuments) return <p>Carregando tipos de documentos...</p>;
  if (errorTypeDocuments) return <p>Erro ao carregar tipos de documentos!</p>;
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar dados!</p>;
  if (!enrollments.some((enroll) => enroll.documents.length > 0))
    return <h3>Nenhum documento encontrado!</h3>;

  return (
    <div className="containerAdmin">
      <h2>Documentos anexados pelos alunos</h2>
      {enrollments.map((enroll, enrollIndex) => {
        const documentosFaltantes = verificarDocumentosFaltantes(
          enroll.documents,
          typeDocumentsData.type_documents_students
        );
        return (
          <div key={enrollIndex} className="enrollmentSection">
            <div className="studentName">
              <strong>Aluno:</strong>{" "}
              {`${enroll.person.first_name} ${enroll.person.last_name}`}
            </div>
            <table className="tableAdmin">
              <thead>
                <tr>
                  <th className="thadmin">Documento</th>
                  <th className="thadmin">Link</th>
                  <th className="thadmin">Status</th>
                  <th className="thadmin">Status (análise feita pela IA)</th>
                  <th className="thadmin">Ação</th>
                </tr>
              </thead>
              <tbody>
                {enroll.documents.map((doc, docIndex) => (
                  <tr key={`${enrollIndex}-${docIndex}`}>
                    <td className="tdAdmin">
                      {doc.type_documents_student?.description}
                    </td>
                    <td className="tdAdmin">
                      <a
                        href={`https://drive.google.com/file/d/${doc.url}/view`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Abrir documento
                      </a>
                    </td>
                    <td
                      className={`tdAdmin ${getStatusClass(
                        doc.status_documents_student.status_name
                      )}`}
                    >
                      <span />
                      {doc.status_documents_student.status_name === "Reprovado"
                        ? "Rejeitado"
                        : doc.status_documents_student.status_name ===
                          "Aprovado"
                        ? "Validado"
                        : "Pendente"}
                    </td>
                    <td
                      className={`tdAdmin ${getStatusClass(
                        doc.statusDocumentsStudentByStatusIdAi?.status_name ||
                          "Pendente"
                      )}`}
                    >
                      <span />
                      {doc.statusDocumentsStudentByStatusIdAi?.status_name ??
                        "Pendente"}
                    </td>
                    <td className="tdAdmin">
                      <button onClick={() => openModal(doc, enroll)}>
                        Validar
                      </button>
                    </td>
                  </tr>
                ))}
                {documentosFaltantes.length > 0 && (
                  <tr>
                    <td className="tdAdmin" colSpan="5">
                      <span className="docAviso">
                        Documentos faltantes:{" "}
                        {documentosFaltantes
                          .map((doc) => doc.description)
                          .join(", ")}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
      <DocumentModal
        isOpen={isModalOpen}
        onClose={closeModal}
        document={selectedDocument}
        onUpdate={handleUpdate}
      />
    </div>
  );
}
