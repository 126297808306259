import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import image from "./fundamentos-fap.png";
import imageResponsivo from "./fundamentos-fap-responsivo.png";
import "./index.css";

const Course = observer((props) => {
  var forms = {
    "Dados pessoais": [
      "email",
      "name",
      "cpf",
      "postal_code",
      "address1",
      "address2",
      "n_address",
      "district",
      "city",
      "state",
      "cell_phone",
    ],
    "Outras informações": ["utm", "utm_others"],
  };
  const prices = {
    PIX: 299,
    "1x cartão (sem juros)": 299,
    "2x cartão (valor total : 308,40)": 154.07,
    "3x cartão (valor total : 311,91)": 103.97,
    "4x cartão (valor total : 315,72)": 78.93,
    "5x cartão (valor total : 319,55)": 63.91,
    "6x cartão (valor total : 323,40)": 53.9,
    "7x cartão (valor total : 327,32)": 46.76,
    "8x cartão (valor total : 331,20)": 41.4,
    "9x cartão (valor total : 335,16)": 37.24,
    "10x cartão (valor total : 339,20)": 33.92,
    "11x cartão (valor total : 343,20)": 31.2,
    "12x cartão (valor total : 347,28)": 28.94,
  };

  const priceBRL = prices.PIX;

  const course = "Fundamentos da FAP";
  return (
    <>
      <div>
        <div className="container-fundamentos">
          <img src={image} alt="" className="image-fundamentos" />
          <img src={imageResponsivo} alt="" className="image-fundamentos-responsive" />
          <div className="descriptionFFAP">
            O curso "Curando Através das Relações - Fundamentos da FAP", é uma
            experiência imersiva prática, mas requer que você assista
            previamente as aulas gravadas. As aulas gravadas estarão disponíveis
            no painel do aluno a partir do dia 24/8. Para participar da sessão
            prática ao vivo, é essencial assistir a este conteúdo
            antecipadamente. As instruções de acesso serão enviadas diretamente
            para o seu e-mail, garantindo que você esteja preparado para
            aproveitar ao máximo cada sessão prática.
          </div>
          <h3 className="price">
            <b>Preço:</b> R${priceBRL},00 (à vista) <br />
          </h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.history.push("/checkout-asaas", {
                items: [
                  {
                    description: `Inscrição - ${course}`,
                    quantity: 1,
                    price_cents: priceBRL * 100,
                  },
                ],
                course,
                semestre: "2024-2",
                forms,
                prices,
                turma: 323,
              });
            }}
          >
            Inscreva-se
          </Button>
        </div>
      </div>
    </>
  );
});

export default Course;
