import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment enrollmentFields on enrollment {
    email
    course
    comment
    registers{
      id
      turma
      is_student
      is_monitor
      is_tutor
      is_professor
      is_team_member
      is_audit
      is_evaluator
      suspended
      cancelled
      locked
      expired
      dependency
      turmaByTurma{
        id
        name
      }
    }
    courseByCourse{
      name
    }
  }
`;

export const GET = gql`
query getEnrollment($id: Int, $limit: Int) {
    enrollment(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
        id
        ...enrollmentFields
    }
    
    course{
      name
    }
    turma{
      id
      name
    }
}
${FieldsFragment}
`;

export const INSERT = gql`
    mutation newEnrollment($objects: [enrollment_insert_input!]!) {
        insert_enrollment(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updateEnrollment($_set: enrollment_set_input={}, $id: Int){
    update_enrollment(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, _set: $_set) {
      affected_rows
    }
  }
`

export const fieldtypes = { //mudar tipos dos campos
  cancelled:"checkbox",
  locked:"checkbox",
  comment:"textarea",
  suspended: "checkbox",
  enrollment:"number",
  is_monitor: "checkbox",
  is_tutor: "checkbox",
  is_professor: "checkbox",
  is_team_member: "checkbox",
  is_student: "checkbox",
  is_audit:"checkbox",
  is_evaluator:"checkbox",
  expired: "checkbox",
  dependency: "checkbox",
}
export function flatten(data){
  data.forEach(d =>{
    delete d.courseByCourse
    if(Array.isArray(d.registers)){
      d.turmas = d.registers.map(register=> register.turmaByTurma.name).join(", ")
      d.registers = d.registers.map(register=>(register.turma)).join(", ")
      }
    });
  return data
}
export const defaults ={
  cancelled : false,
  is_tutor: false,
  locked : false,
  is_professor: false,
  is_team_member: false,
  is_student: true,
  is_audit: false,
  is_monitor: false,
  suspended: false,
  is_evaluator: false,
  expired: false,
  dependency:false
}

export function lists(data){
  data.course.forEach(course=>{
    course.id = course.name
  })
  return data
}