import React, { useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { textLabels } from "../dataTablesLocalization";
import { StoreContext } from "../../store";
import { Grid, Button } from "@material-ui/core";
import gql from "graphql-tag";

const query = gql`
  query getPayments($turma: Int) {
    register(
      order_by: {
        enrollmentByEnrollment: { person: { first_name: asc, last_name: asc } }
      }
      where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
    ) {
      id
      is_professor
      is_tutor
      is_team_member
      is_monitor
      is_audit
      is_evaluator
      suspended
      cancelled
      locked
      dependency
      enrollmentByEnrollment {
        person {
          id
          first_name
          last_name
        }
        payment_plans {
          id
          dia_vencimento
          parcelas
          valor_total
          payments(
            where: {
              _or: [
                { status: { _eq: "paid" } }
                { status: { _eq: "pending" } }
                { status: { _eq: "expired" }, disabled: { _eq: false } }
              ]
              deleted_at: { _is_null: true }
            }
            order_by: { due_date: asc }
          ) {
            id
            payment_id
            due_date
            status
            disabled
            value
          }
        }
      }
    }
  }
`;

const Payments = observer(({ props }) => {
  const store = useContext(StoreContext);
  var turma = store.ui.turma.id;
  var { data, error, loading } = useQuery(query, {
    variables: { turma: turma },
    fetchPolicy: "network-only",
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (Payments.jsx): {error.message}</p>;
  data = data["register"];
  var dataView = JSON.parse(JSON.stringify(data)); //clone data to preserve original object
  var titles = {};
  var plan_color = {};
  var columns = [
    {
      name: "nome",
      options: {
        setCellProps: () => ({
          style: {
            minWidth: "300px",
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            //zIndex: 100
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            //zIndex: 101
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) =>
          value + titles[value],
      },
    },
    "dia_vencimento",
    "pago",
  ];
  var data_inicio = "9999-99";
  var data_fim = "0000-00";
  dataView.forEach((register) => {
    register.nome =
      register.enrollmentByEnrollment.person.first_name +
      " " +
      register.enrollmentByEnrollment.person.last_name;
    let title = [];
    if (register.suspended) title.push("SUSPENSO");
    if (register.cancelled) title.push("CANCELADO");
    if (register.locked) title.push("Matrícula trancada");
    if (register.dependency) title.push("fazendo dependência");
    if (register.is_tutor) title.push("Tutor");
    if (register.is_monitor) title.push("Monitor");
    if (register.is_professor) title.push("Professor");
    if (register.is_team_member) title.push("Equipe");
    if (register.is_audit) title.push("Ouvinte");
    if (register.is_evaluator) title.push("Avaliador");
    title = title.length > 0 ? ` (${title.join(", ")})` : "";
    titles[register.nome] = title;
    var plan = register.enrollmentByEnrollment.payment_plans[0];
    if (plan) {
      var valorTotalParcelas = plan.payments
        .map((item) => item.value)
        .reduce((prev, curr) => prev + curr, 0);
      var valorTotalPlano = plan.valor_total;
      var total = plan.parcelas;
      var paid = 0;
      // eslint-disable-next-line no-unused-vars
      let active = 0; //contagem de faturas ativas para comparação
      plan.payments.forEach((invoice) => {
        if (
          invoice.status === "paid" ||
          invoice.status === "pending" ||
          invoice.status === "expired"
        ) {
          if (!(invoice.disabled && invoice.status === "expired")) active++;
          if (invoice.status === "paid") paid++;
          var month = invoice.due_date.substring(0, 7);
          if (month > data_fim) data_fim = month;
          if (month < data_inicio) data_inicio = month;
          if (register[month]) {
            register[month] += " ";
          } else {
            register[month] = "";
          }
          if (invoice.status === "paid") {
            register[month] += "✓";
          } else if (invoice.status === "expired") {
            if (!invoice.disabled) {
              register[month] += "ATRASO";
            }
          } else {
            var today = new Date().toISOString().split("T")[0];
            if (invoice.due_date < today) register[month] += "ATRASO";
            else register[month] += "aberto";
          }
        }
      });
      register.pago = paid + "/" + total;
      register.dia_vencimento = plan.dia_vencimento;
      plan_color[register.nome] =
        valorTotalParcelas !== valorTotalPlano ? "red" : "black";
    }
  });
  columns[2] = {
    name: "pago",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        let color = plan_color[tableMeta.rowData[0]];
        return <b style={{ color }}>{value}</b>;
      },
    },
  };
  //gera colunas dos meses:
  var ano = data_inicio.split("-")[0];
  var mes = data_inicio.split("-")[1];
  while (ano + "-" + mes.toString().padStart(2, "0") <= data_fim) {
    columns.push(ano + "-" + mes.toString().padStart(2, "0"));
    mes++;
    if (mes > 12) {
      ano++;
      mes = 1;
    }
  }
  return (
    <>
      <Grid container>
        <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
          * Coluna pago em vermelho: a soma total das faturas está diferente do
          valor do plano de pagamento
          <MUIDataTable
            title={"Pagamentos - turma " + store.ui.turma.name}
            data={dataView}
            columns={columns}
            options={{
              responsive: "scroll",
              rowsPerPageOptions: [10, 50, 200],
              rowsPerPage: 200,
              selectableRows: false,
              filterType: "multiselect",
              textLabels,
              onCellClick: (_, cmeta) => {
                const register = dataView[cmeta.dataIndex];
                props.history.push(
                  `/newInvoice?id=${register.enrollmentByEnrollment.person.id}`
                );
              },
            }}
          />
        </div>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "30px" }}
        onClick={() => {
          props.history.push("/report");
        }}
      >
        Relatório mensal
      </Button>
    </>
  );
});

export default Payments;
