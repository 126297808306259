import React, { useState, useEffect, useContext } from "react";
import { Formik, Field, Form } from "formik";
import { Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import axios from "axios";
import "../../css/Apply.css";
import { StoreContext } from "../../store";
import { useQuery } from "react-apollo-hooks";
import PixPage from "./PixPage";
import CheckoutForm from "./CheckoutForm";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("auth0:id_token"),
  },
};

const Standard = (props) => {
  useEffect(() => {
    let tag = document.createElement("script");
    tag.src =
      "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
    document.body.appendChild(tag);
  }, []);
  var { course, semestre, items, turma, forms, prices } = props.location.state;
  if (!items)
    items = [
      {
        price_cents: 500,
        description: "teste",
        quantity: 1,
      },
    ];
  const [paymentForm, setPaymentForm] = useState({ open: false, data: null });
  const [pix, setPix] = useState("");
  const store = useContext(StoreContext);
  const query = store.personId
    ? gql`
        query MyQuery($id: Int!) {
          person_by_pk(id: $id) {
            email
            first_name
            last_name
            cpf
            cell_phone
            postal_code
            n_address
          }
        }
      `
    : gql`
        query public {
          log(limit: 1) {
            id
          }
        }
      `;
  const variables = store.personId ? { id: store.personId } : {};
  const { data, error, loading } = useQuery(query, { variables });
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro (Checkout.jsx): {error.message}</p>;
  var person = data.person_by_pk;
  if (paymentForm.open)
    return <Payment data={paymentForm.data} items={items} course={course} />;
  if (pix) return <PixPage id={pix} />;
  var init = { course, semestre, tipo: "auto" };
  if (person) {
    delete forms["Dados pessoais"];
    delete forms["Outras informações"];
    person.name = person.first_name + " " + person.last_name;
    init.person = store.personId; //conferir se application aceita person
  }
  return (
    <>
      <Formik
        onSubmit={async (values, actions) => {
          if (!person) person = values.personByPerson.data;
          person.email = person.email.trim();
          try {
            var { data } = await axios.post(
              "/.netlify/functions/apply",
              values
            );
            if (data.errors) {
              alert(data.errors[0].message);
            } else {
              if (data.email)
                alert(
                  `CPF já cadastrado com email diferente. O email ${data.email} será usado pelo sistema.`
                );
              person.application = data.data.insert_application_one.id;
              let parcelas = 1;
              if (values.forma_pagamento === "PIX") {
                let res = await createInvoice(course, { ...person, items });
                if (res === "ok") setPix(person.application);
              } else {
                parcelas = values.forma_pagamento.match(/[0-9]/)[0];
                items[0].price_cents = prices[values.forma_pagamento] * 100;
                setPaymentForm({
                  open: true,
                  data: { ...person, parcelas, turma },
                });
              }
            }
          } catch (e) {
            // eslint-disable-next-line no-ex-assign
            if (e.response) e = e.response.data;
            alert(JSON.stringify(e));
          }
          actions.setSubmitting(false);
        }}
        initialValues={init}
        render={({ values, setFieldValue, isSubmitting, errors }) => (
          <CheckoutForm
            course={course}
            semestre={semestre}
            forms={forms}
            isSubmitting={isSubmitting}
            items={items}
            person={person}
            setFieldValue={setFieldValue}
            prices={prices}
          />
        )}
      />
    </>
  );
};
export default Standard;

const Payment = ({ data, items, course }) => {
  const trackPurchase = (value, courseName) => {
    const pixelIds = ["435772408653127", "1070516823477978"];
    pixelIds.forEach((pixelId) => {
      if (window.fbq) {
        window.fbq("init", pixelId);
        window.fbq("track", "Purchase", {
          currency: "BRL",
          value: value,
          content_name: courseName,
        });
      }
    });
  };

  const getClientIp = async () => {
    try {
      const response = await axios.get("/.netlify/functions/getClientIp");
      return response.data.ip;
    } catch (error) {
      console.error("Erro ao obter o IP do cliente:", error);
      return "127.0.0.1";
    }
  };
  const [cardState, setCardState] = useState({
    number: "",
    cvc: "",
    holderName: "",
    focus: "",
    expiryMonth: "",
    expiryYear: "",
  });

  const handleInputChange = (evt, setFieldValue) => {
    const { name, value } = evt.target;
    const stateName = name === "ccv" ? "cvc" : name;
    setCardState((prev) => ({ ...prev, [stateName]: value }));
    setFieldValue(name, value);
  };

  const handleInputFocus = (evt) => {
    const focusName = evt.target.name === "ccv" ? "cvc" : evt.target.name;
    setCardState((prev) => ({ ...prev, focus: focusName }));
  };

  return (
    <div className="formsPayment">
      <Cards
        number={cardState.number}
        expiry={`${cardState.expiryMonth}/${cardState.expiryYear}`}
        cvc={cardState.cvc}
        name={cardState.holderName}
        focused={cardState.focus}
      />
      <br />
      <Formik
        initialValues={{
          holderName: "",
          number: "",
          expiryMonth: "",
          expiryYear: "",
          ccv: "",
        }}
        onSubmit={async (values, actions) => {
          let person = data;
          const clientIp = await getClientIp();
          let body = {
            items: [
              {
                ...items[0],
                price_cents: items[0].price_cents * data.parcelas,
              },
            ],
            installmentCount: data.parcelas,
            application: data.application,
            externalReference: data.application,
            turma: data.turma,
            payable_with: ["credit_card"],
            creditCard: {
              holderName: values.holderName,
              number: values.number,
              expiryMonth: values.expiryMonth,
              expiryYear: values.expiryYear,
              ccv: values.ccv,
            },
            creditCardHolderInfo: {
              name: person.name,
              email: person.email,
              cpfCnpj: person.cpf,
              postalCode: person.postal_code,
              addressNumber: person.n_address || "10",
              phone: person.cell_phone,
            },
            due_date: new Date().toISOString().split("T")[0],
            remoteIp: clientIp,
          };
          if (person.cpf) body.cpf_cnpj = person.cpf;
          if (person.name) body.name = person.name;
          if (person.email) body.email = person.email;
          if (person.cell_phone) body.cell_phone = person.cell_phone;
          if (person.postal_code) body.postal_code = person.postal_code;
          try {
            let response = await axios.post(
              "/.netlify/functions/createinvoice_asaas-background?action=charge&turma=" +
                course,
              body,
              config
            );
            if (response.status === 200) {
              document.getElementById("cc_page").innerHTML =
                "Cobrança enviada para o cartão. Você receberá um email com a fatura, e, caso aprovada, receberá também um email com o link de acesso ao curso.";
              const totalValue = (items[0].price_cents / 100) * data.parcelas;
              trackPurchase(totalValue.toFixed(2), course);
            } else {
              throw new Error("Resposta inesperada do servidor");
            }
          } catch (error) {
            console.error("Erro ao processar pagamento:", error);
            let errorMessage =
              "Ocorreu um erro inesperado. Por favor, tente novamente ou entre em contato com o suporte.";

            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
              errorMessage =
                error.response.data.errors[0]?.description || errorMessage;
            }

            document.getElementById("cc_page").innerHTML = `
              <div class="error-message">
                <h3>Erro no processamento do pagamento</h3>
                <p>${errorMessage}</p>
                <b>suporte-ti@ceconte.com.br</b>
              </div>
            `;
          }
          actions.setSubmitting(false);
        }}
        render={({ values, setFieldValue, isSubmitting, errors }) => (
          <Paper id="cc_page" className="p30">
            <h2 className="titlePayment">
              Pagamento - {course} <br />
              valor total{" "}
              {((items[0].price_cents / 100) * data.parcelas).toFixed(2)} <br />
            </h2>
            <Form id="payment-form" className="formsPayment">
              <div className="usable-creditcard-form">
                <div className="wrapper">
                  <div className="input-group nmb_a">
                    <div className="icon ccic-brand"></div>
                    <Field
                      className="credit_card_number"
                      name="number"
                      placeholder="Número do Cartão"
                      component={TextField}
                      onChange={(e) => handleInputChange(e, setFieldValue)}
                      onFocus={handleInputFocus}
                      InputProps={{
                        className: "credit-card-input",
                      }}
                    />
                  </div>
                  <div className="input-group nmb_b">
                    <div className="icon ccic-cvv"></div>
                    <Field
                      className="credit_card_cvv"
                      name="ccv"
                      placeholder="ccv"
                      component={TextField}
                      onChange={(e) => handleInputChange(e, setFieldValue)}
                      onFocus={handleInputFocus}
                      InputProps={{
                        className: "credit-card-input",
                      }}
                    />
                  </div>
                  <div className="input-group nmb_c">
                    <div className="icon ccic-name"></div>
                    <Field
                      className="credit_card_name"
                      name="holderName"
                      placeholder="Titular do Cartão"
                      component={TextField}
                      onChange={(e) => handleInputChange(e, setFieldValue)}
                      onFocus={handleInputFocus}
                      InputProps={{
                        className: "credit-card-input",
                      }}
                    />
                  </div>
                  <div className="input-group nmb_d">
                    <div className="icon ccic-exp"></div>
                    <div className="divExpiration">
                      <Field
                        className="credit_card_expiration"
                        name="expiryMonth"
                        placeholder="MM"
                        component={TextField}
                        onChange={(e) => handleInputChange(e, setFieldValue)}
                        onFocus={handleInputFocus}
                        InputProps={{
                          className: "credit-card-input-expiration",
                        }}
                      />
                      <span className="separator">/</span>
                      <Field
                        className="credit_card_expiration"
                        name="expiryYear"
                        placeholder="AA"
                        component={TextField}
                        onChange={(e) => handleInputChange(e, setFieldValue)}
                        onFocus={handleInputFocus}
                        InputProps={{
                          className: "credit-card-input-expiration",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="divbutton">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  disabled={isSubmitting}
                >
                  Enviar
                </Button>
              </div>
            </Form>
          </Paper>
        )}
      />
    </div>
  );
};

const createInvoice = async (course, values) => {
  let today = new Date();
  today.setDate(today.getDate() + 3);
  today = today.toISOString().split("T")[0];
  values.due_date = today;
  values.payable_with = ["pix"];
  values.cpf_cnpj = values.cpf;
  try {
    await axios.post(
      "/.netlify/functions/createinvoice_asaas-background?action=pix&turma=" +
        course,
      values,
      config
    );
    return "ok";
  } catch (e) {
    alert(e.response.data);
  }
};
