import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment activityFields on activity {
    available
    description
    lesson
    slides_download_link
    title
    deadline
    type
    ui_order
    video_url
  }
`;

export const GET = gql`
query getActivity($id: Int, $limit: Int) {
    activity(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
        id
        ...activityFields 
        lessonByLesson{
          id
          turmaByTurma{
            id
            name
          }
        }
    }
    activity_type{
      id
      name
    }
}
${FieldsFragment}
`;

export const INSERT = gql`
    mutation newActivity($objects: [activity_insert_input!]!) {
        insert_activity(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updateActivity($_set: activity_set_input={}, $id: Int){
    update_activity(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`

export const fieldtypes = { //mudar tipos dos campos
  available:"checkbox",
  lesson:"number",
  ui_order:"number",
}

export const defaults = {
  available: false,
  video_aspect_ratio: '56.25%'
}

export function flatten(data){
  data.forEach(d =>{
    if(d.lessonByLesson && d.lessonByLesson.turmaByTurma) {
      d.nome_turma = d.lessonByLesson.turmaByTurma.name
      delete d.lessonByLesson
    }
  });
  return data
}

export function lists(data){
  data.activity_type.forEach(activity_type=>{
    activity_type.id = activity_type.name
  })
  data.type = data.activity_type
  return data
}