/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";

const IMF = observer((props) => {
  var forms = {
    "Dados pessoais": ["email", "name", "cpf"],
    "Outras informações": ["utm", "utm_others"],
  };
  var turma = new URLSearchParams(props.location.search).get("turma");
  return (
    <>
      <div className="center">
        <div>
          <h1>Iniciação em Mindfulness Funcional</h1>
          <img
            src="https://images.unsplash.com/photo-1515871204537-49a5fe66a31f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aGVhcnRzfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
            style={{ maxWidth: "600px", maxHeight: "600px" }}
          />
          <p>Valor: R$ 5,00</p>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.history.push("/checkout-asaas", {
                items: [
                  {
                    description: "IMF",
                    quantity: 1,
                    price_cents: 500,
                  },
                ],
                course: "Especialização ACT",
                semestre: "2022-2",
                forms,
                turma,
              });
            }}
          >
            Prosseguir
          </Button>
        </div>
      </div>
    </>
  );
});

export default IMF;
