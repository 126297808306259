/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { Paper } from "@material-ui/core";
import "./Quiz.css";

const query = gql`
  query MyQuery($quiz: Int) {
    quiz(where: { id: { _eq: $quiz }, deleted_at: { _is_null: true } }) {
      id
      name
      img
      questions {
        id
        img
        pontos
        text
        null_question
        question_options {
          id
          text
          correct
        }
      }
    }
  }
`;

var shuffled = false;
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}
const Quiz = observer((props) => {
  const { attempt } = props.location.state;
  const quizId = attempt.quiz;
  const store = useContext(StoreContext);
  if (store.refetch) store.refetch();
  var { data, error, loading } = useQuery(query, {
    variables: { quiz: quizId },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  data = data.quiz[0];
  //shuffling questions and answers:
  if (!shuffled && data.shuffle_questions) {
    data.questions = shuffle(data.questions);
    data.questions.forEach((q) => {
      q.question_options = shuffle(q.question_options);
    });
    shuffled = true;
  }
  data.questions = data.questions.filter((question) => !question.null_question);

  const numQuestions = data.questions.length;
  const newTotal = 10;
  const pointsPerQuestion = newTotal / numQuestions;
  
  data.questions.forEach((question) => {
    question.pontos = pointsPerQuestion;
  });
  
  const answers = attempt.question_completions.map((o) => o.answer);
  var nota = 0;

  data.questions.forEach((question) => {
    question.color = "red";
    let valor = question.pontos;

    question.question_options.forEach((opt) => {
      if (opt.correct && answers.includes(opt.id)) {
        question.color = "green";
        nota += valor;
      }
    });
  });
  return (
    <>
      <div>
        <Paper elevation={2} p={2} style={{ background: "#ffffdd" }}>
          <div style={{ padding: 30 }}>
            <h2>{"Correção do quiz: " + data.name}</h2>
            <h3>
              Nota da tentativa anterior: {nota.toFixed(2)}/{newTotal}
            </h3>
            {data.img && (
              <img
                src={"https://drive.google.com/uc?id=" + data.img}
                style={{ margin: "10px" }}
              />
            )}
            <div>
              {data.questions
                .filter((question) => !question.null_question)
                .map((question) => {
                  return (
                    <>
                      <div style={{ color: question.color }}>
                        <p style={{ marginTop: "40px" }} />
                        <h3>
                          {question.text} (valor: {question.pontos.toFixed(2)})
                        </h3>
                        {question.img && (
                          <img
                            src={
                              "https://drive.google.com/uc?id=" + question.img
                            }
                            style={{ margin: "10px" }}
                          />
                        )}
                        {question.question_options.map((opt) => {
                          // eslint-disable-next-line no-unused-vars
                          var color = "gray";
                          var checked = answers.includes(opt.id);
                          return (
                            <p>
                              <input
                                type="radio"
                                name={question.id}
                                value={opt.id}
                                checked={checked}
                                color="blue"
                              />
                              {opt.text}
                            </p>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
});

export default Quiz;
