import PaymentsAdmin from "./PaymentsAdmin";
import PaymentsStudent from "./PaymentsStudent"
import React, { useContext } from "react";
import {StoreContext} from "../../store"
import { observer } from "mobx-react-lite";

const Payments = observer((props) => {  
  const store = useContext(StoreContext);
  return (
    <>    
    {store.admin? 
    <PaymentsAdmin props={props}/>
    : <PaymentsStudent />
    }
    </>
  );
});
  
export default Payments;