import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment quizFields on quiz {
    name
    img
    shuffle_questions
    disciplina_id
  }
`;

export const GET = gql`
query getQuiz($id: Int, $limit: Int, $person:Int) {
    quiz(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
        id
        ...quizFields
        questions(order_by:{id:asc}) {
            id
            text
            img
            pontos
            null_question
            question_options(order_by:{id:asc}) {
              id
              text
              correct
              img
            }
        }
    }
    quiz_attempt(where: {person: {_eq: $person}, quiz: {_eq: $id}, deleted_at: {_is_null: true}}, order_by:{id:asc}) {
      id
      created_at
    }
}
${FieldsFragment}
`;

export const INSERT = gql`
  mutation insertQuiz($objects: [quiz_insert_input!]! = {}) {
    insert_quiz(objects: $objects) {
      affected_rows
    }
  }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updateQuiz($_set: quiz_set_input={}, $id: Int){
    update_quiz(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`

export const NEWATTEMPT = gql`
mutation newQuizAttempt($objects: [quiz_attempt_insert_input!]!) {
    insert_quiz_attempt(objects: $objects) {
      affected_rows
    }
  }
`
export const countAttempts= gql`
query MyQuery($person: Int, $quiz: Int) {
  quiz_attempt_aggregate(where: {person: {_eq: $person}, quiz: {_eq: $quiz}, deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
`;

export function flatten(data){
    delete data.created_at
    data.forEach(quiz=>{ 
      if(quiz.questions) quiz.questions.forEach((question,i)=>{
        quiz['Pergunta '+(i+1)]=question.text
      })
      delete quiz.questions
    })    
    return data
}

export const fieldtypes = {
  shuffle_questions: "checkbox",
}

export const defaults={
  shuffle_questions: false
}