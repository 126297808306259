import React from "react";
import { Field, Form } from "formik";
import { Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import "../../css/Apply.css";
import { useWindowWidth } from "@react-hook/window-size/throttled";

const CheckoutForm = ({
  course,
  semestre,
  forms,
  isSubmitting,
  items,
  person,
  setFieldValue,
  prices,
}) => {
  const windowSize = useWindowWidth();
  if (!forms) forms = stdForm;
  else {
    if (prices) {
      questions.forma_pagamento.options = [""].concat(Object.keys(prices));
    }
    if (items[0].price_cents > 0) forms.Pagamento = stdForm.Pagamento;
  }
  var perguntas = forms;
  if (!perguntas || !semestre)
    return <h2>Erro: Faltam parâmetros (semestre).</h2>;
  if (person) {
    delete forms["Dados pessoais"];
    delete forms["Outras informações"];
  }

  return (
    <Form>
      <Paper elevation={2} style={{ padding: 30 }} id="paper">
        <h1>
          Formulário de inscrição {course} {semestre}:
        </h1>
        {Object.keys(perguntas).map((section) => {
          return (
            <>
              <div className="section">
                <h2 className="section-title">{section}</h2>
                {perguntas[section].map((k) => {
                  return (
                    <div>
                      <p>
                        <div>
                          <Field
                            type={
                              questions[k].fieldtype
                                ? questions[k].fieldtype
                                : "text"
                            }
                            label={windowSize > 1100 ? questions[k].text : ""}
                            helperText={
                              windowSize <= 1100 ? questions[k].text : ""
                            }
                            name={
                              section === "Dados pessoais"
                                ? "personByPerson.data." + k
                                : k
                            }
                            fullWidth={true}
                            component={TextField}
                            multiline={true}
                            InputLabelProps={questions[k].inputLabelProps}
                            inputProps={questions[k].inputProps}
                            required={questions[k].required}
                            onInput={
                              questions[k].onInput
                                ? (e) => questions[k].onInput(e, setFieldValue)
                                : ""
                            }
                            variant="outlined"
                            select={questions[k].select}
                            SelectProps={{
                              native: true,
                            }}
                            validate={questions[k].validate}
                          >
                            {questions[k].options &&
                              questions[k].options.map((o) => {
                                return (
                                  <option value={o} className="p5">
                                    {o}
                                  </option>
                                );
                              })}
                          </Field>
                          {windowSize <= 1100 && <hr />}
                        </div>
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Enviar
        </Button>
      </Paper>
    </Form>
  );
};

export var questions = {
  email: {
    text: "Endereço de email",
    fieldtype: "email",
    required: true,
  },
  name: {
    text: "Nome completo",
    required: true,
  },
  cell_phone: {
    text: "Telefone para contato",
    required: true,
  },
  occupation: {
    text: "Profissão",
    required: true,
  },
  naturalidade: {
    text: "Naturalidade",
    required: true,
  },
  nacionalidade: {
    text: "Nacionalidade",
    required: true,
  },
  data_nascimento: {
    text: "Data de nascimento",
    required: true,
  },
  cpf: {
    text: "CPF",
    required: true,
    onInput: (e) => {
      var num = e.target.value.match(/[0-9]+/g);
      if (num) {
        num = num.join("").toString();
        if (num.length <= 3) {
          e.target.value = num;
        } else if (num.length <= 6) {
          e.target.value = num.slice(0, 3) + "." + num.slice(3);
        } else if (num.length <= 9) {
          e.target.value =
            num.slice(0, 3) + "." + num.slice(3, 6) + "." + num.slice(6);
        } else {
          e.target.value =
            num.slice(0, 3) +
            "." +
            num.slice(3, 6) +
            "." +
            num.slice(6, 9) +
            "-" +
            num.slice(9, 11);
        }
      }
    },
    validate: (value) => {
      var cpf = value;
      if (!cpf) cpf = "0";
      cpf = cpf.match(/[0-9]/g);
      if (!cpf) return "CPF inválido";
      cpf = cpf.join("").padStart(11, "0");
      var soma = 0;
      // eslint-disable-next-line eqeqeq
      if (cpf.replace(new RegExp(cpf[0], "g"), "") == "") {
        //verifica se todos os dígitos são iguais
        return "CPF inválido";
      }
      //primeiro dígito verificador:
      for (var char = 0; char < 9; char++) {
        soma += cpf[char] * (10 - char);
      }
      // eslint-disable-next-line eqeqeq
      if (((10 * soma) % 11) % 10 != cpf[9]) {
        return "CPF inválido";
      } //segundo dígito verificador:
      soma = 0;
      // eslint-disable-next-line no-redeclare
      for (var char = 0; char < 10; char++) {
        soma += cpf[char] * (11 - char);
      }
      // eslint-disable-next-line eqeqeq
      if (((10 * soma) % 11) % 10 != cpf[10]) {
        return "CPF inválido";
      }
    },
  },
  rg: {
    text: "RG",
    required: true,
  },
  uf_rg: {
    text: "UF do RG",
    required: true,
  },
  rg_orgao_expedidor: {
    text: "Órgão expedidor do RG",
    required: true,
  },
  address1: {
    text: "Endereço (Avenida, Rua)",
    required: true,
    inputLabelProps: { shrink: true },
  },
  address2: {
    text: "Complemento (apto, bloco, etc)",
    required: true,
    inputLabelProps: { shrink: true },
  },
  n_address: {
    text: "Número do endereço",
    required: true,
    inputLabelProps: { shrink: true },
  },
  district: {
    text: "Bairro",
    required: true,
    inputLabelProps: { shrink: true },
  },
  city: {
    text: "Cidade",
    required: true,
    inputLabelProps: { shrink: true },
  },
  state: {
    text: "UF",
    required: true,
    inputLabelProps: { shrink: true },
  },
  postal_code: {
    text: "CEP",
    required: true,
    onInput: async (e, setFieldValue) => {
      var cep = e.target.value.match(/[0-9]/g);
      if (cep) {
        cep = cep.join("");
        if (cep.length > 5) {
          e.target.value = cep.substring(0, 5) + "-" + cep.substring(5);
        }
        if (cep.length === 8) {
          try {
            var { data } = await axios.get(
              "https://ws.apicep.com/cep.json?code=" + cep
            );
            setFieldValue("personByPerson.data.address1", data.address);
            setFieldValue("personByPerson.data.district", data.district);
            setFieldValue("personByPerson.data.city", data.city);
            setFieldValue("personByPerson.data.state", data.state);
          } catch (e) {
            console.log(e.response.data);
          }
        }
      }
    },
  },
  idade: {
    //change to birth date?
    text: "Qual sua idade?",
    required: true,
    fieldtype: "number",
  },
  utm: {
    text: "Como você ficou sabendo do curso?",
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "E-mail",
      "Facebook",
      "Instagram",
      "Website",
      "Indicação",
      "Outros (especifique abaixo)",
    ],
  },
  utm_others: {
    text:
      "Se você marcou outros acima, por favor, especifique como você ficou sabendo",
  },
  forma_pagamento: {
    text: "Qual seria a forma de parcelamento",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "PIX", "1x (cartão)"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  renda_per_capita: {
    text:
      "Somando a sua renda com a renda de todas pessoas que moram com você e dividindo pelo número de pessoas da sua casa, quanto é, aproximadamente, a renda familiar mensal per capita?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "Até R$ 2.500,00 mensal.",
      "Entre R$ 2.500,01 e R$ 4.000,00 mensal.",
      "Mais de R$ 4.000,00 mensal.",
    ],
  },
};

const stdForm = {
  "Dados pessoais": ["email", "name", "cpf", "cell_phone", "postal_code", "address_number"],
  "Outras informações": ["utm", "utm_others"],
  Pagamento: ["forma_pagamento"],
};

export default CheckoutForm;
