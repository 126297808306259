import React, { useContext, useEffect, useState } from "react";
import { useQuery, useApolloClient } from "react-apollo-hooks";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import "../css/GradesStudent.css";
import GradesMonitors from "./Grades-monitors";
const GET_GRADES = gql`
  query GradesStudent($email: String, $turma: Int) {
    disciplina_grades(
      where: {
        person: { email: { _eq: $email } }
        turma: { id: { _eq: $turma } }
        disciplina_id: { _neq: 158 }
      }
    ) {
      id
      is_final
      grade
      attempts
      disciplina {
        name
        id
      }
    }
  }
`;

const GET_MOST_RECENT_ATTEMPT = gql`
  query getMostRecentAttempt(
    $person_id: Int
    $turma_id: Int
    $disciplina_id: Int
  ) {
    most_recent_attempt: quiz_attempt(
      where: {
        person: { _eq: $person_id }
        quizByQuiz: {
          quiz_relations: { turma: { _eq: $turma_id } }
          disciplina_id: { _eq: $disciplina_id }
        }
      }
      order_by: { updated_at: desc }
    ) {
      id
      quiz
      question_completions {
        answer
        id
        question_option {
          id
          correct
        }
      }
    }
  }
`;

const GradesStudent = () => {
  const [grades, setGrades] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const store = useContext(StoreContext);
  const client = useApolloClient();
  const variables = { turma: store.ui.turma.id, email: store.email };
  const { data, loading } = useQuery(GET_GRADES, {
    variables: variables,
  });

  const history = useHistory();
  useEffect(() => {
    if (data && data.disciplina_grades) {
      const promises = data.disciplina_grades.map((grade) =>
        client.query({
          query: GET_MOST_RECENT_ATTEMPT,
          variables: {
            person_id: store.personId,
            turma_id: store.ui.turma.id,
            disciplina_id: grade.disciplina.id,
          },
        })
      );
      Promise.all(promises)
        .then((attemptDataArray) => {
          const reshapedGrades = data.disciplina_grades.map((grade, index) => {
            const attemptsData =
              attemptDataArray[index].data.most_recent_attempt;
            const mostRecentValidAttempt = attemptsData.find(
              (attempt) =>
                attempt.question_completions &&
                attempt.question_completions.length > 0
            );

            let redoUrl = "";
            let correctionUrl = "";
            let attemptDetails = null;

            if (mostRecentValidAttempt && grade.attempts < 3) {
              redoUrl = `/quiz/${mostRecentValidAttempt.quiz}`;
              attemptDetails = mostRecentValidAttempt;
            }
            if (grade.attempts === 3 && mostRecentValidAttempt) {
              correctionUrl = `/quiz/${mostRecentValidAttempt.quiz}`;
            }

            if (
              grade.attempts === 3 &&
              !mostRecentValidAttempt &&
              attemptsData.length > 0
            ) {
              correctionUrl = `/quiz/${attemptsData[0].quiz}`;
            }
            return {
              ...grade,
              disciplina_name: grade.disciplina.name,
              attempts_formatted: `${grade.attempts}/3`,
              grade_formatted: `${grade.grade}/10 (${(
                (grade.grade / 10) *
                100
              ).toFixed(2)}%)`,
              redo: redoUrl,
              correction:
                attemptsData.length === 3 ? correctionUrl : attemptDetails,
            };
          });

          setGrades(reshapedGrades);
          setLoadingTable(false);
        })
        .catch((error) => {
          console.error("Error fetching most recent attempt:", error);
        });
    }
  }, [data, client, store.ui.turma.id, history, store.personId]);

  if (loading) {
    return <p className="loading"> Buscando dados...</p>;
  }

  const columns = [
    {
      name: "disciplina_name",
      label: "Questionário",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "grade_formatted",
      label: "Nota",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let grade = grades[dataIndex].grade;
          let attempts = grades[dataIndex].attempts;
          let percentage = grade * 10;
          let gradeFormatted;
          if (grades[dataIndex].disciplina.id === 84 && attempts === 1) {
            gradeFormatted = `${grade.toFixed(2)}/10 (Nota Parcial)`;
          } else if (grades[dataIndex].disciplina.id === 84 && attempts === 2) {
            gradeFormatted = `${grade.toFixed(2)}/10 (Nota Final)`;
          } else {
            gradeFormatted = `${grade.toFixed(2)}/10 (${percentage.toFixed(
              0
            )}%)`;
          }
          return (
            <div
              style={{
                fontWeight: "bold",
                color: grade < 7 ? "red" : "black",
              }}
            >
              {gradeFormatted}
            </div>
          );
        },
      },
    },
    {
      name: "attempts_formatted",
      label: "Tentativas",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div style={{ fontWeight: "bold" }}>
              {grades[dataIndex].attempts_formatted}
            </div>
          );
        },
      },
    },
    {
      name: "redo",
      label: "Refazer",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return grades[dataIndex].redo ? (
            <a href={grades[dataIndex].redo} className="link-grade-studant">
              Refazer
            </a>
          ) : (
            "Tentativas esgotadas"
          );
        },
      },
    },
    {
      name: "correction",
      label: "Correção",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          if (
            grades[dataIndex].correction &&
            typeof grades[dataIndex].correction === "string"
          ) {
            return (
              <a
                href={grades[dataIndex].correction}
                className="link-grade-studant"
              >
                Gabarito
              </a>
            );
          } else if (grades[dataIndex].correction) {
            return (
              <button
                className="link-grade-studant"
                onClick={() =>
                  history.push("/quizanalysis", {
                    attempt: grades[dataIndex].correction,
                  })
                }
              >
                Correção
              </button>
            );
          }
          return null;
        },
      },
    },
  ];

  const theme = createTheme({
    typography: {
      useNextVariants: true,
      h6: {
        color: "#801819",
        fontWeight: "bold",
      },
    },
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: "400px",
        },
      },
    },
  });

  const options = {
    responsive: "scrollMaxHeight",
    rowsPerPageOptions: [10, 50, 200],
    rowsPerPage: 200,
    selectableRows: false,
    filterType: "multiselect",
    sortOrder: {
      name: "disciplina_name",
      direction: "asc",
    },
  };

  return (
    <div className="container-table">
      {loadingTable ? (
        <p className="loading"> Buscando dados...</p>
      ) : (
        <>
          <MuiThemeProvider theme={theme}>
            <MUIDataTable
              title={"Notas"}
              data={grades}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
          <br /> <br />
          {store.is_monitor ? <GradesMonitors /> : <></>}
        </>
      )}
    </div>
  );
};

export default GradesStudent;
