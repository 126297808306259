import React, { useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { textLabels } from "../dataTablesLocalization";
import { StoreContext } from "../../store";
import { Grid, TextField } from "@material-ui/core";
import gql from "graphql-tag";
import Dashboard from "./Dashboard";

const Analysis = observer((props) => {
  const store = useContext(StoreContext);
  const [state, setState] = useState({
    course: "Formação MF",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value ? event.target.value : null;
    setState({
      ...state,
      [name]: value,
    });
  };
  var {
    data: dataPerson,
    error: errorPerson,
    loading: loadingPerson,
  } = useQuery(queryPerson, { variables: { email: store.email } });
  if (dataPerson && dataPerson.person && !state.agent) {
    var agent = dataPerson.person[0].first_name + "%";
    if (!store.admin) setState({ agent });
  }
  var { data, error, loading } = useQuery(query, { variables: state });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (HuggyAnalysis.jsx): {error.message}</p>;
  if (loadingPerson) return <p>Carregando... </p>;
  if (errorPerson)
    return <p>ERROR (HuggyAnalysis.jsx): {errorPerson.message}</p>;
  var dataView = JSON.parse(JSON.stringify(data.huggy_chat)); //clone data to preserve original object
  var consultants = JSON.parse(JSON.stringify(data.consultant));
  consultants = consultants.map((con) => {
    return {
      name: con.personByPerson.first_name,
      applications: con.applications,
    };
  }); //make application index per consultant
  dataView = dataView.map((d) => {
    let status =
      d.last_message_sender === d.agent
        ? "Aguardando resposta do cliente"
        : "Sem resposta do agente";
    d = { ...d, status };
    delete d["__typename"];
    return d;
  });
  return (
    <>
      <div>
        <Dashboard attendances={dataView} consultants={consultants} />
        <h3>Filtro de data para a tabela (última mensagem):</h3>
        <TextField
          name="date_from"
          label="Data inicial"
          InputLabelProps={{ shrink: true }}
          type="date"
          style={{ margin: "10px" }}
          onChange={handleChange}
          value={state.date_from}
        />
        <TextField
          name="date_to"
          label="Data final"
          InputLabelProps={{ shrink: true }}
          type="date"
          style={{ margin: "10px" }}
          onChange={handleChange}
          value={state.date_to}
        />
      </div>
      <Grid container>
        <div
          style={{
            display: "table",
            tableLayout: "fixed",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <MUIDataTable
            title="Atendimentos"
            data={dataView}
            columns={translate(dataView)}
            options={{
              responsive: "scroll",
              rowsPerPageOptions: [50, 100, 200],
              rowsPerPage: 200,
              selectableRows: false,
              filterType: "multiselect",
              textLabels,
            }}
          />
        </div>
      </Grid>
    </>
  );
});

export default Analysis;

const query = gql`
  query chats(
    $agent: String
    $department: String
    $situation: String
    $tabulation: String
    $date_from: timestamp
    $date_to: timestamp
    $course: String
  ) {
    huggy_chat(
      where: {
        agent: { _ilike: $agent }
        department: { _eq: $department }
        situation: { _eq: $situation }
        tabulation: { _eq: $tabulation }
        last_message_date: { _gte: $date_from, _lte: $date_to }
        deleted_at: { _is_null: true }
      }
      order_by: { last_message_date: desc }
    ) {
      id
      agent
      customer_email
      customer_name
      last_message_text
      department
      last_message_date
      last_message_sender
      situation
      tabulation
    }
    consultant(
      where: {
        applications: { course: { _eq: $course }, nivel: { _eq: 1 } }
        personByPerson: { first_name: { _ilike: $agent } }
      }
      order_by: {
        personByPerson: { first_name: asc }
        deleted_at: { _is_null: true }
      }
    ) {
      applications {
        fatura_status
      }
      personByPerson {
        first_name
      }
    }
  }
`;
const queryPerson = gql`
  query name($email: String) {
    person(where: { email: { _eq: $email }, deleted_at: { _is_null: true } }) {
      first_name
    }
  }
`;

const translate = (data) => {
  const portuguese = {
    agent: "consultora",
    customer_email: "cliente_email",
    customer_name: "cliente",
    department: "departamento",
    last_message_date: "data da última mensagem",
    last_message_sender: "enviada por",
    last_message_text: "última mensagem",
    tabulation: "tabulação",
  };
  if (!data[0]) return [];
  else {
    return Object.keys(data[0]).map((label) => {
      if (portuguese[label])
        return {
          name: label,
          label: portuguese[label],
        };
      else return label;
    });
  }
};
