import {
  getBaseStudentData,
  log,
  getPersonId,
  getTutor,
} from "../queriesAndMutations";

export const logLogin = async (personId, client, data) => {
  // eslint-disable-next-line no-unused-vars
  const result = await client.mutate({
    mutation: log,
    variables: {
      personId: personId,
      action: "login",
      payload: data,
    },
  });
};

export const setPersonId = async (store) => {
  const result = await store.apolloClient.query({
    query: getPersonId,
    variables: { email: store.email },
  });
  const personArr = result.data.person;
  if (personArr[0] && personArr[0].id) {
    store.personId = personArr[0].id;
  }
};

export const setTutor = async (store) => {
  const result = await store.apolloClient.query({
    query: getTutor,
    variables: { email_tutor: store.email },
  });
  if (
    result.data &&
    result.data.student_tutor_view &&
    result.data.student_tutor_view.length > 0 &&
    result.data.student_tutor_view[0].tutor_id
  ) {
    store.is_tutor = true;
    localStorage.setItem("isTutor", true);
  }
};

export const setBaseStudentData = async (store) => {
  const { data, errors } = await store.apolloClient.query({
    query: getBaseStudentData,
    variables: {
      email_monitor: store.email,
      today: new Date().toISOString().split("T")[0],
    },
  });
  if (errors) {
    return null;
  }
  const personArr = data.person;
  if (personArr[0] && personArr[0].id) {
    store.personId = personArr[0].id;
    await logLogin(
      store.personId,
      store.apolloClient,
      `${JSON.stringify(data)} Version: ${store.version}`
    );
  }
  const monitorandos =
    personArr[0] && personArr[0].monitorandos ? personArr[0].monitorandos : [];
  if (monitorandos.length > 0) {
    store.is_monitor = true;
    localStorage.setItem("isMonitor", true);
  }
  if (
    personArr[0] &&
    personArr[0].project_students &&
    personArr[0].project_students.length > 0
  ) {
    store.is_evaluator = true;
    localStorage.setItem("isEvaluator", true);
  }
  if (personArr[0] && personArr[0].consulting) {
    store.is_consultant = true;
    localStorage.setItem("isConsultant", true);
    store.consultantId = personArr[0].consulting.id;
  }
  const courses_managed = !personArr[0]
    ? []
    : personArr[0].courses_managed.map((m) => m.course);
  store.courses_managed = courses_managed;
  let defaulting_courses = [];
  if (personArr[0])
    personArr[0].enrollments.forEach((e) => {
      let plan = e.payment_plans[0];
      if (!plan || !plan.payments[0]) return null;
      else
        e.registers.forEach((r) => {
          defaulting_courses.push({
            curso: r.turmaByTurma.id,
            date: plan.payments[0].due_date,
          });
        });
    });
  store.defaulting_courses = defaulting_courses;
  var turmas = [];
  if (personArr[0])
    personArr[0].enrollments.forEach((e) => {
      turmas = turmas.concat(
        e.registers.map((r) => ({
          id: r.turmaByTurma.id,
          name: r.turmaByTurma.name,
          category_name: r.turmaByTurma.category_name,
          welcome_message: r.turmaByTurma.welcome_message,
          max_activity_characters: r.turmaByTurma.max_activity_characters,
          max_activity_words: r.turmaByTurma.max_activity_words,
          course: r.turmaByTurma.course,
          deadline: r.turmaByTurma.deadline,
          org: r.turmaByTurma.courseByCourse?.org, // Add this line
        }))
      );
    });
  //sorting:
  let pos = 0;
  for (pos; pos < turmas.length - 1; pos++) {
    for (var i = pos + 1; i < turmas.length; i++) {
      if (turmas[i].id > turmas[pos].id) {
        let temp = turmas[i];
        turmas[i] = turmas[pos];
        turmas[pos] = temp;
      }
    }
  }
  store.turmas = turmas;
  const maxTurma = turmas.reduce(
    (m, i) => {
      return i.id > m.id ? i : m;
    },
    { id: 0 }
  );
  if (maxTurma.id > 0) {
    if (store.ui.turma.id === 9999 || !localStorage.getItem("lastTurma"))
      store.ui.turma = maxTurma;
  }
};
