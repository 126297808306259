/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-loop-func */
import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { Paper } from "@material-ui/core";
import "./Quiz.css";

const query = gql`
  query Rec($person: Int, $turma: Int) {
    quiz(
      where: {
        quiz_relations: {
          turma: { _eq: $turma }
          deleted_at: { _is_null: true }
        }
        name: { _ilike: "%Recuperação%" }
        deleted_at: { _is_null: true }
      }
    ) {
      id
      name
      img
      shuffle_questions
      questions {
        id
        img
        pontos
        text
        null_question
        question_options {
          id
          text
          correct
        }
      }
      quiz_attempts(
        where: { person: { _eq: $person }, deleted_at: { _is_null: true } }
      ) {
        id
        quiz
        question_completions {
          id
          answer
          question_option {
            id
            correct
            questionByQuestion {
              pontos
              null_question
            }
          }
        }
      }
    }
  }
`;

const Quiz = observer((props) => {
  const store = useContext(StoreContext);
  var { data, error, loading } = useQuery(query, {
    variables: { turma: store.ui.turma.id, person: store.personId },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  data = data.quiz[0];
  if (!data)
    return (
      <p>
        Erro: Esta turma ({store.ui.turma.name}) não tem avaliação de
        recuperação registrada.
      </p>
    );
  if (data.quiz_attempts.length === 0)
    return <p>Desculpe, você não fez a prova de recuperação nesta turma.</p>;
  var bestAttempt = {};
  data.quiz_attempts.forEach((at) => {
    var nota = 0;
    var total = 0;
    var answers = {};
    at.question_completions.forEach((answer) => {
      //calcula nota
      answers[answer.answer] = answer.question_option.correct;
      var valor = answer.question_option.questionByQuestion.pontos;
      if (
        answer.question_option.correct ||
        answer.question_option.questionByQuestion.null_question
      ) {
        if (valor) nota += valor;
      }
      total += valor;
    });
    if ((!bestAttempt.nota || bestAttempt.nota <= nota) && total > 0) {
      bestAttempt = at;
      bestAttempt.nota = nota;
      bestAttempt.answers = answers;
      bestAttempt.total = total;
    }
  });
  if (bestAttempt.nota >= 7)
    return (
      <p>
        Você foi aprovado na recuperação desta turma, não é necessário fazer a
        2ª.
      </p>
    );
  // eslint-disable-next-line no-unused-vars
  let toRemove = [];
  for (var i = 0; i < data.questions.length; i++) {
    let question = data.questions[i];
    question.question_options.forEach((qo) => {
      if (qo.correct && bestAttempt.answers[qo.id]) question.correct = true;
    });
  }
  return (
    <>
      <div>
        <Paper elevation={2} p={2} style={{ background: "#ffffdd" }}>
          <div style={{ padding: 30 }}>
            <h2>
              Avaliação da 2ª Recuperação da turma {store.ui.turma.name}
              <p>
                Abaixo consta uma lista das questões que você errou na sua
                melhor tentativa da Avaliação de Recuperação (ou seja, se você
                fez mais de uma tentativa, esta lista aqui vai se referir à
                tentativa em que você obteve a maior nota), juntamente com o
                gabarito correto de cada questão. Observe que a sua resposta
                (errada) está escrita em vermelho e a resposta correta está em
                verde.{" "}
              </p>
              <p>
                Você deverá analisar todas as respostas e apresentar o seguinte:
                <li>
                  Explique por que a sua resposta original está errada. Por
                  exemplo, se a pergunta era “O que é Aceitação” e um(a)
                  aluno(a) marcou a alternativa errada que dizia “Uma forma de
                  respirar que traz relaxamento”, esse(a) aluno(a) poderia
                  explicar que essa alternativa está errada porque “aceitação
                  não é um exercício de respiração e não tem como objetivo o
                  relaxamento”.
                </li>
                <li>
                  Reveja o conteúdo e justifique sobre o gabarito correto da
                  questão, explicando o seu entendimento sobre essa resposta. Ou
                  seja, escreva com suas palavras uma ou mais frases em que
                  demonstra entender esta resposta correta. Por exemplo, se a
                  pergunta era “O que é Aceitação” e a resposta correta na prova
                  original era “Um dos seis processos do hexaflex de ACT”, você
                  pode escrever aqui qualquer coisa que demonstre que você
                  entendeu que Aceitação é um dos seis processos do hexaflex.
                  Uma possibilidade seria listar os seis processos simplesmente,
                  dizendo: “Os seis processos do hexaflex de ACT são Momento
                  Presente, Aceitação, Desfusão, Self-como-contexto, Valores e
                  Ação com compromisso, portanto Aceitação é um destes
                  processos”. Não existe uma única resposta certa, o importante
                  é você poder articular uma (ou mais) frases que demonstrem que
                  você entendeu a alternativa correta da prova original.
                </li>
              </p>
              Esta avaliação deverá ser escrita num arquivo em word e enviada
              para o email da secretaria.
            </h2>
            <h3>Nota: {bestAttempt.nota + "/" + bestAttempt.total}</h3>
            // eslint-disable-next-line jsx-a11y/alt-text
            {data.img && (
              <img
                src={"https://drive.google.com/uc?id=" + data.img}
                style={{ margin: "10px" }}
              />
            )}
            <div>
              {data.questions.map((question) => {
                if (question.correct) return "";
                return (
                  <>
                    <div>
                      <p style={{ marginTop: "40px" }} />
                      <h3>
                        {question.text + " (valor: " + question.pontos + ")"}
                      </h3>
                      {question.img && (
                        <img
                          src={"https://drive.google.com/uc?id=" + question.img}
                          style={{ margin: "10px" }}
                        />
                      )}
                      {question.null_question ? (
                        <p style={{ color: "green" }}>
                          <input type="radio" checked={true} color="green" />
                          Questão Anulada
                        </p>
                      ) : (
                        question.question_options.map((opt) => {
                          if (
                            !opt.correct &&
                            bestAttempt.answers[opt.id] === undefined
                          )
                            return "";
                          var color = "gray";
                          var checked = false;
                          if (
                            Object.keys(bestAttempt.answers).includes(
                              opt.id.toString()
                            )
                          ) {
                            checked = true;
                            color = bestAttempt.answers[opt.id]
                              ? "green"
                              : "red";
                          } else if (opt.correct) {
                            color = "green";
                          }
                          return (
                            <p style={{ color: color }}>
                              (
                              {opt.correct
                                ? "Resposta correta"
                                : "Resposta escolhida"}
                              ) {opt.text}
                            </p>
                          );
                        })
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
});

export default Quiz;
