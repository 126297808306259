/* eslint-disable no-ex-assign */
import React, { useState, Fragment, useContext } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo-hooks";
import { Formik, Field, Form } from "formik";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { TextField } from "formik-material-ui";
import { filter } from "graphql-anywhere";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { StoreContext } from "../store";
import { Link } from "react-router-dom";
import "../css/attachmentsStudent.css";

const styles = {
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 15,
    marginRight: 15,
  },
};

const PersonFieldsFragment = gql`
  fragment personFields on person {
    first_name
    last_name
    email
    cell_phone
    dob
    gender
    rg
    uf_rg 
    rg_orgao_expedidor 
    occupation
    address1
    address2
    n_address
    district
    city
    state
    postal_code
    emergency_contact_name
    emergency_contact_phone
    nacionalidade
    naturalidade
    data_nascimento
  }
`;

const GET_STUDENT = gql`
  query getStudent($email: String) {
    person(where: { email: { _eq: $email }, deleted_at: { _is_null: true } }) {
      id
      ...personFields
    }
  }
  ${PersonFieldsFragment}
`;

const GET_ANY_STUDENT = gql`
  query getAnyStudent {
    person(limit: 1) {
      id
      ...personFields
    }
  }
  ${PersonFieldsFragment}
`;

const EDIT_STUDENT = gql`
  mutation editStudent(
    $first_name: String
    $last_name: String
    $email: String
    $cell_phone: String
    $dob: date
    $gender: String
    $rg: String
    $uf_rg: String
    $rg_orgao_expedidor: String
    $occupation: String
    $address1: String
    $address2: String
    $n_address: Int
    $district: String
    $city: String
    $state: String
    $postal_code: String
    $emergency_contact_name: String
    $emergency_contact_phone: String
    $nacionalidade: String
    $naturalidade: String
    $data_nascimento: String
  ) {
    update_person(
      where: { email: { _eq: $email } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        cell_phone: $cell_phone
        dob: $dob
        rg: $rg
        uf_rg: $uf_rg
        rg_orgao_expedidor: $rg_orgao_expedidor
        occupation: $occupation
        gender: $gender
        address1: $address1
        address2: $address2
        n_address: $n_address
        district: $district
        city: $city
        state: $state
        postal_code: $postal_code
        emergency_contact_name: $emergency_contact_name
        emergency_contact_phone: $emergency_contact_phone
        nacionalidade: $nacionalidade
        naturalidade: $naturalidade
        data_nascimento: $data_nascimento
      }
    ) {
      affected_rows
      returning {
        id
        ...personFields
      }
    }
  }
  ${PersonFieldsFragment}
`;

const EditStudentUnstyled = (props) => {
  // null email will make this an InsertStudent component
  const email = props.studentEmail;
  const store = useContext(StoreContext);
  const [readOnly, setReadOnly] = useState(email ? true : false);
  const { classes } = props;
  const mutation = email ? EDIT_STUDENT : INSERT_STUDENT;
  const [update] = useMutation(mutation);
  // need to do this way b/c hooks can't be conditional
  // it'll help b/c creates required initValues from response
  const query = email ? GET_STUDENT : GET_ANY_STUDENT;
  const { data, error, loading } = useQuery(query, {
    variables: { email },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  const std = data.person[0];
  const initVals = filter(PersonFieldsFragment, std);
  // if inserting than set all fields to empty, if not just nulls
  Object.entries(initVals).forEach(([key, value]) => {
    if (!email || value === null) {
      initVals[key] = "";
    }
  });
  return (
    <Fragment>
      <Grid container spacing={24} alignItems="stretch" direction="column">
      <Link className="linkAnexos" to="/attachmentsPageStudent">Meus Anexos</Link>
        {!email && (
          <Typography variant="h6" gutterBottom>
            Criar novo aluno:
          </Typography>
        )}
        {email && (
          <FormControlLabel
            control={
              <Switch
                checked={!readOnly}
                onChange={(e) => setReadOnly(!readOnly)}
                value="editar"
                color="primary"
              />
            }
            label="Editar"
          />
        )}
        <Formik
          initialValues={initVals}
          onSubmit={async (values, actions) => {
            // copy object to avoid nulls warnings
            const newValues = { ...values };
            // update empty strings as null for UNIQUE fields
            ["cpf", "rg", "dob", "cell_phone"].forEach((k) => {
              if (newValues[k] === "") {
                newValues[k] = null;
              }
            });
            try {
              await update({ variables: { ...newValues } });
            } catch (err) {
              if (err.graphQLErrors[0]?.extensions?.internal)
                err =
                  "Error: " +
                  err.graphQLErrors[0]?.extensions?.internal.error.message;
              alert(err);
            }
            actions.setSubmitting(false);
            setReadOnly(true);
          }}
          render={({ errors, status, touched, isSubmitting }) => (
            <Form>
              <Grid item md={6}>
                <Paper className={classes.root} elevation={2}>
                  <Field
                    type="text"
                    label="Nome"
                    name="first_name"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly || !store.admin,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Sobrenome"
                    name="last_name"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly || !store.admin,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="email"
                    label="Email"
                    name="email"
                    fullWidth={true}
                    InputProps={{
                      readOnly: email ? true : false,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Celular/WhatsApp"
                    name="cell_phone"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />

                  <p />
                  <Field
                    type="text"
                    label="Gênero"
                    name="gender"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="date"
                    label="Data de Nascimento"
                    name="data_nascimento"
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="RG"
                    name="rg"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Orgão Expedidor (Ex: SSP)"
                    name="rg_orgao_expedidor"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="UF-RG (Ex: SP)"
                    name="uf_rg"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Nacionalidade (Brasileiro, etc)"
                    name="nacionalidade"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Naturalidade (Cidade e Estado de nascimento)"
                    name="naturalidade"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Profissão"
                    name="occupation"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                </Paper>
              </Grid>
              <p />
              <Grid item md={6}>
                <Paper className={classes.root} elevation={2}>
                  <Field
                    type="text"
                    label="Endereço"
                    name="address1"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Complemento (apto, bloco, etc)"
                    name="address2"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <Field 
                    type="number"
                    label="Número do endereço"
                    name="n_address"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Bairro"
                    name="district"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <Field
                    type="text"
                    label="Cidade"
                    name="city"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="UF"
                    name="state"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="CEP"
                    name="postal_code"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Nome do contato de emergência"
                    name="emergency_contact_name"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Telefone do contato de emergência"
                    name="emergency_contact_phone"
                    fullWidth={true}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    component={TextField}
                  />
                </Paper>
              </Grid>
              <p />
              {!readOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {email ? "Atualizar" : "Criar Aluno"}
                </Button>
              )}
            </Form>
          )}
        />
      </Grid>
    </Fragment>
  );
};

const INSERT_STUDENT = gql`
  mutation insertStudent(
    $first_name: String
    $last_name: String
    $email: String
    $cell_phone: String
    $dob: date
    $gender: String
    $cpf: String
    $rg: String
    $occupation: String
    $address1: String
    $address2: String
    $n_address: Number
    $city: String
    $state: String
    $postal_code: String
    $data_nascimento: String
    $nacionalidade: String
    $naturalidade: String
  ) {
    insert_person(
      objects: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        cell_phone: $cell_phone
        dob: $dob
        cpf: $cpf
        rg: $rg
        occupation: $occupation
        gender: $gender
        address1: $address1
        address2: $address2
        n_address: $n_address
        city: $city
        state: $state
        postal_code: $postal_code
        data_nascimento: $data_nascimento
        nacionalidade: $nacionalidade
        naturalidade: $naturalidade
      }
    ) {
      returning {
        id
        ...personFields
      }
    }
  }
  ${PersonFieldsFragment}
`;

export const EditStudent = withStyles(styles)(EditStudentUnstyled);
