import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { store } from "../store";
import TextField from "@material-ui/core/TextField";
import { logLogin } from "../Auth/Auth";
import axios from "axios";
import history from "../history";
import userLogin from "../assets/user-login.png"

import '../css/login.css'

// no-unused-expressions triggered because of the !store.apolloClient
const logPassworldLessAttempt = (email) => {
  // eslint-disable-next-line no-unused-expressions
  !store.apolloClient
    ? console.log("Sem Apollo Provider")
    : logLogin(
      0,
      store.apolloClient,
      `Asked for email: ${email}\nVersion: ${store.version}`
    );
};

export default function LoginPasswordless() {
  const [submitted, setSubmitted] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    try {
      let res = await axios.get(
        `/.netlify/functions/sendlink?email=${data.email}`
      );
      setSubmitted(res.data);
    } catch (e) {
      // eslint-disable-next-line no-ex-assign
      if (e.response) e = e.response.data;
      setSubmitted(e);
    }
    logPassworldLessAttempt(data.email);
  };

  return (
    <div className="div-login">
      {!submitted &&
        (<div className="login-color">
          <div className="text-login">
            <p className="login-text">Seja bem-vindo ao Painel do Aluno! É um prazer tê-lo aqui e poder auxiliá-lo em sua jornada educacional.
              Para acessar o painel, é necessário inserir o seu endereço de e-mail cadastrado na instituição de ensino.
              Após inserir o e-mail, você receberá um link de acesso por e-mail. Acesse esse link para entrar no painel
              e ter acesso às suas informações acadêmicas, como notas, calendário escolar, materiais didáticos, entre outros recursos disponíveis.
              Fique à vontade para explorar todas as funcionalidades do painel e, caso precise de ajuda, conte conosco para auxiliá-lo.
              Desejamos a você uma excelente experiência no Painel do Aluno!</p>
          </div>
        </div>)}
      <div className="div-form-login">
        {!submitted && (
          <form onSubmit={handleSubmit(onSubmit)} className="form-login">
            <img src={userLogin} alt="user" className="user-login" />
            <TextField
              name="email"
              label="E-mail"
              inputRef={register({ required: true })}
              fullWidth
            />
            {/* errors will return when field validation fails  */}
            {errors.email && <span>Entre com seu email</span>}
            <p />
            <button className="bnt-login">
              Entrar
            </button>
          </form>
        )}
        {submitted && <h3>{submitted}</h3>}
      </div>
    </div>
  );
}

export const logout = () => {
  localStorage.removeItem("auth0:access_token");
  localStorage.removeItem("auth0:id_token");
  localStorage.removeItem("auth0:expires_at");
  localStorage.removeItem("auth0:id_token:email");
  localStorage.removeItem("auth0:id_token:sub");
  localStorage.removeItem("auth0:id_token:default_role");
  localStorage.removeItem("isMonitor");
  store.email = null;
  store.personId = null;
  store.default_role = null;
  store.auth_expires = null;
  history.replace("/");
};
