import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogTitle,
  ListItem,
  List,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CommentDialog from "./ActivityObsDialog";
import "../css/ActivitiesDialog.css";
export const ActivitiesDialog = ({ open, onClose, data }) => {
  const acts = data.activities.sort((a, b) => b.ui_order - a.ui_order);
  const [dialogState, setDialogState] = useState({
    open: false,
    data: {
      id: null,
    },
  });
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{`${data.first_name} ${data.last_name}: Lição ${data.lesson}`}</DialogTitle>
        <List>
          {acts.map(
            (act, i) =>
              act.completed && (
                <ListItem key={i}>
                  <ListItemText
                    primary={`${act.type}: ${act.title}`}
                    secondary={
                      <Fragment>
                        <Typography variant="caption" className="comment">
                          {act.comment}
                        </Typography>
                      </Fragment>
                    }
                    onClick={() => {
                      setDialogState({
                        open: true,
                        data: {
                          id: act.id,
                          obs: act.monitor_comment,
                          file: act.file,
                          email: data.email,
                          activity: act.type,
                          lesson: data.lesson,
                          monitor_file: act.monitor_file,
                          file_date: act.file_date,
                          file_folder_etc: act.file_folder_etc,
                          file_summary_etc: act.file_summary_etc,
                          file_video_etc: act.file_video_etc,
                        },
                      });
                    }}
                  />
                </ListItem>
              )
          )}
        </List>
      </Dialog>
      <CommentDialog
        open={dialogState.open}
        onClose={() => setDialogState({ ...dialogState, open: false })}
        data={dialogState.data}
      />
    </>
  );
};
