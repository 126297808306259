import React, { useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { textLabels } from "../dataTablesLocalization";
import { StoreContext } from "../../store";
import { Grid, NativeSelect, FormControl, InputLabel } from "@material-ui/core";
import gql from "graphql-tag";

const query = gql`
  query getPayments($from: date, $to: date, $turma: Int) {
    payment(
      where: {
        _and: {
          status: { _eq: "paid" }
          paid_at: { _gte: $from, _lt: $to }
          paymentPlanByPaymentPlan: {
            enrollmentByEnrollment: { registers: { turma: { _eq: $turma } } }
          }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { paid_at: asc }
    ) {
      paymentPlanByPaymentPlan {
        parcelas
        enrollmentByEnrollment {
          email
          person {
            first_name
            last_name
            cpf
            email
            address1
            address2
            n_address
            district
            city
            state
            postal_code
          }
        }
      }
      paid_at
      due_date
      value
      description
    }
  }
`;

const PaymentReport = observer((props) => {
  const store = useContext(StoreContext);
  const nextMonth = (month) => {
    var mo = month.split("-");
    mo[1]++;
    if (mo[1] > 12) {
      mo[1] = 1;
      mo[0]++;
    }
    mo[1] = mo[1].toString().padStart(2, "0");
    return mo.join("-");
  };
  var now = new Date().toISOString().match(/[0-9]{4}-[0-9]{2}/)[0];
  const [state, setState] = React.useState({
    from: now + "-01",
    to: nextMonth(now) + "-01",
  });
  const handleChange = (e) => {
    var mo = e.target.value;
    if (mo)
      setState({
        from: mo + "-01",
        to: nextMonth(mo) + "-01",
      });
  };

  var turma = store.ui.turma.id;
  var { data, error, loading } = useQuery(query, {
    variables: { turma: turma, from: state.from, to: state.to },
  });
  if (loading) return <p>Carregando do banco de dados... </p>;
  if (error) return <p>ERROR (Payments.jsx): {error.message}</p>;
  data = data["payment"];
  var dataView = JSON.parse(JSON.stringify(data)); //clone data to preserve original object
  dataView.forEach((payment) => {
    delete payment.__typename;
    var person = payment.paymentPlanByPaymentPlan.enrollmentByEnrollment.person;
    payment.nome = person.first_name + " " + person.last_name;
    payment.email = person.email;
    payment.CPF = person.cpf;
    payment.parcelas = payment.paymentPlanByPaymentPlan.parcelas;
    payment.endereco = person.address1 + " " + person.address2;
    var numero = person.n_address ? person.n_address : person.address1.match(/[0-9]+/g);
    if (!numero) numero = [0];
    payment.endereco_numero = numero[numero.length - 1];
    payment.bairro = person.district ? person.district : "-";
    payment.cidade = person.city ? person.city : "-";
    payment.uf = person.state ? person.state : "-";
    payment.cep = person.postal_code ? person.postal_code : "00000-000";
    delete payment.paymentPlanByPaymentPlan;
  });
  var range = ["2019-01"];
  while (range[0] < now) {
    range = [nextMonth(range[0]), ...range];
  }
  range = ["", ...range];
  return (
    <>
      <div>
        <FormControl className="formControl">
          <InputLabel htmlFor="month">Mês</InputLabel>
          <NativeSelect
            value={state.month}
            onChange={handleChange}
            inputProps={{
              name: "month",
              id: "month",
            }}
          >
            {range.map((month) => {
              return <option value={month}>{month}</option>;
            })}
          </NativeSelect>
        </FormControl>
      </div>
      <Grid container>
        <MUIDataTable
          title={"Pagamentos"}
          data={dataView}
          columns={[
            { name: "nome", label: "Nome" },
            { name: "paid_at", label: "data de pagamento" },
            { name: "value", label: "valor (R$)" },
            { name: "CPF", label: "CPF" },
            { name: "email", label: "Email" },
            { name: "parcelas", label: "total de parcelas" },
            { name: "description", label: "Descrição" },
            "endereco",
            "endereco_numero",
            "bairro",
            "cidade",
            "uf",
            "cep",
          ]}
          options={{
            responsive: "scroll",
            rowsPerPageOptions: [10, 50, 200],
            rowsPerPage: 200,
            selectableRows: false,
            filterType: "multiselect",
            textLabels,
          }}
        />
      </Grid>
    </>
  );
});

export default PaymentReport;
