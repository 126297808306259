import React, { useEffect, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import SavedSnack from "../SavedSnack";

// eslint-disable-next-line no-unused-vars
const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("auth0:id_token"),
  },
};

const HuggyControls = (props) => {
  const [state, setState] = useState({
    agents: [],
    balance: null,
  });

  useEffect(() => {
    const getAgents = async () => {
      axios
        .get("/.netlify/functions/huggy?action=getagents")
        .then((res) => {
          setState({ agents: res.data.agents, balance: res.data.balance });
        })
        .catch((e) => {
          setState({ agents: ["Erro ao buscar agentes online"] });
        });
    };
    getAgents();
  }, []);

  return (
    <>
      <Formik
        onSubmit={async (values, actions) => {
          try {
            let { data } = await axios.put(
              `/.netlify/functions/huggy?action=transfer`,
              values
            );
            setState({
              ...state,
              msg: JSON.stringify(data),
              snackbar: true,
            });
          } catch (e) {
            setState({
              ...state,
              msg: JSON.stringify(e.response.data),
              snackbar: true,
            });
          }
          actions.setSubmitting(false);
        }}
        initialValues={{ agent: 0, amount: 0 }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <h2>Transferir atendimentos:</h2>
            <Field
              name="agent"
              label="Agente"
              component={TextField}
              variant="outlined"
              select
              SelectProps={{
                native: true,
              }}
              style={{ marginRight: "10px", minWidth: "200px" }}
              onChange={(e) => {
                setFieldValue("agent", e.target.value);
              }}
            >
              <option value={0}></option>
              {state.agents.map((agent) => {
                return <option value={agent.id}>{agent.name}</option>;
              })}
            </Field>
            <Field
              name="amount"
              label="Quantidade"
              type="number"
              component={TextField}
              variant="outlined"
              onChange={(e) => {
                setFieldValue("amount", parseInt(e.target.value));
              }}
            />
            <p />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Transferir
            </Button>
            <div className="m10">Saldo de créditos(R$): ${state.balance}</div>
          </Form>
        )}
      </Formik>
      <SavedSnack
        open={state.snackbar}
        msg={state.msg}
        setOpen={(open) => {
          setState({
            ...state,
            snackbar: open,
          });
        }}
      />
    </>
  );
};
export default HuggyControls;
