import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2
  }
});

const SavedSnack = ({ classes, msg, open, setOpen }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      autoHideDuration={4000}
      onClose={() => setOpen(false)}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{msg}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Fechar"
          color="inherit"
          className={classes.close}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
};

export default withStyles(styles)(SavedSnack);
