import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Card, CardContent, Grid, TextField } from "@material-ui/core";
import "../../css/Dashboard.css";


const HuggyDashboard = observer((props) => {
  //Attention: the attendances need to be ordered by last_message, descending
  var [metrics, setMetrics] = useState({ //values in hours
    without_answer: 8,
    delay_answer: 2,
    asked_to_wait: 24,
    idle: 16
  });
  var count = JSON.parse(JSON.stringify(metrics));
  Object.keys(count).forEach(metric=>{
    count[metric]= {total:0};
    props.consultants.forEach(con=>{
      count[metric][con.name]=0;
    })
  })
  var consultants = {    
    "matrículas": { total:0 },
    "inscrições": { total:0 },
    "atendimentos": { total:0 }
  };
  props.consultants.forEach(consultant=>{
    let enrollments = 0;
    consultant.applications.forEach(app=>{
      if(app.fatura_status==="paid") enrollments++;
    });
    consultants["inscrições"][consultant.name]= consultant.applications.length;
    consultants["inscrições"].total+= consultant.applications.length;
    consultants["matrículas"][consultant.name]= enrollments;
    consultants["matrículas"].total+= enrollments;
    consultants.atendimentos[consultant.name]= 0;
  });
  consultants["atendimentos"]["Monica"]= 180;
  props.attendances.forEach(att=>{
    if(att.department==="Recepção" || att.department==="Comercial"){
      var consultant = null;
      Object.keys(consultants.atendimentos).forEach(name=>{
        if(att.agent && att.agent.includes(name))
          consultant = name;
      })
      if(att.situation==="in_chat"){
        Object.keys(metrics).forEach(metric=>{
          if(att.last_message_sender !== att.agent){          
            if(Date.now() - Date.parse(att.last_message_date) > metrics[metric]*3600*1000){
              if(metric === "delay_answer"){
                if(att.tabulation === "2. Distribuído"){
                  count[metric]["total"]++
                  if(consultant){
                    count[metric][consultant]++
                  }
                }
              }else if(metric === "asked_to_wait"){
                if(att.tabulation === "10. Pediu tempo"){
                  count[metric]["total"]++
                  if(consultant){
                    count[metric][consultant]++
                  }
                }
              }else{
                count[metric]["total"]++
                if(consultant){
                  count[metric][consultant]++
                }
              }
            }
          }
          if(metric === "idle" && Date.now() - Date.parse(att.last_message_date) > metrics[metric]*3600*1000){
            count[metric]["total"]++
            if(consultant){
              count[metric][consultant]++
            }
          }
        });
      }
      consultants.atendimentos.total++;
      if(consultant) consultants.atendimentos[consultant]++;
    }
  });  
  return (
    <>
    <div>
    <h1 className="center">Atualizado de hora em hora</h1>
      <Grid className="dashboard"> 
      <div className="dashboard">
      {Object.keys(count).map(key=>{
        return(
          <Card variant="outlined" className="dashboard-card">
            <CardContent>
              <div className="dashboard-text">
                <h3>{portuguese[key]}</h3>
                <font size="6"> 
                <TextField
                  variant="outlined"
                  type = "number"
                  value = {metrics[key]}
                  onChange = {e=>{
                    let updated = {                      
                      ...metrics,
                      [key]: e.target.value
                    }
                    setMetrics(updated)
                  }}
                />
                h</font>
              </div>
              <div>
               {Object.keys(count[key]).map(consultant=>{
                 return(
                   <>
                    {consultant}: {count[key][consultant]}
                    <br />
                  </>
                 )
               })}               
              </div>
            </CardContent>
          </Card>
          )
      })}
      </div>
      <div className="dashboard">
      {Object.keys(consultants).map(metric=>{
        return(
          <Card variant="outlined" className="dashboard-card">
            <CardContent>
              <div className="dashboard-text">
                <h3>{metric}</h3>
                {Object.keys(consultants[metric]).map(consultant=>{
                  return(
                    <>
                      {consultant}: {consultants[metric][consultant]} 
                      {metric!=="atendimentos" ? 
                        ` (${Intl.NumberFormat('pt-BR',{maximumFractionDigits:2}).format(100 * consultants[metric][consultant] / consultants.atendimentos[consultant])}%)`
                        : ""
                      }
                      <br />
                    </>
                  )
                })}
              </div>
            </CardContent>
          </Card>
        )
      })}
      </div>
    </Grid>
    </div>
    </>
  );
});

export default HuggyDashboard;

const portuguese = {
  without_answer: "Sem resposta da consultora",
  delay_answer: "Distribuído mas não iniciou conversa",
  asked_to_wait: "Cliente pediu tempo há mais de",
  idle: "Sem mensagens há"
}