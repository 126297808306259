import React, { useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import * as mobx from "mobx";
import AddButton from "./AddButton";
import { textLabels } from "./dataTablesLocalization";
import {
  allStudentsWithActivivities,
  getLessonNumbers,
  allStudentsWithActivivitiesNoMonitors,
} from "../queriesAndMutations";
import { ActivitiesDialog } from "./ActivitiesDialog";
import { StoreContext } from "../store";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

const lessonCols = (lessons, colsDisplay) =>
  lessons.map((l) => {
    var les = l.toString().replace(".", "-");
    return {
      name: `lesson${les}`,
      label: `Lição ${les}`,
      options: { display: colsDisplay.lessons.includes(l) },
    };
  });

const flattenCompletion = (lessons, completions, currentTurma) => {
  const flats = completions
    .map((c) => {
      if (!c.activityByActivity || !c.activityByActivity.lessonByLesson)
        return null;
      const lessonTurma = c.activityByActivity.lessonByLesson.turma;
      if (lessonTurma !== currentTurma) return null;
      const { id, completed, comment, date_done, monitor_comment, file, monitor_file, file_date, file_folder_etc, file_summary_etc, file_video_etc } = c;
      const { title, type, ui_order } = c.activityByActivity;
      const lessonNumber = c.activityByActivity.lessonByLesson.number;
      const cFull = {
        id,
        lessonNumber,
        title,
        completed,
        comment,
        date_done,
        type,
        ui_order,
        monitor_comment,
        file,
        monitor_file,
        file_date,
        file_summary_etc,
        file_folder_etc,
        file_video_etc

      };
      return cFull;
    })
    .filter((f) => f !== null);
  return filterLessons(lessons, flats);
};

const filterLessons = (lessons, flats) =>
  lessons.reduce((obj, l) => {
    const filtered = flats.filter((f) => f.lessonNumber === l);
    var les = l.toString().replace(".", "-");
    return {
      [`lesson${les}`]: filtered.length,
      [`activities-l${les}`]: filtered,
      ...obj,
    };
  }, {});

const completionsByPerson = (lessons, persons, currentTurma) =>
  persons.map((p) => {
    let title = [];
    let register = p.enrollments[0].registers[0];
    if (register) {
      if (register.suspended) title.push("SUSPENSO");
      if (register.cancelled) title.push("CANCELADO");
      if (register.locked) title.push("Matrícula trancada");
      if (register.dependency) title.push("fazendo dependência");
      if (register.is_tutor) title.push("Tutor");
      if (register.is_monitor) title.push("Monitor");
      if (register.is_professor) title.push("Professor");
      if (register.is_team_member) title.push("Equipe");
      if (register.is_audit) title.push("Ouvinte");
      if (register.is_evaluator) title.push("Avaliador");
    }
    title = title.length > 0 ? ` (${title.join(", ")})` : "";
    return {
      ...p,
      title,
      ...flattenCompletion(lessons, p.activity_completions, currentTurma),
    };
  });

const Alunos = observer((props) => {
  const [dialogState, setDialogState] = useState({
    open: false,
    data: {
      first_name: null,
      last_name: null,
      lesson: 0,
      activities: [],
    },
  });
  const store = useContext(StoreContext);
  const { data: lessonData, loading: lessonLoading } = useQuery(
    getLessonNumbers,
    {
      variables: { turmaId: store.ui.turma.id },
    }
  );

  let variables = store.admin
    ? { turma: store.ui.turma.id }
    : { turma: store.ui.turma.id, monitor: store.personId };
  const { data, error, loading } = store.admin
    ? useQuery(allStudentsWithActivivitiesNoMonitors, { variables })
    : useQuery(allStudentsWithActivivities, { variables });
  if (loading || lessonLoading || !data) return <p>Carregando... </p>;
  if (error) return <p>ERROR (Alunos.js): {error.message}</p>;

  // eslint-disable-next-line array-callback-return


  // goes into re-render loop without this, datatables may be reassigning
  // anyway mui-datatables takes care of reacting to this
  const colsDisplay = mobx.toJS(store.ui.alunos.columns);
  const lessons = lessonData.lesson.map((l) => l.number);
  // by default display only last 3 lessons info
  colsDisplay.lessons = lessons;
  //lessons.length > 3 ? lessons.slice(lessons.length - 3) : lessons;
  const columns = [
    { name: "id", label: "ID", options: { display: colsDisplay.id } },
    {
      name: "first_name",
      label: "Nome",
      options: {
        display: colsDisplay.first_name, setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "#fcf9f9",
          },
        }),
      },
    },
    {
      name: "last_name",
      label: "Sobrenome",
      options: { display: colsDisplay.last_name, },
    },
    {
      name: "cell_phone",
      label: "Telefone",
      options: { display: false },
    },
    {
      name: "title",
      label: "Função",
      options: { display: colsDisplay.title },
    },
    {
      name: "time",
      label: "último acesso",
      options: { display: colsDisplay.time },
    },

    { name: "email", label: "Email", options: { display: colsDisplay.email } },
    { name: "city", label: "Cidade", options: { display: colsDisplay.city } },
    { name: "state", label: "Estado", options: { display: colsDisplay.state } },
  ].concat(lessonCols(lessons, colsDisplay));


  const pessoas = completionsByPerson(lessons, data.person, store.ui.turma.id);
  return (
    <Grid container>
      <ActivitiesDialog
        open={dialogState.open}
        onClose={() => setDialogState({ ...dialogState, open: false })}
        data={dialogState.data}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ fontWeight: "bold", color: "white", marginRight: "10px" }}
        onClick={() => {
          props.history.push("/attachmentsPageAdmin");
        }}
        className="m10"
      >
        Anexos dos alunos
      </Button>
      <br />
      <br />
      <Button
        variant="contained"
        color="primary"
        style={{ fontWeight: "bold", color: "white" }}
        onClick={() => {
          props.history.push("/monitores");
        }}
        className="m10"
      >
        Monitoria
      </Button>
      <p />
      <div style={{ maxWidth: "80vw", border: "2px solid #00000069", marginTop: "50px" }}>
        <MUIDataTable
          title="Alunos"
          data={pessoas}
          columns={columns}
          options={{
            responsive: "scroll",
            fixedHeader: true,
            rowsPerPageOptions: [10, 50, 100],
            rowsPerPage: store.ui.alunos.rowsPerPage,
            onChangeRowsPerPage: (n) => (store.ui.alunos.rowsPerPage = n),
            onColumnViewChange: (col, action) => {
              const dict = { add: true, remove: false };
              store.ui.alunos.columns[col] = dict[action];
            },
            setRowProps: (row) => {
              const cursor = "pointer";
              return {
                style: {
                  cursor,
                  borderTop: "2px solid #00000069",
                },
              };
            },
            selectableRows: false,
            filterType: "multiselect",
            onCellClick: (_, cmeta) => {
              const cname = columns[cmeta.colIndex].name;
              const person = pessoas[cmeta.dataIndex];
              if (!(cname.substring(0, 6) === "lesson")) {
                props.history.push(`/edit/${person.email}`);
              } else {
                const lesson = cname.substring(6);
                setDialogState({
                  open: true,
                  data: {
                    first_name: person.first_name,
                    last_name: person.last_name,
                    lesson,
                    activities: person[`activities-l${lesson}`],
                    email: person.email,
                  },
                });
              }
            },
            customToolbar: () => {
              return <AddButton {...props} />;
            },
            textLabels,
          }}
        />
      </div>
    </Grid>
  );
});

export default Alunos;
