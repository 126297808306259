import React from "react";
import { Formik, Field, Form } from "formik";
import { Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import axios from "axios";
import "../css/Apply.css";
import Upload from "./Upload";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { StoreContext } from "../store";
import { useContext } from "react";
import { questions } from "./Apply";

// eslint-disable-next-line no-unused-vars
const mutation = gql`
  mutation insert($objects: [application_insert_input!]!) {
    insert_application(objects: $objects) {
      affected_rows
    }
  }
`;

export const forms = {
  especializacao: {
    "Dados pessoais": [
      "name",
      "marital_status",
      "occupation",
      "cpf",
      "rg",
      "postal_code",
      "address1",
      "address2",
      "district",
      "city",
      "state",
    ],
    "Perfil socioeconômico": [
      "idade",
      "etnia",
      "graduado",
      "curso_graduacao",
      "tempo_graduacao",
      "saude_publica",
      "funcao_saude",
      "professor",
      "renda_pessoal",
      "familiares",
      "renda_per_capita",
    ],
    "ESTOU CIENTE DE QUE:": [
      "termo_frequencia",
      "termo_aula_online_n2",
      "termo_atividades_extraclasse",
      "termo_executar_atividades",
      "termo_70_porcento",
      "comentario_mf",
      "horario_mf_n2",
    ],
    Pagamento: ["forma_pagamento_esp_n2", "vencimento", "comment"],
    "Seus anexos": [
      "cpf_arquivo",
      "id_arquivo",
      "certidao_arquivo",
      "endereco_arquivo",
      "diploma_frente",
      "diploma_verso",
      "historico_arquivo",
    ],
  },
  formacao: {
    "Dados pessoais": [
      "name",
      "marital_status",
      "occupation",
      "cpf",
      "rg",
      "postal_code",
      "address1",
      "address2",
      "district",
      "city",
      "state",
    ],
    "Perfil socioeconômico": [
      "idade",
      "etnia",
      "graduado",
      "curso_graduacao",
      "tempo_graduacao",
      "saude_publica",
      "funcao_saude",
      "professor",
      "renda_pessoal",
      "familiares",
      "renda_per_capita",
    ],
    "ESTOU CIENTE DE QUE:": [
      "termo_carga_mf_for_n2",
      "termo_frequencia",
      "termo_aula_online_n2",
      "termo_atividades_extraclasse",
      "termo_executar_atividades",
      "termo_70_porcento_n2",
      "comentario_mf",
      "horario_mf_n2",
    ],
    Pagamento: ["forma_pagamento_for", "vencimento", "comment"],
  },
};

const Apply = (props) => {
  const params = new URLSearchParams(props.location.search);
  const consultor = 2;
  const tipo = params.get("tipo");
  var curso = "MF";
  const bolsa = true;
  const semestre = params.get("semestre");
  const windowSize = useWindowWidth();
  const store = useContext(StoreContext);
  if (!curso.includes("Especialização") && !curso.includes("Formação")) {
    let prefix = tipo === "formacao" ? "Formação " : "Especialização ";
    curso = prefix + curso;
  }
  var perguntas = forms[tipo];
  if (!perguntas || !semestre) return <h2>Erro: Faltam parâmetros no link.</h2>;
  return (
    <>
      <Formik
        onSubmit={async (values, actions) => {
          values.personByPerson.data.email = store.email;
          let ultimaTurma = store.turmas;
          values.ultimaTurma = ultimaTurma[ultimaTurma.length - 1].name.match(
            /[0-9]{4}-[0-9]/
          )[0];
          values.nivel = 2;
          if (bolsa) values.bolsa = true;
          var missingFiles = false;
          if (perguntas["Seus anexos"]) {
            perguntas["Seus anexos"].forEach((k) => {
              if (questions[k].required && !values[k]) missingFiles = true;
            });
          }
          if (missingFiles) {
            alert("Faltam arquivos para enviar");
          } else {
            try {
              var { data } = await axios.post(
                "/.netlify/functions/apply",
                values
              );
              if (data.errors) {
                alert(data.errors[0].message);
              } else {
                if (data.data)
                  document.getElementById("paper").innerHTML =
                    "Formulário enviado com sucesso. Código: " +
                    data.data.insert_application_one.id;
              }
            } catch (e) {
              alert(JSON.stringify(e.response.data));
            }
          }
          actions.setSubmitting(false);
        }}
        initialValues={{ consultor, tipo, course: curso, semestre }}
        render={({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Paper elevation={2} style={{ padding: 30 }} id="paper">
              <h1>
                Formulário de inscrição {curso} {semestre} Nível 2:
              </h1>
              <h2>{tipo === "formacao" ? "Formação" : "Especialização"}</h2>
              {Object.keys(perguntas).map((section) => {
                return (
                  <>
                    <div className="section">
                      <h2 className="section-title">{section}</h2>
                      {perguntas[section].map((k) => {
                        return (
                          <div>
                            <p>
                              {questions[k].fieldtype !== "file" ? (
                                <div>
                                  <Field
                                    type={questions[k].fieldtype}
                                    label={
                                      windowSize > 1100 ? questions[k].text : ""
                                    }
                                    helperText={
                                      windowSize <= 1100
                                        ? questions[k].text
                                        : ""
                                    }
                                    name={
                                      section === "Dados pessoais"
                                        ? "personByPerson.data." + k
                                        : k
                                    }
                                    fullWidth={true}
                                    component={TextField}
                                    multiline={true}
                                    InputLabelProps={
                                      questions[k].inputLabelProps
                                    }
                                    inputProps={questions[k].inputProps}
                                    required={questions[k].required}
                                    onInput={
                                      questions[k].onInput
                                        ? (e) =>
                                            questions[k].onInput(
                                              e,
                                              setFieldValue
                                            )
                                        : ""
                                    }
                                    variant="outlined"
                                    select={questions[k].select}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    validate={questions[k].validate}
                                  >
                                    {questions[k].options &&
                                      questions[k].options.map((o) => {
                                        return (
                                          <option value={o} className="p5">
                                            {o}
                                          </option>
                                        );
                                      })}
                                  </Field>
                                  {windowSize <= 1100 && <hr />}
                                </div>
                              ) : (
                                <Upload
                                  setFieldValue={setFieldValue}
                                  prefix={
                                    values.personByPerson
                                      ? values.personByPerson.data.name
                                      : ""
                                  }
                                  folder={"INSCRIÇÃO"}
                                  turma={curso}
                                  label={questions[k].text}
                                  field={k}
                                />
                              )}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Enviar
              </Button>
            </Paper>
          </Form>
        )}
      />
    </>
  );
};
export default Apply;
