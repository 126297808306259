import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment question_optionFields on question_option {
    question
    text
    correct
    img
  }
`;

  

export const GET = gql`
query getQuestionOption($id: Int, $limit: Int) {
    question_option(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
        id
        ...question_optionFields         
    }
}
${FieldsFragment}
`;

export const INSERT = gql`
    mutation newquestion_option($objects: [question_option_insert_input!]!) {
        insert_question_option(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updateQuestionOption($_set: question_option_set_input={}, $id: Int){
    update_question_option(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, _set: $_set) {
      affected_rows
    }
  }
`

export const fieldtypes = {
    correct: "checkbox",
}

export const defaults = {
  correct: false
}

