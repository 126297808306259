import React from "react";
import { EditStudent } from "./student";
import { store } from "../store";
import { observer } from "mobx-react-lite";

const EditBasicData = observer(() => {
  const email = store.email;
  return <EditStudent studentEmail={email} />;
});

export default EditBasicData;
