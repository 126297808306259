/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import axios from "axios";
import "../css/Apply.css";
import Upload from "./Upload";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { useQuery } from "react-apollo-hooks";

// eslint-disable-next-line no-unused-vars
const mutation = gql`
  mutation insert($objects: [application_insert_input!]!) {
    insert_application(objects: $objects) {
      affected_rows
    }
  }
`;
const couponQuery = gql`
  query Coupon($code: String) {
    coupon(where: { code: { _eq: $code }, deleted_at: { _is_null: true } }) {
      email
      percent_value
    }
  }
`;

export const forms = {
  "Especialização MF": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "saude_publica",
        "funcao_saude",
        "professor",
        "renda_pessoal",
        "familiares",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "experiencia_mf",
        "motivo_mf",
        "historia_mf",
        "aplicar_conhecimentos_mf",
        "fechar_uma_semana",
        "horario_mf",
        "utm",
        "utm_others",
      ],
      "ESTOU CIENTE DE QUE:": [
        "termo_carga_mf_esp",
        "termo_frequencia",
        "termo_aula_online",
        "termo_atividades_extraclasse",
        "termo_executar_atividades",
        "termo_70_porcento",
        "comentario_mf",
      ],
      Pagamento: ["forma_pagamento_esp", "vencimento", "comment"],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
        "diploma_frente",
        "diploma_verso",
        "historico_arquivo",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "motivo_mf",
        "horario_mf",
        "utm",
        "utm_others",
      ],
      "ESTOU CIENTE DE QUE:": [
        "termo_carga_mf_esp",
        "termo_frequencia",
        "termo_aula_online",
        "termo_atividades_extraclasse",
        "termo_executar_atividades",
        "termo_70_porcento",
        "comentario_mf",
      ],
      Pagamento: ["forma_pagamento_esp", "vencimento", "comment"],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
        "diploma_frente",
        "diploma_verso",
        "historico_arquivo",
      ],
    },
  },
  "Formação MF": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "saude_publica",
        "funcao_saude",
        "professor",
        "renda_pessoal",
        "familiares",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "experiencia_mf",
        "motivo_mf",
        "historia_mf",
        "aplicar_conhecimentos_mf",
        "fechar_uma_semana",
        "horario_mf",
        "utm",
        "utm_others",
      ],
      "ESTOU CIENTE DE QUE:": [
        "termo_carga_mf_for",
        "termo_frequencia",
        "termo_aula_online",
        "termo_atividades_extraclasse",
        "termo_executar_atividades",
        "termo_70_porcento",
        "comentario_mf",
      ],
      Pagamento: ["forma_pagamento_for", "vencimento", "comment"],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "motivo_mf",
        "horario_mf",
        "utm",
        "utm_others",
      ],
      "ESTOU CIENTE DE QUE:": [
        "termo_carga_mf_for",
        "termo_frequencia",
        "termo_aula_online",
        "termo_atividades_extraclasse",
        "termo_executar_atividades",
        "termo_70_porcento",
        "comentario_mf",
      ],
      Pagamento: ["forma_pagamento_for", "vencimento", "comment"],
    },
  },
  "Especialização ACT": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "uf_rg",
        "rg_orgao_expedidor",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "experiencia_act",
        "motivo_act",
        "horario_act",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
        "diploma_frente",
        "diploma_verso",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "uf_rg",
        "rg_orgao_expedidor",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "motivo_act",
        "horario_act",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
        "diploma_frente",
        "diploma_verso",
      ],
    },
  },
  "Formação ACT": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "uf_rg",
        "rg_orgao_expedidor",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "experiencia_act",
        "motivo_act",
        "horario_act",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
      ],
      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "uf_rg",
        "rg_orgao_expedidor",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "motivo_act",
        "horario_act",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
      ],

      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
    },
  },
  auto: {
    normal: {
      "Dados pessoais": ["name", "email", "cpf"],
      Pagamento: ["forma_pagamento"],
    },
  },
  "Especialização FAP": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "data_nascimento",
        "nacionalidade",
        "uf_rg",
        "rg_orgao_expedidor",
        "naturalidade",
        "cell_phone",
        "rg",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "experiencia_act",
        "motivo_act",
        "horario_fap",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
        "diploma_frente",
        "diploma_verso",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "uf_rg",
        "rg_orgao_expedidor",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "motivo_act",
        "horario_fap",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
        "diploma_frente",
        "diploma_verso",
      ],
    },
  },
  "Formação FAP": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "uf_rg",
        "rg_orgao_expedidor",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "experiencia_act",
        "motivo_act",
        "horario_fap",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
        "diploma_frente",
        "diploma_verso",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "uf_rg",
        "rg_orgao_expedidor",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "motivo_act",
        "horario_fap",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
      ],
      Pagamento: ["forma_pagamento_esp", "associado", "vencimento", "comment"],
    },
  },
  "Módulo 1 RFT": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "uf_rg",
        "rg_orgao_expedidor",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "horario_rft",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_RFT_modulo1",
        "associado",
        "vencimento",
        "comment",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "uf_rg",
        "rg_orgao_expedidor",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "horario_rft",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_RFT_modulo1",
        "associado",
        "vencimento",
        "comment",
      ],
    },
  },
  "Curso Completo RFT": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "uf_rg",
        "rg_orgao_expedidor",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
        "horario_rft",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_RFT_completo",
        "associado",
        "vencimento",
        "comment",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "uf_rg",
        "rg_orgao_expedidor",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "horario_rft",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_RFT_completo",
        "associado",
        "vencimento",
        "comment",
      ],
    },
  },
  "Básico - ACT Crianças e Adolescentes": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "uf_rg",
        "rg_orgao_expedidor",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "horario_act_para_criancas_e_adolescentes",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_act_para_criancas_e_adolescentes_basico",
        "associado",
        "vencimento",
        "comment",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "uf_rg",
        "rg_orgao_expedidor",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "horario_act_para_criancas_e_adolescentes",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_act_para_criancas_e_adolescentes_basico",
        "associado",
        "vencimento",
        "comment",
      ],
    },
  },
  "Completo - ACT Crianças e Adolescentes": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "uf_rg",
        "rg_orgao_expedidor",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
        "horario_act_para_criancas_e_adolescentes",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_act_para_criancas_e_adolescentes_completo",
        "associado",
        "vencimento",
        "comment",
      ],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "uf_rg",
        "rg_orgao_expedidor",
        "postal_code",
        "address1",
        "address2",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "recebeu_info",
        "horario_act_para_criancas_e_adolescentes",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": ["id_arquivo"],
      Pagamento: [
        "forma_pagamento_act_para_criancas_e_adolescentes_completo",
        "associado",
        "vencimento",
        "comment",
      ],
    },
  },

  "Formação em Conceituação de caso na FAP": {
    bolsa: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "uf_rg",
        "rg_orgao_expedidor",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "idade",
        "etnia",
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
        "renda_per_capita",
      ],
      "Outras informações importantes para nós": [
        "experiencia_act",
        "motivo_act",
        "horario_ffc",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
      ],
      Pagamento: ["forma_pagamento_ffc", "associado", "vencimento", "comment"],
    },
    normal: {
      "Dados pessoais": [
        "email",
        "name",
        "marital_status",
        "occupation",
        "cpf",
        "rg",
        "data_nascimento",
        "uf_rg",
        "rg_orgao_expedidor",
        "nacionalidade",
        "naturalidade",
        "cell_phone",
        "postal_code",
        "address1",
        "address2",
        "n_address",
        "district",
        "city",
        "state",
      ],
      "Perfil socioeconômico": [
        "graduado",
        "curso_graduacao",
        "tempo_graduacao",
      ],
      "Outras informações importantes para nós": [
        "motivo_act",
        "horario_ffc",
        "recebeu_info",
        "utm",
        "utm_others",
        "cupom",
      ],
      "Seus anexos": [
        "cpf_arquivo",
        "id_arquivo",
        "certidao_arquivo",
        "endereco_arquivo",
      ],

      Pagamento: ["forma_pagamento_ffc", "associado", "vencimento", "comment"],
    },
  },
};

const Apply = (props) => {
  const params = new URLSearchParams(props.location.search);
  const consultor = params.get("consultor");
  const tipo = params.get("tipo");
  var curso = params.get("curso");
  const bolsa = params.get("bolsa");
  const semestre = params.get("semestre");
  const windowSize = useWindowWidth();
  const [coupon, setCoupon] = useState("");
  // eslint-disable-next-line no-unused-vars
  const { data: coupons, error, loading, refetch } = useQuery(couponQuery, {
    variables: { code: coupon },
  });
  var perguntas = null;
  if (!curso.includes("Especialização") && !curso.includes("Formação")) {
    let prefix;
    if (tipo === "formacao") {
      prefix = "Formação ";
    } else if (tipo === "especializacao") {
      prefix = "Especialização ";
    } else if (tipo === "modulo-1") {
      prefix = "Módulo 1 ";
    } else if (tipo === "completo") {
      prefix = "Curso Completo ";
    } else if (tipo === "basico") {
      prefix = "Básico - ACT ";
    } else if (tipo === "avancado") {
      prefix = "Completo - ACT ";
    }
    curso = prefix + curso;
  }
  console.log(forms);
  console.log(curso);
  console.log(forms[curso]);
  if (forms[curso]) {
    if (bolsa) perguntas = forms[curso].bolsa;
    else perguntas = forms[curso].normal;
  }
  console.log(perguntas);
  console.log(forms);
  console.log(curso);
  if (tipo === "auto") perguntas = forms.auto;
  if (!consultor || !perguntas || !semestre)
    return <h2>Erro: Faltam parâmetros no link.</h2>;
  return (
    <>
      <Formik
        onSubmit={async (values, actions) => {
          let email = values.personByPerson.data.email;
          email = email.trim();
          values.personByPerson.data.email = email;
          if (bolsa) values.bolsa = true;
          var missingFiles = false;
          if (perguntas["Seus anexos"]) {
            perguntas["Seus anexos"].forEach((k) => {
              if (questions[k].required && !values[k]) missingFiles = true;
            });
          }
          if (missingFiles) {
            alert("Faltam arquivos para enviar");
          } else {
            try {
              var { data } = await axios.post(
                "/.netlify/functions/apply",
                values
              );
              if (data.errors) {
                let msg = data.errors[0]?.extensions?.internal
                  ? "Error: " +
                    data.errors[0]?.extensions?.internal.error.message
                  : data.errors[0].message;
                alert(msg);
              } else {
                if (data.data)
                  document.getElementById("paper").innerHTML =
                    "Formulário enviado com sucesso. Código: " +
                    data.data.insert_application_one.id;
              }
            } catch (e) {
              alert(JSON.stringify(e.response.data));
            }
          }
          actions.setSubmitting(false);
        }}
        initialValues={{ consultor, tipo, course: curso, semestre }}
        render={({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Paper elevation={2} style={{ padding: 30 }} id="paper">
              <h1>
                Formulário de inscrição : {curso} {semestre}:
              </h1>
              <h2>
                {bolsa ? "Bolsa - " : ""}
                {!curso.includes("RFT")
                  ? curso.split(" ")[0]
                  : "Teoria das Molduras Relacionais"}
              </h2>
              {Object.keys(perguntas).map((section) => {
                return (
                  <>
                    <div className="section">
                      <h2 className="section-title">{section}</h2>
                      {perguntas[section].map((k) => {
                        return (
                          <div>
                            <p>
                              {questions[k].fieldtype !== "file" ? (
                                <div>
                                  <Field
                                    type={questions[k].fieldtype}
                                    label={
                                      windowSize > 1100 ? questions[k].text : ""
                                    }
                                    helperText={
                                      windowSize <= 1100
                                        ? questions[k].text
                                        : ""
                                    }
                                    name={
                                      section === "Dados pessoais"
                                        ? "personByPerson.data." + k
                                        : k
                                    }
                                    fullWidth={true}
                                    component={TextField}
                                    multiline={true}
                                    InputLabelProps={
                                      questions[k].inputLabelProps
                                    }
                                    inputProps={questions[k].inputProps}
                                    required={questions[k].required}
                                    onInput={
                                      questions[k].onInput
                                        ? (e) =>
                                            questions[k].onInput(
                                              e,
                                              setFieldValue
                                            )
                                        : ""
                                    }
                                    variant="outlined"
                                    select={questions[k].select}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    validate={questions[k].validate}
                                  >
                                    {questions[k].options &&
                                      questions[k].options.map((o) => {
                                        return (
                                          <option value={o} className="p5">
                                            {o}
                                          </option>
                                        );
                                      })}
                                  </Field>
                                  {k === "cupom" && (
                                    <>
                                      {coupons &&
                                      coupons.coupon &&
                                      coupons.coupon.length > 0 ? (
                                        <p className="p5 font-green">
                                          Desconto aplicado.
                                        </p>
                                      ) : values[k] ? (
                                        <p className="p5 font-red">
                                          Cupom não verificado
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: "10px" }}
                                        onClick={() => {
                                          setCoupon(values[k].toUpperCase());
                                          refetch();
                                        }}
                                      >
                                        Verificar cupom
                                      </Button>
                                    </>
                                  )}
                                  {windowSize <= 1100 && <hr />}
                                </div>
                              ) : (
                                <Upload
                                  setFieldValue={setFieldValue}
                                  prefix={
                                    values.personByPerson
                                      ? values.personByPerson.data.name
                                      : ""
                                  }
                                  folder={"INSCRIÇÃO"}
                                  turma={curso}
                                  label={questions[k].text}
                                  field={k}
                                />
                              )}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Enviar
              </Button>
            </Paper>
          </Form>
        )}
      />
    </>
  );
};
export default Apply;

export const questions = {
  email: {
    text: "Endereço de email",
    fieldtype: "email",
    required: true,
  },
  name: {
    text: "Nome completo",
    required: true,
  },
  marital_status: {
    text: "Estado civil",
    required: true,
  },
  occupation: {
    text: "Profissão (como vai constar no contrato)",
    required: true,
  },
  cpf: {
    text: "CPF",
    required: true,
    onInput: (e) => {
      var num = e.target.value.match(/[0-9]+/g);
      if (num) {
        num = num.join("").toString();
        if (num.length <= 3) {
          e.target.value = num;
        } else if (num.length <= 6) {
          e.target.value = num.slice(0, 3) + "." + num.slice(3);
        } else if (num.length <= 9) {
          e.target.value =
            num.slice(0, 3) + "." + num.slice(3, 6) + "." + num.slice(6);
        } else {
          e.target.value =
            num.slice(0, 3) +
            "." +
            num.slice(3, 6) +
            "." +
            num.slice(6, 9) +
            "-" +
            num.slice(9, 11);
        }
      }
    },
    validate: (value) => {
      var cpf = value;
      if (!cpf) cpf = "0";
      cpf = cpf.match(/[0-9]/g);
      if (!cpf) return "CPF inválido";
      cpf = cpf.join("").padStart(11, "0");
      var soma = 0;
      if (cpf.replace(new RegExp(cpf[0], "g"), "") == "") {
        //verifica se todos os dígitos são iguais
        return "CPF inválido";
      }
      //primeiro dígito verificador:
      for (var char = 0; char < 9; char++) {
        soma += cpf[char] * (10 - char);
      }
      if (((10 * soma) % 11) % 10 != cpf[9]) {
        return "CPF inválido";
      } //segundo dígito verificador:
      soma = 0;
      // eslint-disable-next-line no-redeclare
      for (var char = 0; char < 10; char++) {
        soma += cpf[char] * (11 - char);
      }
      if (((10 * soma) % 11) % 10 != cpf[10]) {
        return "CPF inválido";
      }
    },
  },
  rg: {
    text: "RG",
    required: true,
  },
  uf_rg: {
    text: "UF do RG",
    required: true,
  },
  rg_orgao_expedidor: {
    text: "Órgão expedidor do RG",
    required: true,
  },
  data_nascimento: {
    text: "Data de nascimento (dd/mm/aaaa)",
    required: true,
  },
  nacionalidade: {
    text: "Nacionalidade",
    required: true,
  },
  naturalidade: {
    text: "Naturalidade",
    required: true,
  },
  cell_phone: {
    text: "Telefone",
    required: true,
  },
  address1: {
    text: "Endereço (Avenida, Rua)",
    required: true,
    inputLabelProps: { shrink: true },
  },
  address2: {
    text: "Complemento (apto, bloco, etc)",
    required: true,
    inputLabelProps: { shrink: true },
  },
  n_address: {
    text: "Endereço (número)",
    required: true,
    inputLabelProps: { shrink: true },
  },
  district: {
    text: "Bairro",
    required: true,
    inputLabelProps: { shrink: true },
  },
  city: {
    text: "Cidade",
    required: true,
    inputLabelProps: { shrink: true },
  },
  state: {
    text: "UF",
    required: true,
    inputLabelProps: { shrink: true },
  },
  postal_code: {
    text: "CEP",
    required: true,
    onInput: async (e, setFieldValue) => {
      var cep = e.target.value.match(/[0-9]/g);
      if (cep) {
        cep = cep.join("");
        if (cep.length > 5) {
          e.target.value = cep.substring(0, 5) + "-" + cep.substring(5);
        }
        if (cep.length === 8) {
          try {
            var { data } = await axios.get(
              "https://ws.apicep.com/cep.json?code=" + cep
            );
            setFieldValue("personByPerson.data.address1", data.address);
            setFieldValue("personByPerson.data.district", data.district);
            setFieldValue("personByPerson.data.city", data.city);
            setFieldValue("personByPerson.data.state", data.state);
          } catch (e) {
            console.log(e.response.data);
          }
        }
      }
    },
  },
  idade: {
    //change to birth date?
    text: "Qual sua idade?",
    required: true,
    fieldtype: "number",
  },
  etnia: {
    text: "Qual sua cor ou raça?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Amarela", "Branca", "Indígena", "Parda", "Preta"],
  },
  graduado: {
    text: "Você tem diploma de graduação?",
    select: true,
    options: ["", "Sim", "Não"],
    required: true,
    inputLabelProps: { shrink: true },
  },
  curso_graduacao: {
    text: "Se sim, qual curso de graduação?",
  },
  tempo_graduacao: {
    text: "Quanto tempo de conclusão da sua graduação?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "1 ano",
      "2 anos",
      "3 anos",
      "Mais de 5 anos",
      "Não se aplica",
    ],
  },
  saude_publica: {
    text: "Você é profissional da rede pública de saúde?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
  },
  funcao_saude: {
    text:
      "Se você é profissional da rede pública de saúde, qual é a sua função?",
  },
  professor: {
    text: "Você é professor(a) do ensino Fundamental ou Médio?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "Sim, sou professor(a) do Ensino Fundamental",
      "Sim, sou professor(a) do Ensino Médio",
      "Sim, as duas opções acima",
      "Não",
    ],
  },
  renda_pessoal: {
    text: "Qual é a sua renda pessoal mensal?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "Até R$ 2.500,00",
      "Entre R$ 2.500,01 e R$ 4.000,00",
      "Mais que R$ 4.000,00",
    ],
  },
  familiares: {
    text:
      "Quantas pessoas moram com você na mesma casa? (incluindo filhos, irmãos, parentes e amigos)",
    required: true,
    fieldtype: "number",
  },
  renda_per_capita: {
    text:
      "Somando a sua renda com a renda de todas pessoas que moram com você e dividindo pelo número de pessoas da sua casa, quanto é, aproximadamente, a renda familiar mensal per capita?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "Até R$ 2.500,00 mensal.",
      "Entre R$ 2.500,01 e R$ 4.000,00 mensal.",
      "Mais de R$ 4.000,00 mensal.",
    ],
  },
  experiencia_mf: {
    text: "Você já teve alguma experiência com mindfulness antes?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
  },
  motivo_mf: {
    text: "Por que você quer fazer o curso de Mindfulness Funcional?",
    required: true,
  },
  historia_mf: {
    text:
      "Conte algo da sua história de vida ou experiência que acha importante sabermos para conceder uma bolsa parcial de estudos para o curso de Mindfulness Funcional para você.",
  },
  aplicar_conhecimentos_mf: {
    text:
      "Como você pretende aplicar os conhecimentos adquiridos na Pós-graduação em Mindfulness Funcional?",
    required: true,
  },
  fechar_uma_semana: {
    text:
      "Caso o seu pedido de bolsa seja aprovado, você fecharia a inscrição dentro do prazo de uma semana?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não", "Talvez"],
  },
  horario_mf: {
    text:
      "As aulas começarão no dia 01/09. Qual é o horário de sua preferência para participar das AULAS PRÁTICAS (ao vivo)?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "QUARTAS-FEIRAS das 20 às 22 horas",
      "QUINTAS-FEIRAS das 8 às 10 horas",
    ],
  },
  recebeu_info: {
    text:
      "Você recebeu todas as informações sobre o curso (datas e horários das aulas, modo de funcionamento)?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não", "Talvez"],
  },
  utm: {
    text: "Como você ficou sabendo do curso?",
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "E-mail",
      "Facebook",
      "Instagram",
      "Website",
      "Indicação",
      "Outros (especifique abaixo)",
    ],
  },
  utm_others: {
    text:
      "Se você marcou outros acima, por favor, especifique como você ficou sabendo",
  },
  forma_pagamento_esp: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "1 parcela (à vista)",
      "Matrícula + 24 parcelas mensais (sob consulta ao Serasa)",
      "Matrícula + 17 parcelas mensais",
      "3 parcelas (semestrais)",
    ],
  },
  forma_pagamento_pre_inscricao_fap: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "3 parcelas (mensais)",
      "Matrícula + 17 parcelas mensais",
      "Matrícula + 24 parcelas mensais (sob consulta ao Serasa)",
    ],
  },
  forma_pagamento_RFT_modulo1: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "À vista", "6 parcelas mensais", "9 parcelas mensais"],
  },
  forma_pagamento_RFT_completo: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "À vista", "12 parcelas mensais", "18 parcelas mensais"],
  },
  forma_pagamento_act_para_criancas_e_adolescentes_completo: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "À vista",
      "Matrícula + 11 parcelas mensais",
      "Matrícula + 17 parcelas mensais",
    ],
  },
  forma_pagamento_act_para_criancas_e_adolescentes_basico: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "À vista",
      "Matrícula + 5 parcelas mensais",
      "Matrícula + 8 parcelas mensais",
    ],
  },
  forma_pagamento_ffc: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "1 parcela (à vista)",
      "Matrícula + 8 parcelas mensais",
      "Matrícula + 5 parcelas mensais",
      "3 parcelas (semestrais)",
    ],
  },
  vencimento: {
    text:
      "Por favor, marque o dia do vencimento que você prefere para o pagamento das parcelas mensais?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "1", "10", "17", "25"],
  },
  comment: {
    text: "Comentários ou algo mais que você gostaria de nos falar?",
  },
  cpf_arquivo: {
    text:
      "CPF (favor anexar aqui a cópia de seu CPF, preferencialmente em PDF)*",
    required: true,
    fieldtype: "file",
    inputLabelProps: { shrink: true },
  },
  id_arquivo: {
    text:
      "Documento de Identidade (favor anexar aqui a cópia de seu RG, somente RG, frente e verso, preferencialmente em PDF)*",
    required: true,
    fieldtype: "file",
    inputLabelProps: { shrink: true },
  },
  certidao_arquivo: {
    text:
      "Certidão de Nascimento ou Casamento (favor anexar aqui todas as páginas da cópia de sua certidão, preferencialmente em PDF)",
    fieldtype: "file",
    inputLabelProps: { shrink: true },
  },
  endereco_arquivo: {
    text:
      "Comprovante de Residência (favor anexar aqui a cópia de seu comprovante de residência, preferencialmente em PDF)*",
    required: true,
    fieldtype: "file",
    inputLabelProps: { shrink: true },
  },
  diploma_frente: {
    text:
      "Diploma de Graduação - frente (favor anexar aqui a cópia de seu Diploma, preferencialmente em PDF)",
    fieldtype: "file",
    inputLabelProps: { shrink: true },
  },
  diploma_verso: {
    text:
      "Diploma de Graduação - verso (favor anexar aqui a cópia de seu Diploma, preferencialmente em PDF)",
    fieldtype: "file",
    inputLabelProps: { shrink: true },
  },
  historico_arquivo: {
    text:
      "Histórico escolar (favor anexar aqui a cópia de seu Histórico, preferencialmente em PDF)",
    fieldtype: "file",
    inputLabelProps: { shrink: true },
  },
  experiencia_act: {
    text: "Você tem contato com as Terapias Contextuais?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
  },
  motivo_act: {
    text: "Por que você quer fazer o curso?",
    required: true,
  },
  horario_act: {
    text:
      "As aulas começarão no dia 20/08. Qual é o horário de sua preferência para participar das AULAS PRÁTICAS (ao vivo)? (As opções de horários estão condicionadas a demanda de alunos por turno.)",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Terça-feira de 20h ás 22h", "Quarta-feira de 8h ás 10h"],
  },
  horario_rft: {
    text:
      "As aulas práticas são sempre as quartas-feiras, das 08h às 10h. Você concorda com esse horário?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
  },
  horario_ffc: {
    text:
    "As aulas começarão no dia 20/08. Qual é o horário de sua preferência para participar das AULAS PRÁTICAS (ao vivo)?",
  required: true,
  select: true,
  inputLabelProps: { shrink: true },
  options: [
    "",
    "Terça-feira (20 às 22h)",
    "Quarta-feira, (08 às 10h)",
  ],
  },

  horario_act_para_criancas_e_adolescentes: {
    text:
      "As aulas práticas são sempre as segundas-feiras, das 08h às 10h. Você concorda com esse horário?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
  },

  associado: {
    text: "Você é associado da ABPMC, ACBS-Brasil ou FBTC?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
  },
  cartao_boleto: {
    text: "Especifique a forma de pagamento",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Cartão de Crédito", "Boleto/PIX"],
  },
  forma_pagamento_for: {
    text: "Qual seria a melhor forma de pagamento da inscrição para você?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "1 parcela (à vista)",
      "Matrícula + 24 parcelas mensais (sob consulta ao Serasa)",
      "Matrícula + 17 parcelas mensais",
      "3 parcelas (semestrais)",
    ],
  },
  termo_carga_mf_esp: {
    text:
      "A carga horária total do curso é de 360 horas com duração de três semestres, de agosto de 2021 a dezembro de 2022.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  termo_frequencia: {
    text:
      "Uma vez por semana, devo cumprir 75% de frequência nas Aulas Práticas on-line (ao vivo) com duração de 2 horas para obter aprovação no curso.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  termo_aula_online: {
    text:
      "Preciso participar das Aulas Práticas Intensivas on-line no 1º e 2º semestres com carga horária de 10 horas em cada semestre (já incluída na carga horária total do curso). No 1° semestre, ocorrerá nos dias 20 e 21 de novembro de 2021, das 10 às 12:30h e das 15 às 17:30.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  termo_atividades_extraclasse: {
    text:
      "Para melhorar a aprendizagem, preciso realizar as atividades extraclasse, referentes à cada lição no Painel do Aluno, além do horário das Aulas Práticas.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  termo_executar_atividades: {
    text:
      "Posso executar as atividades extraclasse, de acordo com a minha disponibilidade de tempo ao longo de cada semestre do curso.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  termo_70_porcento: {
    text:
      "Para obter aprovação no curso, devo atingir no mínimo 70% de acertos nas avaliações de múltipla escolha (on-line) por cada disciplina. Além de realizar os projetos práticos para avaliação qualitativa de meu desempenho.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  termo_70_porcento_n2: {
    text:
      "Para obter aprovação no curso, devo atingir no mínimo 70% de acertos nas avaliações de múltipla escolha (on-line) por cada disciplina.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  termo_carga_mf_for: {
    text:
      "A carga horária total do curso é de 133 horas com duração de um semestre, de agosto a dezembro de 2021.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  comentario_mf: {
    text:
      "Agora que você está ciente da dinâmica do curso, ainda existe algo que gostaria de perguntar ou de comentar conosco?",
    inputLabelProps: { shrink: true },
  },
  forma_pagamento_esp_n2: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "2 parcelas (semestrais)",
      "Matrícula + 11 parcelas mensais",
      "Matrícula + 17 parcelas mensais (sob consulta ao Serasa)",
    ],
  },
  forma_pagamento_esp_n3: {
    text: "Qual seria a forma de pagamento da inscrição?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "À vista",
      "Matrícula + 5 parcelas mensais",
      "Matrícula + 11 parcelas mensais (sob consulta ao Serasa)",
      "Cartão de crédito - 12 parcelas mensais",
    ],
  },
  termo_aula_online_n2: {
    text:
      "Preciso participar das Aulas Práticas Intensivas on-line com carga horária de 10 horas (já incluída na carga horária total do curso) nos dias 28 e 29/05/22 das 10 às 12h30 e das 15 às 17h30.",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  horario_mf_n2: {
    text:
      "As aulas começarão no dia 23/03. Qual é o horário de sua preferência para participar das AULAS PRÁTICAS (ao vivo)?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "QUARTAS-FEIRAS das 20 às 22 horas",
      "QUINTAS-FEIRAS das 8 às 10 horas",
    ],
  },
  horario_mf_n3: {
    text:
      "As aulas começarão no dia 24/08. Qual é o horário de sua preferência para participar das AULAS PRÁTICAS (ao vivo)?",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "QUARTAS-FEIRAS das 20 às 22 horas",
      "QUINTAS-FEIRAS das 8 às 10 horas",
    ],
  },
  termo_carga_mf_for_n2: {
    text:
      "A carga horária total do curso é de 60 horas/aula. As aulas serão de agosto a novembro/2022",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Sim", "Não"],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  horario_fap: {
    text:
      "As aulas começarão no dia 20/08. Qual é o horário de sua preferência para participar das AULAS PRÁTICAS (ao vivo)? (As opções de horários estão condicionadas a demanda de alunos por turno.)",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: ["", "Terça de 20h ás 22h", "Quarta de 8h ás 10h"],
  },
  forma_pagamento: {
    text: "Qual seria a forma de parcelamento",
    required: true,
    select: true,
    inputLabelProps: { shrink: true },
    options: [
      "",
      "PIX",
      "1x (cartão)",
      //"2x (cartão)",
      //"3x (cartão)",
      //"4x (cartão)",
      //"5x (cartão)",
      //"6x (cartão)"
    ],
    validate: (value) => {
      if (value === "") return "Resposta Obrigatória";
    },
  },
  cupom: {
    text: "Código de desconto:",
  },
};
