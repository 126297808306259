import React, { useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery, useMutation } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { textLabels } from "./dataTablesLocalization";
import { StoreContext } from "../store";
import {
  Grid,
  NativeSelect,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import functions from "./GQL";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

const DBUI = observer((props) => {
  const [state, setState] = useState({
    name: "turma",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  // eslint-disable-next-line no-unused-vars
  const store = useContext(StoreContext);
  const [update] = useMutation(functions[state.name].INSERT);
  var { data, error, loading, refetch } = useQuery(functions[state.name].GET, {
    fetchPolicy: "network-only",
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (DBUI.js): {error.message}</p>;
  data = data[state.name];
  var dataView = JSON.parse(JSON.stringify(data)); //clone data to preserve original object
  if (functions[state.name].flatten)
    dataView = functions[state.name].flatten(dataView);
  dataView.forEach((d) => delete d["__typename"]);
  return (
    <>
      <div>
        <FormControl className="formControl">
          <InputLabel htmlFor="table">Tabela</InputLabel>
          <NativeSelect
            value={state.name}
            onChange={handleChange}
            inputProps={{
              name: "name",
              id: "table",
            }}
          >
            <option value={"turma"}>Turmas</option>
            <option value={"person"}>Alunos</option>
            <option value={"lesson"}>Lições</option>
            <option value={"activity"}>Atividades</option>
            <option value={"enrollment"}>Matrículas</option>
            <option value={"quiz"}>Quiz</option>
            <option value={"quiz_relation"}>Quiz_Turma</option>
            <option value={"question"}>Perguntas(Quiz)</option>
            <option value={"live_schedule"}>Dias da aula ao vivo</option>
            <option value={"disciplina"}>Disciplinas</option>
          </NativeSelect>
        </FormControl>
      </div>
      <Grid container>
        <div
          style={{
            display: "table",
            tableLayout: "fixed",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <MUIDataTable
            title={state.name}
            data={dataView}
            columns={Object.keys(dataView[0])}
            options={{
              responsive: "scroll",
              rowsPerPageOptions: [10, 50, 100],
              selectableRows: false,
              filterType: "multiselect",
              onCellClick: (_, cmeta) => {
                //const cname = dataView[cmeta.colIndex].name;
                const record = dataView[cmeta.dataIndex];
                props.history.push(`/editDB/${state.name}/${record.id}`);
              },
              customToolbar: () => {
                return (
                  <Tooltip title={"adicionar"}>
                    <IconButton
                      onClick={() =>
                        props.history.push("/editDB/" + state.name)
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                );
              },
              textLabels,
              // eslint-disable-next-line no-dupe-keys
              selectableRows: "multiple",
              customToolbarSelect: (selectedRows) => (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                      let values = JSON.parse(JSON.stringify(data));
                      let objects = selectedRows.data.map((row) => {
                        let object = values[row.dataIndex];
                        return formatData(object);
                      });
                      await update({ variables: { objects } });
                      refetch();
                    }}
                  >
                    Clonar
                  </Button>
                </>
              ),
            }}
          />
        </div>
        {state.name === "live_attendance" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.history.push("/presenca")}
          >
            Adicionar presenças
          </Button>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
});

export default DBUI;

const formatData = (values) => {
  delete values.id;
  if (values.__typename === "lesson") values.number = 100;
  if (values.__typename === "activity") values.available = false;
  delete values.__typename;
  Object.keys(values).forEach((key) => {
    if (Array.isArray(values[key])) {
      values[key].forEach((value) => {
        formatData(value); //recursion
      });
      values[key] = { data: values[key] };
    } else if (typeof values[key] === "object") {
      delete values[key];
    } else {
      if (values[key] === "") {
        // insert nulls because of unique fields
        delete values[key];
      }
    }
  });
  return values;
};
