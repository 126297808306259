import React, { useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import "../css/Grades.css";

const query = gql`
  query getAttempts($email: String, $turma: Int) {
    turma(where: { id: { _eq: $turma }, deleted_at: { _is_null: true } }) {
      id
      quiz_relations(
        where: { quizByQuiz: { _not: { disciplina_id: { _eq: 158 } } } }
      ) {
        quizByQuiz {
          id
          name
          questions {
            id
            pontos
          }
          quiz_attempts(
            where: {
              personByPerson: {
                email: { _eq: $email }
                enrollments: {
                  registers: {
                    turma: { _eq: $turma }
                    deleted_at: { _is_null: true }
                  }
                  deleted_at: { _is_null: true }
                }
                deleted_at: { _is_null: true }
              }
              deleted_at: { _is_null: true }
            }
          ) {
            id
            quiz
            created_at
            personByPerson {
              id
              first_name
              last_name
            }
            question_completions {
              id
              answer
              question_option {
                id
                correct
                questionByQuestion {
                  pontos
                  null_question
                }
              }
            }
          }
        }
      }
      registers {
        is_professor
        is_tutor
        is_team_member
        is_monitor
        is_audit
        is_evaluator
        suspended
        cancelled
        locked
        dependency
        enrollmentByEnrollment {
          person {
            first_name
            last_name
            email
            monitorandos {
              student
            }
          }
        }
      }
      lessons(
        where: {
          title: { _ilike: "Projeto%" }
          deleted_at: { _is_null: true }
          number: { _gte: 0.1 }
        }
      ) {
        id
        title
        start_date
        activities(
          where: { available: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          id
          type
          activity_completions(
            where: {
              personByPerson: {
                email: { _eq: $email }
                deleted_at: { _is_null: true }
              }
              completed: { _eq: true }
              deleted_at: { _is_null: true }
            }
          ) {
            id
            file
            personByPerson {
              id
              first_name
              last_name
              live_attendances_aggregate(
                where: {
                  lessonByLesson: {
                    turma: { _eq: $turma }
                    deleted_at: { _is_null: true }
                  }
                  deleted_at: { _is_null: true }
                }
              ) {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }
      live_schedules {
        day
      }
    }
    disciplina(
      where: {
        lessons: { turma: { _eq: $turma } }
        deleted_at: { _is_null: true }
      }
      order_by: { id: asc }
    ) {
      id
      name
      lessons(
        where: {
          turma: { _eq: $turma }
          deleted_at: { _is_null: true }
          number: { _gte: 0.1 }
        }
        order_by: { number: asc }
      ) {
        id
        number
        title
        start_date
        activities(
          where: { available: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          id
          type
          activity_completions(
            where: {
              personByPerson: {
                email: { _eq: $email }
                deleted_at: { _is_null: true }
              }
              completed: { _eq: true }
            }
          ) {
            id
            personByPerson {
              id
              first_name
              last_name
            }
          }
        }
        live_attendances {
          date
          personByPerson {
            first_name
            last_name
          }
        }
        liveAttendancesByReferenceLesson {
          date
          personByPerson {
            first_name
            last_name
          }
          reference_lesson
        }
      }
      courseworks(
        where: {
          turma: { _eq: $turma }
          personByPerson: { email: { _eq: $email } }
          deleted_at: { _is_null: true }
        }
      ) {
        grade
        title
        personByPerson {
          id
          first_name
          last_name
        }
      }
    }
    retake2 {
      person
      quiz
      grade
    }
  }
`;

const GradesAntigo = observer((props) => {
  // null id will make this an Insert component
  const store = useContext(StoreContext);
  var variables = { turma: store.ui.turma.id };
  const course = store.ui.turma.course;
  if (!(store.admin || store.is_monitor)) variables.email = props.email;
  var { data, error, loading } = useQuery(query, { variables: variables });
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {JSON.stringify(error)}</p>;
  if (data.turma.length === 0) return <p>Turma não encontrada.</p>;
  // Filter disciplines in place to exclude 0-lesson ones (like 'Informativos')
  data.disciplina = data.disciplina.filter((d) => {
    return d.lessons.length > 0;
  });
  var grades = [];
  var quizids = [];
  var quiz_values = {};
  var titles = {};
  var live_detailed = {};
  var live_days = data.turma[0].live_schedules;
  if (store.admin || store.is_monitor) {
    data.turma[0].registers.forEach((register) => {
      let name =
        register.enrollmentByEnrollment.person.first_name +
        " " +
        register.enrollmentByEnrollment.person.last_name;
      // following was suggestd by ChatGPT
      let title = [
        register.suspended && "SUSPENSO",
        register.cancelled && "CANCELADO",
        register.locked && "Matrícula trancada",
        register.dependency && "fazendo dependência",
        register.is_tutor && "Tutor",
        register.is_monitor && "Monitor",
        register.is_professor && "Professor",
        register.is_team_member && "Equipe",
        register.is_audit && "Ouvinte",
        register.is_evaluator && "Avaliador",
      ].filter(Boolean);
      titles[name] = title.length > 0 ? ` (${title.join(", ")})` : "";
    });
  }
  //generate data for the grades table:
  if (store.admin) {
    if (data.turma[0].quiz_relations) {
      data.turma[0].quiz_relations.forEach((relation) => {
        var quiz = relation.quizByQuiz;
        var id = quiz.name;
        var total = 0;
        quiz.questions.forEach((question) => {
          if (question.pontos) total += question.pontos;
        });
        total = Math.round(total * 100) / 100;
        quiz_values[id] = total;
        quizids.push({
          name: id,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              var color = value && value.grade >= 0.7 * total ? "black" : "red";
              return (
                <>
                  <div style={{ color: color, fontWeight: "bold" }}>
                    {value ? `${value.grade} (${value.attempts}/3)` : ""}
                  </div>
                </>
              );
            },
            onCellClick: (_, cmeta) => {
              props.history.push("/quiz/" + quiz.id);
            },
          },
        });
        var retake2 = data.retake2.filter((r) => r.quiz === quiz.id);
        if (retake2.length > 0) {
          quizids.push({
            name: "2ª Recuperação",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                var color =
                  value && value.grade >= 0.7 * total ? "black" : "red";
                return (
                  <>
                    <div style={{ color: color, fontWeight: "bold" }}>
                      {value ? `${value.grade}` : ""}
                    </div>
                  </>
                );
              },
              onCellClick: (_, cmeta) => {
                props.history.push("/rerecuperacao");
              },
            },
          });
          quiz_values["2ª Recuperação"] = 10;
        }
        quiz.quiz_attempts.forEach((at) => {
          var nota = 0;
          var aluno =
            at.personByPerson.first_name + " " + at.personByPerson.last_name;
          at.question_completions.forEach((answer) => {
            //calcula nota
            if (
              answer.question_option.correct ||
              answer.question_option.questionByQuestion.null_question
            ) {
              var valor = answer.question_option.questionByQuestion.pontos;
              if (valor) nota += valor;
            }
          });
          nota = Math.round(nota * 100) / 100;
          var pos = grades.length;
          grades.forEach((linha, i) => {
            //verifica se já há registro do aluno na tabela
            if (linha.nome === aluno) {
              pos = i;
              if (linha[id] && linha[id].grade > nota) nota = linha[id].grade; //mantém a maior nota
            }
          });
          if (pos === grades.length) grades[pos] = { nome: aluno }; //adiciona nova linha
          if (!grades[pos][id]) grades[pos][id] = { grade: nota, attempts: 0 };
          grades[pos][id].grade = nota;
          grades[pos][id].attempts++;
          let grade_retake_2 = null;
          retake2.forEach((r) => {
            //add 2nd retake grade to the 1st
            if (r.person === at.personByPerson.id) grade_retake_2 = r.grade;
          });
          if (grade_retake_2)
            grades[pos]["2ª Recuperação"] = {
              grade: nota + grade_retake_2,
              attempts: 0,
            };
        });
      });
    }
    //courseworks:
    data.disciplina.forEach((discipline) => {
      discipline.courseworks[0] &&
        quizids.push({
          name: discipline.name,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              var color = value && value.grade >= 7 ? "black" : "red";
              return (
                <>
                  <div style={{ color: color, fontWeight: "bold" }}>
                    {value ? `${value.grade}` : ""}
                  </div>
                </>
              );
            },
          },
        });
      discipline.courseworks.forEach((coursework) => {
        var aluno =
          coursework.personByPerson.first_name +
          " " +
          coursework.personByPerson.last_name;
        var pos = grades.findIndex((linha) => linha.nome === aluno);
        if (pos < 0)
          grades.push({
            nome: aluno,
            [discipline.name]: {
              grade: coursework.grade,
            },
          });
        //adiciona nova linha
        else grades[pos][discipline.name] = { grade: coursework.grade };
      });
      if (discipline.courseworks[0]) quiz_values[discipline.name] = 10;
    });
    grades.forEach((student) => {
      let passed = true;
      let retake = false;
      quizids.forEach((quiz) => {
        if (!quiz.name.toUpperCase().includes("RECUPERAÇÃO")) {
          passed &&=
            student[quiz.name] &&
            student[quiz.name].grade >= quiz_values[quiz.name] * 0.7;
        } else {
          retake |=
            student[quiz.name] &&
            student[quiz.name].grade >= quiz_values[quiz.name] * 0.7;
        }
      });
      passed = passed || retake;
      student.Resultado = passed ? "Aprovado" : "Reprovado";
    });
    quizids = [
      {
        name: "nome",
        options: {
          customBodyRender: (value, tableMeta, updateValue) =>
            value + (titles[value] || ""),
        },
      },
    ].concat(quizids);
    store.admin &&
      quizids.push({
        name: "Resultado",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            var color = value === "Reprovado" ? "red" : "black";
            return (
              <>
                <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
              </>
            );
          },
        },
      });
  } else {
    //generate grades table for student
    quizids = [
      "Questionário",
      {
        name: "Nota",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            var color =
              parseInt(value.split("(")[1].split("%")[0]) >= 70
                ? "black"
                : "red";
            return (
              <>
                <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
              </>
            );
          },
        },
      },
      "Tentativas usadas",
      {
        name: "quizId",
        label: "Refazer",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (tableMeta.rowData[0] === "2ª Recuperação" || !value) return "";
            return (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.history.push("/quiz/" + value);
                  }}
                >
                  Fazer Prova
                </Button>
              </>
            );
          },
        },
      },
    ];
    if (config[course] && config[course].quizanalysis)
      quizids.push({
        name: "attempt",
        label: "Correção",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (!value) return "";
            return (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.history.push("/quizanalysis", { attempt: value });
                  }}
                >
                  Ver Correção
                </Button>
              </>
            );
          },
        },
      });
    if (data.turma[0].quiz_relations) {
      data.turma[0].quiz_relations.forEach((relation) => {
        var quiz = relation.quizByQuiz;
        var id = quiz.name;
        var total = 0;
        var retake2 = data.retake2.filter(
          (r) => r.quiz === quiz.id && r.person === store.personId
        );
        quiz.questions.forEach((question) => {
          if (question.pontos) total += question.pontos;
        });
        total = Math.round(total * 100) / 100;
        var linha = { Questionário: id };
        let tentativas = 0;
        quiz.quiz_attempts.forEach((at) => {
          // check only current person
          if (at.personByPerson.id !== store.personId) return;
          tentativas++;
          var nota = 0;
          at.question_completions.forEach((answer) => {
            //calcula nota
            if (
              answer.question_option.correct ||
              answer.question_option.questionByQuestion.null_question
            ) {
              var valor = answer.question_option.questionByQuestion.pontos;
              if (valor) nota += valor;
            }
          });
          nota = Math.round(nota * 100) / 100;
          if (!linha.Nota || linha.Nota < nota) linha.Nota = nota;
        });
        if (!linha.Nota) {
          linha.Nota = 0;
        }
        var nota = linha.Nota;
        linha.Nota +=
          "/" + total + " (" + Math.round((nota * 100) / total) + "%)";
        //linha["Tentativas usadas"] = quiz.quiz_attempts.length + "/3";
        linha["Tentativas usadas"] = tentativas + "/3";
        linha.quizId = quiz.id;
        let lastAttempt = null;
        if (quiz.quiz_attempts[0]) {
          const sortedAttempts = quiz.quiz_attempts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          lastAttempt = sortedAttempts[0];
        }
        linha.attempt = lastAttempt;
        // grades.push(linha);
        // only push grades if tentativas > 0
        if (tentativas > 0) {
          grades.push(linha);
        }
        if (retake2.length > 0) {
          let finalGrade = nota + retake2[0].grade;
          grades.push({
            Questionário: "2ª Recuperação",
            Nota: `${finalGrade}/10 (${Math.round(finalGrade * 10)}%)`,
          });
        }
      });
    }
    //courseworks:
    data.disciplina.forEach((discipline) => {
      discipline.courseworks[0] &&
        grades.push({
          Questionário: discipline.name,
          Nota: `${discipline.courseworks[0].grade} (${Math.round(
            discipline.courseworks[0].grade * 10
          )}%)`,
        });
    });
  }
  //generate data for the attendances table:
  var attendances = [];
  var disciplines = [];
  var total_activities = {};
  if (
    course.includes("ACT") ||
    course.includes("FAP") ||
    course.includes("RFT")
  ) {
    disciplines.push("Aula ao vivo");
    total_activities["Aula ao vivo"] = 0;
  }
  if (config[course] && config[course].classes)
    config[course].classes.forEach((c) => {
      disciplines.push(c);
      total_activities[c] = 0;
    });
  var ignore =
    config[course] && config[course].ignore ? config[course].ignore : [];
  data.disciplina.forEach((disciplina) => {
    disciplines.push(disciplina.name);
    total_activities[disciplina.name] = 0;
    console.log(disciplina.lessons);
    disciplina.lessons.forEach((lesson) => {
      if (lesson.number < 0.1) return;
      console.log(lesson);
      if (lesson.number >= 0.1 && lesson.number < 99) {
        total_activities["Aula ao vivo"]++;
      }
      lesson.activities.forEach((activity) => {
        if (!ignore.includes(activity.type)) {
          let category =
            config[course] &&
            config[course].classes &&
            config[course].classes.includes(activity.type)
              ? activity.type
              : disciplina.name;
          total_activities[category]++;
          activity.activity_completions.forEach((ac) => {
            var aluno =
              ac.personByPerson.first_name + " " + ac.personByPerson.last_name;
            let pos = attendances.length;
            attendances.forEach((linha, i) => {
              //verifica se já há registro do aluno na tabela
              if (linha.nome === aluno) {
                pos = i;
              }
            });
            if (pos === attendances.length) attendances[pos] = { nome: aluno }; //adiciona nova linha
            if (attendances[pos][category]) {
              attendances[pos][category]++;
            } else {
              attendances[pos][category] = 1;
            }
          });
        }
      });
      if (
        store.ui.turma.course.includes("ACT") ||
        store.ui.turma.course.includes("FAP") ||
        store.ui.turma.course.includes("RFT")
      ) {
        lesson.live_attendances
          .concat(lesson.liveAttendancesByReferenceLesson)
          .forEach((at) => {
            let aluno =
              at.personByPerson.first_name + " " + at.personByPerson.last_name;
            let pos = attendances.length;
            attendances.forEach((linha, i) => {
              //verifica se já há registro do aluno na tabela
              if (linha.nome === aluno) {
                pos = i;
              }
            });
            if (pos === attendances.length) attendances[pos] = { nome: aluno }; //adiciona nova linha
            if (!attendances[pos]["Aula ao vivo"])
              attendances[pos]["Aula ao vivo"] = 0;
            attendances[pos]["Aula ao vivo"]++;
            if (!live_detailed[aluno]) live_detailed[aluno] = [];
            let dates = [];
            if (at.reference_lesson && at.date) {
              // feita em recuperação
              let at_date = at.date.split("-").reverse();
              at_date.pop();
              dates.push(at_date.join("/"));
            } else {
              if (lesson.title?.toUpperCase().includes("WORKSHOP")) {
                let ldate = new Date(lesson.start_date + "T12:00:00.000Z")
                  .toISOString()
                  .split("T")[0]
                  .split("-")
                  .reverse();
                ldate.pop();
                dates.push(ldate.join("/"));
              } else {
                live_days.forEach(({ day }) => {
                  let start = new Date(lesson.start_date + "T12:00:00.000Z");
                  let weekday = [
                    "sun",
                    "mon",
                    "tue",
                    "wed",
                    "thu",
                    "fri",
                    "sat",
                  ].indexOf(day.toLowerCase());
                  start.setDate(7 + weekday - start.getDay() + start.getDate());
                  start = start
                    .toISOString()
                    .split("T")[0]
                    .split("-");
                  dates.push(`${start[2]}/${start[1]}`);
                });
              }
            }
            if (dates.length === 0) dates = [at.date];
            dates = dates.join(", ");
            live_detailed[aluno].push(`Lição ${lesson.number} (${dates})`);
            live_detailed[aluno].sort((a, b) => {
              a = parseFloat(a.split("Lição ")[1].split(" ")[0]);
              b = parseFloat(b.split("Lição ")[1].split(" ")[0]);
              if (a > b) return 1;
              else if (a < b) return -1;
              return 0;
            });
          });
      }
    });
  });
  store.admin &&
    attendances.forEach((student) => {
      let passed = true;
      disciplines.forEach((disc) => {
        passed &&= student[disc] >= total_activities[disc] * 0.75;
      });
      student.Resultado = passed ? "Aprovado" : "Reprovado";
    });
  disciplines.forEach((name, i) => {
    disciplines[i] = {
      name,
      label: `${name} (${total_activities[name]})`,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var color = value < total_activities[name] * 0.75 ? "red" : "black";
          return (
            <>
              <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
            </>
          );
        },
      },
    };
    if (name === "Aula ao vivo") {
      disciplines[i].options = {
        customBodyRender: (value, tableMeta, updateValue) => {
          var color = value < total_activities[name] * 0.75 ? "red" : "black";
          return (
            <div
              className="tooltip"
              style={{ color: color, fontWeight: "bold" }}
            >
              {value}
              <span className="tooltiptext" id={`span${tableMeta.rowIndex}`}>
                Clique para ver as datas
              </span>
            </div>
          );
        },
      };
    }
  });
  disciplines = [
    {
      name: "nome",
      label: "nome",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value + (titles[value] || ""),
      },
    },
  ].concat(disciplines);
  store.admin &&
    disciplines.push({
      name: "Resultado",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var color = value === "Reprovado" ? "red" : "black";
          return (
            <>
              <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
            </>
          );
        },
      },
    });

  //transpose data for single person:
  if (!(store.admin || store.is_monitor)) {
    let nome = "";
    if (attendances.length > 0) {
      nome = attendances[0].nome;
      delete attendances[0].nome;
      attendances = Object.keys(attendances[0]).map((act) => {
        return {
          atividade: `${act} (${total_activities[act]})`,
          frequência: attendances[0][act],
        };
      });
    }
    disciplines = [
      {
        name: "atividade",
        label: "Disciplina (total)",
      },
      {
        name: "frequência",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div className="tooltip">
              {value <
              0.75 * total_activities[tableMeta.rowData[0].split(" (")[0]] ? (
                <b className="font-red">{value}</b>
              ) : (
                value
              )}
              {tableMeta.rowData[0].includes("Aula ao vivo") && (
                <span className="tooltiptext" id={`span${tableMeta.rowIndex}`}>
                  {live_detailed[nome] &&
                    live_detailed[nome].map((text) => (
                      <>
                        {text}
                        <br />
                      </>
                    ))}
                </span>
              )}
            </div>
          ),
        },
      },
    ];
  }
  var tableOptions = {
    //for the tables
    responsive: "scroll",
    rowsPerPageOptions: [10, 50, 200],
    rowsPerPage: 200,
    selectableRows: false,
    filterType: "multiselect",
    sortOrder: {
      name: "nome",
      direction: "asc",
    },
  };

  //Final table (resultado):
  if (store.admin) {
    //MF projects:
    var projects = [];
    data.turma[0].lessons.forEach((lesson) => {
      if (lesson.title.toUpperCase().includes("PROJETO")) {
        projects.push(lesson.title);
        total_activities[lesson.title] = 0;
        lesson.activities.forEach((activity) => {
          total_activities[lesson.title]++;
          activity.activity_completions.forEach((ac) => {
            var aluno =
              ac.personByPerson.first_name + " " + ac.personByPerson.last_name;
            var pos = attendances.length;
            attendances.forEach((linha, i) => {
              //verifica se já há registro do aluno na tabela
              if (linha.nome === aluno) {
                pos = i;
              }
            });
            if (pos === attendances.length) attendances[pos] = { nome: aluno }; //adiciona nova linha
            if (ac.file) {
              if (attendances[pos][lesson.title]) {
                attendances[pos][lesson.title]++;
              } else {
                attendances[pos][lesson.title] = 1;
              }
            }
          });
        });
      }
    });
    //approval table:
    var completions_columns = [];
    if (data.turma[0].quiz_relations.length > 0)
      completions_columns.push("Notas");
    if (data.disciplina.length > 0) completions_columns.push("Frequência");
    if (projects.length > 0) completions_columns.push("Projetos");
    var completions = data.turma[0].registers.map((register) => {
      let name =
        register.enrollmentByEnrollment.person.first_name +
        " " +
        register.enrollmentByEnrollment.person.last_name;
      let grade = "Reprovado";
      grades.forEach((student) => {
        if (student.nome === name) grade = student["Resultado"];
      });
      let att = "Reprovado";
      let project_result = "Reprovado";
      attendances.forEach((student) => {
        if (student.nome === name) {
          att = student["Resultado"];
          projects.forEach((project) => {
            if (student[project] === total_activities[project])
              project_result = "Aprovado";
          });
        }
      });
      return {
        nome: name + titles[name],
        Notas: grade,
        Frequência: att,
        Projetos: project_result,
      };
    });
    completions_columns = ["nome"].concat(
      completions_columns.map((name) => ({
        name,
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            var color = value === "Reprovado" ? "red" : "black";
            return (
              <>
                <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
              </>
            );
          },
        },
      }))
    );
  }

  return (
    <>
      <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
        <MUIDataTable
          data={grades}
          columns={quizids}
          title={"Notas"}
          options={{
            ...tableOptions,
          }}
        />
        <br />
        {store.admin &&
        (store.ui.turma.course.includes("ACT") ||
          store.ui.turma.course.includes("FAP") ||
          store.ui.turma.course.includes("RFT")) ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.history.push("/presenca")}
          >
            Marcar Presenças
          </Button>
        ) : (
          ""
        )}
        <MUIDataTable
          data={attendances}
          columns={disciplines}
          title={"Frequências"}
          options={{
            ...tableOptions,
            onCellClick: (_, cmeta) => {
              if (disciplines[cmeta.colIndex].name === "Aula ao vivo") {
                let text = live_detailed[attendances[cmeta.dataIndex].nome];
                if (!text) text = "";
                else text = text.join("<br />");
                let span = document.getElementById(`span${cmeta.rowIndex}`);
                if (span) span.innerHTML = text;
              }
            },
          }}
        />
        <br />
        {store.admin && (
          <MUIDataTable
            data={completions}
            columns={completions_columns}
            title={"Resultado"}
            options={tableOptions}
          />
        )}
      </div>
    </>
  );
});

export default GradesAntigo;

const config = {
  "Especialização FAP": {
    ignore: [
      "Aula 2 - parte 1",
      "Aula 2 - parte 2",
      "Não obrigatória",
      "Extra",
    ],
    quizanalysis: true,
  },
  "Formação FAP": {
    ignore: ["Aula 2 - parte 1", "Aula 2 - parte 2"],
    quizanalysis: true,
  },
  "Especialização ACT": {
    classes: ["Produzir Conteúdo"],
    quizanalysis: true,
  },
  "Formação ACT": {
    classes: ["Produzir Conteúdo"],
    quizanalysis: true,
  },
  "Curso Completo RFT": {
    quizanalysis: true,
  },
  "Módulo 1 RFT": {
    quizanalysis: true,
  },
};
