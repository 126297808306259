import React, { useContext, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../css/GradesAdmin.css"

const GET_GRADES = gql`
  query MyQuery($turma: Int) {
    disciplina_grades(where: {turma: {id: {_eq: $turma}}, disciplina_id: {_neq: 158}}) {
      id
      is_final
      grade
      attempts
      person {
        id
        first_name
        last_name
        email
      }
      disciplina {
        name
        id
      }
    }
    register(where: { turma: { _eq: $turma } }) {
      approved
      cancelled
      is_tutor
      is_professor
      is_team_member
      is_audit
      is_monitor
      locked
      suspended
      expired
      dependency
      enrollmentByEnrollment {
        person {
          id
        }
      }
    }
  }
`;

function transformData(data) {
  if (!data || !data.disciplina_grades) {
    return [];
  }

  const transformedData = data.disciplina_grades.map((item) => {
    const registerInfo = data.register
      .filter(
        (registerItem) =>
          registerItem.enrollmentByEnrollment.person.id === item.person.id
      )
      .map((register) => formatRegisterInfo(register))
      .filter(Boolean)
      .join(", ");
    return {
      attempts: item.attempts,
      disciplina: {
        id: item.disciplina.id,
        name: item.disciplina.name,
      },
      grade: item.grade,
      id: item.id,
      is_final: item.is_final,
      person: {
        email: item.person.email,
        first_name: item.person.first_name,
        id: item.person.id,
        last_name: item.person.last_name,
        registerInfo: registerInfo || null,
      },
    };
  });

  return transformedData;
}

function formatRegisterInfo(register) {
  const mapping = {
    cancelled: "Matrícula Cancelada",
    is_tutor: "Tutor",
    is_professor: "Professor",
    is_team_member: "Membro da Equipe",
    is_audit: "Ouvinte",
    is_monitor: "Monitor",
    locked: "Matrícula trancada",
    suspended: "Suspenso",
    expired: "Expirado",
    dependency: "Fazendo dependência",
  };

  const labels = [];
  for (let [key, value] of Object.entries(register)) {
    if (value && mapping[key]) {
      labels.push(mapping[key]);
    }
  }
  return labels.length > 0 ? labels.join(", ") : null;
}

const GradesAdmin = () => {
  const store = useContext(StoreContext);
  const variables = { turma: store.ui.turma.id };
  const { data, loading, refetch } = useQuery(GET_GRADES, {
    variables: variables,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateGradeResult = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "/.netlify/functions/update-grade-result",
        {
          turma: store.ui.turma.id,
        }
      );
      console.log("response", response);
      refetch();
    } catch (err) {
      console.error("Error while calling update-grade-result:", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  const allSubjects = new Set();

  const grades = transformData(data);

  grades.forEach((grade) => {
    allSubjects.add(grade.disciplina.name);
  });

  const columns =
    grades.length > 0
      ? grades.reduce(
          (acc, curr) => {
            const index = acc.findIndex(
              ({ name }) => name === curr.disciplina.name
            );
            if (index === -1) {
              acc.push({
                name: curr.disciplina.name,
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    const grade = tableMeta.rowData[tableMeta.columnIndex];
                    const color = grade?.split("(")[0] < 7 ? "red" : "black";
                    const attempts = grades.find(
                      (g) =>
                        g.id === tableMeta.rowData[0].id &&
                        g.disciplina.id === curr.disciplina.id
                    )?.attempts;
                    const attemptStr =
                      attempts !== undefined ? `(${attempts}/${3})` : "";
                    return (
                      <p style={{ color, fontWeight: "bold" }}>
                        {grade}
                        {attemptStr}
                      </p>
                    );
                  },
                },
              });
            }
            return acc;
          },
          [
            { name: "Nome", options: { filter: true } },
            {
              name: "Resultado",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  const pass = value === "Aprovado";
                  const color = pass ? "black" : "red";
                  return (
                    <div style={{ color, fontWeight: "bold" }}>{value}</div>
                  );
                },
              },
            },
          ]
        )
      : [];

  const students = [
    ...new Set(
      grades.map(
        (grade) => `${grade.person.first_name} ${grade.person.last_name}`
      )
    ),
  ];

  const rows = students.map((student) => {
    const studentGrades = grades.filter(
      (grade) =>
        `${grade.person.first_name} ${grade.person.last_name}` === student
    );
    const studentId = studentGrades[0].person.id;
    const studentApproval = data.register.find(
      (registerItem) =>
        registerItem.enrollmentByEnrollment.person.id === studentId
    )?.approved;
    const registerInfo = studentGrades[0].person.registerInfo;
    const displayName = registerInfo ? `${student} (${registerInfo})` : student;
    const row = { Nome: displayName };
    let pass = studentApproval;
    studentGrades.forEach((grade) => {
      let gradeStr;
      if (grade.disciplina.id === 84 && grade.attempts === 1) {
        gradeStr = `${grade.grade.toFixed(2)} (Nota Parcial)`;
      } else if (grade.disciplina.id === 84 && grade.attempts === 2) {
        gradeStr = `${grade.grade.toFixed(2)} (Nota Final)`;
      } else {
        gradeStr = `${grade.grade.toFixed(2)}${
          grade.attempts !== 0 ? ` (${grade.attempts}/${3})` : ""
        }`;
      }
      row[grade.disciplina.name] = gradeStr;
    });
    row["Resultado"] = pass ? "Aprovado" : "Reprovado";
    return row;
  });

  const resultIndex = columns.findIndex((col) => col.name === "Resultado");
  if (resultIndex > -1) {
    columns.push(columns.splice(resultIndex, 1)[0]);
  }

  const options = {
    responsive: "scroll",
    rowsPerPageOptions: [10, 50, 200],
    rowsPerPage: 200,
    selectableRows: false,
    filterType: "multiselect",
    sortOrder: {
      name: "Nome",
      direction: "asc",
    },
  };

  const theme = createTheme({
    typography: {
      useNextVariants: true,
      h6: {
        color: "#801819",
        fontWeight: "bold",
      },
    },
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: "400px",
        },
      },
    },
  });

  return (
    <div className="conteiner">
      <div className="containerButtons">
        <Link
          to="/grades-student"
          className="linkAdmins"
        >
          Visualizar como aluno
        </Link>
        <Button
          onClick={updateGradeResult}
          disabled={isLoading}
          variant="contained"
          color="primary"
          style={{ color: "white", marginBottom: 20 }}
        >
          {isLoading ? "carregando..." : "Atualizar Resultados"}
        </Button>
        {error && <p>Houve um erro na atualização: {error.message}</p>}
      </div>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title="Notas dos alunos"
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default GradesAdmin;
