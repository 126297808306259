import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import axios from "axios";
import "../../css/Apply.css";
import { StoreContext } from "../../store";
import { useQuery } from "react-apollo-hooks";
import PixPage from "./PixPage";
import CheckoutForm from "./CheckoutForm";
import "./checkout.css";

const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("auth0:id_token"),
  },
};

const Standard = (props) => {
  useEffect(() => {
    let tag = document.createElement("script");
    tag.src = "https://js.iugu.com/v2";
    tag.addEventListener("load", () => setLoaded(true));
    document.body.appendChild(tag);
    tag = document.createElement("script");
    tag.src =
      "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
    document.body.appendChild(tag);
  }, []);
  var { course, semestre, items, turma, forms, prices } = props.location.state;
  if (!items)
    items = [
      {
        price_cents: 100,
        description: "teste",
        quantity: 1,
      },
    ];
  const [loaded, setLoaded] = useState(false);
  const [paymentForm, setPaymentForm] = useState({ open: false, data: null });
  const [pix, setPix] = useState("");
  const store = useContext(StoreContext);
  const query = store.personId
    ? gql`
        query MyQuery($id: Int!) {
          person_by_pk(id: $id) {
            email
            first_name
            last_name
            cpf
          }
        }
      `
    : gql`
        query public {
          log(limit: 1) {
            id
          }
        }
      `;
  const variables = store.personId ? { id: store.personId } : {};
  const { data, error, loading } = useQuery(query, { variables });
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro (Checkout.jsx): {error.message}</p>;
  var person = data.person_by_pk;
  if (paymentForm.open)
    return (
      <Payment
        loaded={loaded}
        data={paymentForm.data}
        items={items}
        course={course}
      />
    );
  if (pix) return <PixPage id={pix} />;
  var init = { course, semestre, tipo: "auto" };
  if (person) {
    delete forms["Dados pessoais"];
    delete forms["Outras informações"];
    person.name = person.first_name + " " + person.last_name;
    init.person = store.personId; //conferir se application aceita person
  }
  return (
    <>
      <Formik
        onSubmit={async (values, actions) => {
          console.log(values);
          if (!loaded) {
            alert("Erro no carregamento do sistema de pagamento");
          } else {
            if (!person) person = values.personByPerson.data;
            let email = person.email;
            while (email[email.length - 1] === " ") {
              email = email.substring(0, email.length - 1);
            }
            person.email = email;
            try {
              var { data } = await axios.post(
                "/.netlify/functions/apply",
                values
              );
              if (data.errors) {
                alert(data.errors[0].message);
              } else {
                if (data.email)
                  alert(
                    `CPF já cadastrado com email diferente. O email ${data.email} será usado pelo sistema.`
                  );
                person.application = data.data.insert_application_one.id;
                let parcelas = 1;
                if (values.forma_pagamento === "PIX") {
                  let res = await createInvoice(course, { ...person, items });
                  if (res === "ok") setPix(person.application);
                } else {
                  parcelas = values.forma_pagamento.match(/[0-9]/)[0];
                  items[0].price_cents = prices[values.forma_pagamento] * 100;
                  setPaymentForm({
                    open: true,
                    data: { ...person, parcelas, turma },
                  });
                }
              }
            } catch (e) {
              // eslint-disable-next-line no-ex-assign
              if (e.response) e = e.response.data;
              alert(JSON.stringify(e));
            }
          }
          actions.setSubmitting(false);
        }}
        initialValues={init}
        render={({ values, setFieldValue, isSubmitting, errors }) => (
          <CheckoutForm
            course={course}
            semestre={semestre}
            forms={forms}
            isSubmitting={isSubmitting}
            items={items}
            person={person}
            setFieldValue={setFieldValue}
            prices={prices}
          />
        )}
      />
    </>
  );
};
export default Standard;

const Payment = ({ loaded, data, items, course }) => {
  let person = data;
  let acc_id =
    course.includes("ACT") || course.includes("FAP") || course.inclues("RFT")
      ? "9ADFEFF587D705A004E8FD85375DE6D1"
      : "53E4A1233A3646279808E954F645ABD9";
  useEffect(() => {
    if (loaded) {
      let Iugu = window.Iugu;
      Iugu.setAccountID(acc_id);
      let form = document.getElementById("payment-form");
      form.addEventListener("submit", async function(e) {
        e.preventDefault();
        Iugu.createPaymentToken(form, tokenResponseHandler);
        return false;
      });
      var tokenResponseHandler = async function(data) {
        if (data.errors) {
          alert("Erro salvando cartão: " + JSON.stringify(data.errors));
        } else {
          let body = {
            email: person.email,
            name: person.name,
            token: data.id,
            items,
            parcelas: parseInt(person.parcelas),
            application: person.application,
            turma: person.turma,
          };
          let address = person.address1 + " " + person.address2;
          if (address) {
            body.address_street = address.replace(/,* *[0-9]+.*/, "");
            let num = address.match(/[0-9]+/);
            if (num && num[0]) num = num[0];
            body.number = num;
          }
          if (person.postal_code)
            body.zip_code = person.postal_code.match(/[0-9]+/g).join("");
          if (person.cpf) body.cpf_cnpj = person.cpf;
          if (person.district) body.district = person.district;
          try {
            let { data } = await axios.post(
              "/.netlify/functions/createinvoice_iugu-background?action=charge&turma=" +
                course,
              body,
              config
            );
            console.log(data);
            if (data && data.errors && Object.keys(data.errors).length !== 0) alert(data.errors);
            else {
              document.getElementById("cc_page").innerHTML = "Concluído.";
            }
          } catch (e) {
            // eslint-disable-next-line no-ex-assign
            if (e.response) e = e.response.data;
            alert(e);
          }
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!loaded) return <p>Erro: Plugin de pagamento não carregou.</p>;
  return (
    <Paper style={{ alignItems: "center" }} id="cc_page" className="p30">
      <h2>
        Pagamento - {course} (R${items[0].price_cents / 100} em {data.parcelas}x
        no cartão):
      </h2>
      <form id="payment-form">
        <div className="usable-creditcard-form">
          <div className="wrapper">
            <div className="input-group nmb_a">
              <div className="icon ccic-brand"></div>
              <input
                className="credit_card_number"
                data-iugu="number"
                placeholder="Número do Cartão"
                type="text"
              />
            </div>
            <div className="input-group nmb_b">
              <div className="icon ccic-cvv"></div>
              <input
                className="credit_card_cvv"
                data-iugu="verification_value"
                placeholder="CVV"
                type="text"
              />
            </div>
            <div className="input-group nmb_c">
              <div className="icon ccic-name"></div>
              <input
                className="credit_card_name"
                data-iugu="full_name"
                placeholder="Titular do Cartão"
                type="text"
              />
            </div>
            <div className="input-group nmb_d">
              <div className="icon ccic-exp"></div>
              <input
                className="credit_card_expiration"
                data-iugu="expiration"
                placeholder="MM/AA"
                type="text"
              />
            </div>
          </div>
          <div className="footer">
            <img
              src="https://s3-sa-east-1.amazonaws.com/storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/cc-icons.e8f4c6b4db3cc0869fa93ad535acbfe7.png"
              alt="Visa, Master, Diners. Amex"
              border="0"
            />
          </div>
        </div>
        <div>
          <button type="submit">Enviar</button>
        </div>
      </form>
    </Paper>
  );
};

const createInvoice = async (course, values) => {
  let today = new Date();
  today.setDate(today.getDate() + 3);
  today = today.toISOString().split("T")[0];
  values.due_date = today;
  values.payable_with = ["pix"];
  values.cpf_cnpj = values.cpf;
  try {
    await axios.post(
      "/.netlify/functions/createinvoice_iugu-background?action=pix&turma=" +
        course,
      values,
      config
    );
    return "ok";
  } catch (e) {
    alert(e.response.data);
  }
};
