import React, { Fragment, useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../store";
import { List, ListItem, Link } from "@material-ui/core";
import {
  getPersonId,
  activitiesCounts,
  Completions,
} from "../queriesAndMutations";
import { useQuery } from "react-apollo-hooks";
import WelcomeMessage from "./WelcomeMessage";
import LoginPasswordless from "./LoginPasswordless";
import { logout } from "./LoginPasswordless";
import {
  pendingProduzirConteudo,
  pendingProduzirAtividade1,
  pendingProduzirAtividade2,
} from "./GQL/Painel_gql";

const UserExists = observer(() => {
  const store = useContext(StoreContext);
  const { data, error, loading } = useQuery(getPersonId, {
    variables: { email: store.email },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) {
    logout();
    return (
      <p>
        Recarregue a página para logar novamente (código 1): {error.message}
      </p>
    );
  }

  const personId = data.person[0] ? data.person[0].id : null;
  if (!personId) store.not_in_db = true;
  if (personId) store.personId = personId;
  return (
    <Fragment>
      {!personId && !store.admin && (
        <p>Usuário com este email ({store.email}) não existe no sistema.</p>
      )}
      {personId && data.person[0].first_name && <WelcomeMessage />}
    </Fragment>
  );
});

const Painel = observer(() => {
  const store = useContext(StoreContext);
  const [debug, setDebug] = useState(false);
  const mode = process.env.NODE_ENV === "development" ? "local" : "prod";
  return (
    <div>
      {!store.logged_in && (
        <div>
          <LoginPasswordless />
          <p />
        </div>
      )}
      {store.logged_in && <UserExists />}
      {(store.is_monitor || store.admin) && <ListPendingComments />}
      {(store.is_monitor || store.admin) &&
        store.ui.turma.course.includes("FAP") && <ListPendingDPPs />}
      {(store.is_monitor || store.admin) &&
        store.ui.turma.course.includes("FAP") && <ListPendingAtividade2 />}
      {(store.is_monitor || store.admin) && <AttentionStudents />}
      <button className="bnt-debug" onClick={() => setDebug(!debug)}>
        {debug ? "Esconder " : "Mostrar "}Debug Info
      </button>
        {debug && (
          <Fragment>
            <p>Node.env: {process.env.NODE_ENV}</p>
            <p>Mode: {mode}</p>
            <p>Version: {store.version}</p>
            <p>Email: {store.email || "Faça o login"}</p>
            <p>Turmas: {JSON.stringify(store.turmas)}</p>
            <p>Selected Turma: {JSON.stringify(store.ui.turma)}</p>
            <p>Person ID: {store.personId || "Sem personId"}</p>
            <p>Role: {store.default_role || "No role"}</p>
            <p>Expired: {store.expired.toString()}</p>
            <p>Logged in: {store.logged_in.toString()}</p>
            <p>Authorized: {store.authorized.toString()}</p>
            <p>Admin?: {store.admin.toString()}</p>
            <p>Monitor?: {store.is_monitor.toString()}</p>
            <p>Evaluator?: {store.is_evaluator.toString()}</p>
            <p>Consultant?: {store.is_consultant.toString()}</p>
            <p>
              Expires at:{" "}
              {store.auth_expires
                ? new Date(store.auth_expires).toString() //store.auth_expires.toString()
                : "no expiration for JWT token recorded"}
            </p>
          </Fragment>
        )}
      </div>
    );
});

interface ActivitiesCountsInterface {
  person: [PersonInterface];
}

interface PersonInterface {
  first_name: string;
  last_name: string;
  activity_completions: [Completions];
  activity_completions_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

const AttentionStudents = () => {
  const store = useContext(StoreContext);
  const { data, error, loading } = useQuery<ActivitiesCountsInterface>(
    activitiesCounts
  );
  if (loading) return <p>Carregando... </p>;
  if (error)
    return <p>Houve um erro de sistema (código 2): {error.message} </p>;
  const noActs = data!.person.filter(
    (p) => p.activity_completions_aggregate.aggregate.count === 0
  );
  const students = noActs.map((p, i: number) => (
    <ListItem key={i}>{`${i + 1}: ${p.first_name} ${p.last_name}`} </ListItem>
  ));
  return (
    <div>
      {students.length > 0 && !store.admin && (
        <Fragment>
          <h3>
            {" "}
            Alunos seus que ainda não completaram nenhuma atividade (
            {students.length}):
          </h3>
          <List>{students}</List>
          <p />
        </Fragment>
      )}
      {students.length === 0 && (
        <h3> Todos seus alunos já completaram alguma atividade. </h3>
      )}
    </div>
  );
};

const ListPendingComments = () =>
  ListPendingAtividades(pendingProduzirConteudo, "Produzir Conteúdo");

const ListPendingDPPs = () =>
  ListPendingAtividades(pendingProduzirAtividade1, "DPPs");

const ListPendingAtividade2 = () =>
  ListPendingAtividades(pendingProduzirAtividade2, "Atividade Prática 2");

const ListPendingAtividades = (gql: any, nome: string) => {
  const store = useContext(StoreContext);
  var { data, loading, error } = useQuery<ActivitiesCountsInterface>(gql, {
    variables: {
      turma: store.ui.turma.id,
      monitor: store.admin ? null : store.personId,
    },
  });
  if (loading) return <p> Carregando... </p>;
  if (error)
    return <p> Houve um erro de sistema (código 2): {error.message} </p>;
  var list: String[] = data!.person.map((person: PersonInterface) => {
    var name = person.first_name + " " + person.last_name;
    var lessons = person.activity_completions.map(
      (ac) => ac.activityByActivity.lessonByLesson.number
    );
    return `${name}: ${lessons.length > 1 ? "Lições" : "Lição"} ${lessons.join(
      ", "
    )}`;
  });
  return (
    <>
      {list.length > 0 && (
        <>
          <h3>
            {" "}
            {`Alunos que fizeram ${nome} e estão sem feedback`} ({list.length}):
          </h3>
          {list.map((a, i: number) => (
            <ListItem key={i}>
              {`${i + 1}:`} <Link href="/alunos"> {a} </Link>
            </ListItem>
          ))}
        </>
      )}
    </>
  );
};

export default Painel;
