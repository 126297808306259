import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { Modal, Backdrop, Fade, TextField, Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

const LESSON_FEEDBACK_QUERY = gql`
  query lessonfeedback($personid: Int, $lessonid: Int, $turmaid: Int) {
    lesson_feedback(
      where: {
        person: { _eq: $personid }
        lesson: { _eq: $lessonid }
        turma: { _eq: $turmaid }
      }
    ) {
      id
      rating
      status_view
      comment
    }
  }
`;

const SUBMIT_FEEDBACK_MUTATION = gql`
  mutation submitFeedback(
    $personid: Int!
    $lessonid: Int!
    $turmaid: Int!
    $rating: Int!
    $comment: String
  ) {
    insert_lesson_feedback(
      objects: {
        person: $personid
        lesson: $lessonid
        turma: $turmaid
        rating: $rating
        comment: $comment
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const DISMISS_FEEDBACK_MUTATION = gql`
  mutation DismissFeedback($id: uuid!) {
    update_lesson_feedback(
      where: { id: { _eq: $id } }
      _set: { status_view: true }
    ) {
      affected_rows
    }
  }
`;

const INSERT_FEEDBACK_MUTATION = gql`
  mutation InsertFeedback($personid: Int!, $lessonid: Int!, $turmaid: Int!) {
    insert_lesson_feedback(
      objects: {
        person: $personid
        lesson: $lessonid
        turma: $turmaid
        status_view: true
      }
    ) {
      affected_rows
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  });
interface LessonFeedbackProps {
  lessonId: number;
  personId: number | undefined;
  turmaId: number;
  classes: Record<string, string>;
}
interface LessonFeedbackQueryData {
  lesson_feedback: {
    id: string;
    rating: number;
    status_view: boolean;
    comment: string;
  }[];
}

const LessonFeedback: React.FC<LessonFeedbackProps> = ({
  lessonId,
  personId,
  turmaId,
  classes,
}) => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [showReminder, setShowReminder] = useState(true);
  const [submitFeedbackMutation] = useMutation(SUBMIT_FEEDBACK_MUTATION);
  const [dismissFeedbackMutation] = useMutation(DISMISS_FEEDBACK_MUTATION);
  const [insertFeedbackMutation] = useMutation(INSERT_FEEDBACK_MUTATION);

  const { data, loading } = useQuery<LessonFeedbackQueryData>(
    LESSON_FEEDBACK_QUERY,
    {
      variables: { personid: personId, lessonid: lessonId, turmaid: turmaId },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!loading) {
      if (data && data.lesson_feedback.length > 0) {
        const feedback = data.lesson_feedback[data.lesson_feedback.length - 1];
        setRating(feedback.rating);
        setComment(feedback.comment);
        setShowReminder(!feedback.status_view);
      }
    } else {
      setRating(null);
      setComment("");
      setShowReminder(true);
    }
  }, [data, loading, lessonId]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitFeedback = async () => {
    try {
      await submitFeedbackMutation({
        variables: {
          personid: personId,
          lessonid: lessonId,
          turmaid: turmaId,
          rating: rating,
          comment: comment,
        },
      });
      handleClose();
    } catch (error) {
      console.error("Erro ao enviar feedback:", error);
    }
  };

  const handleDismissFeedback = async () => {
    try {
      const existingFeedback = data && data.lesson_feedback.length > 0
        ? data.lesson_feedback[0]
        : null;
  
      if (existingFeedback) {
        await dismissFeedbackMutation({
          variables: {
            id: existingFeedback.id,
          },
        });
      } else {
        await insertFeedbackMutation({
          variables: {
            personid: personId,
            lessonid: lessonId,
            turmaid: turmaId,
          },
        });
      }
      setShowReminder(false);
      setOpen(false);
    } catch (error) {
      console.error("Erro ao atualizar ou inserir feedback:", error);
    }
  };
  if (loading) {
    return null;
  }

  return (
    <div>
      {showReminder && (
        <>
          <span style={{ fontSize: 20 }}>O que você achou desta lição?</span>
          <br />
          <Rating
            size="large"
            name="lesson-rating"
            value={rating || 0}
            onChange={(event, newValue) => {
              setRating(newValue);
              handleOpen();
            }}
          />
        </>
      )}
      <Modal
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Rating
              name="lesson-rating"
              size="large"
              value={rating || 0}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              readOnly={!open}
            />
            <TextField
              fullWidth
              label="Comentário"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={submitFeedback}
            >
              Enviar
            </Button>
            <br />
            <Button
              variant="text"
              style={{ color: "red" }}
              onClick={handleDismissFeedback}
            >
              Não quero ver isso novamente
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default withStyles(styles)(LessonFeedback);
