import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment registerFields on register {
    enrollment
    turma
    is_student
    is_monitor
    is_tutor
    is_professor
    is_team_member
    is_audit
    is_evaluator
    suspended
    cancelled
    locked
    expired
    dependency
    turmaByTurma{
      id
      name
    }
  }
`;

export const GET = gql`
query getRegister($id: Int, $limit: Int) {
    register(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
      id
      ...registerFields
    }
    turma{
      id
      name
    }
}
${FieldsFragment}
`;

export const INSERT = gql`
    mutation newRegister($objects: [register_insert_input!]!) {
        insert_register(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updateRegister($_set: register_set_input={}, $id: Int){
    update_register(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, _set: $_set) {
      affected_rows
    }
  }
`

export const fieldtypes = { //mudar tipos dos campos
  cancelled:"checkbox",
  locked:"checkbox",
  suspended: "checkbox",
  enrollment:"number",
  is_monitor: "checkbox",
  is_tutor: "checkbox",
  is_professor: "checkbox",
  is_team_member: "checkbox",
  is_student: "checkbox",
  is_audit:"checkbox",
  is_evaluator:"checkbox",
  expired: "checkbox",
  dependency: "checkbox",
}
export function flatten(data){
  data.forEach(d =>{
    if(d.enrollmentByenrollment) d.email=d.enrollmentByenrollment.email;
    if(d.turmaByTurma) {
      d.nome_turma = d.turmaByTurma.name
      delete d.turmaByTurma
    }
    delete d.enrollmentByenrollment
    });
  return data
}
export const defaults ={
  cancelled : false,
  locked : false,
  is_tutor: false,
  is_professor: false,
  is_team_member: false,
  is_student: true,
  is_audit: false,
  is_monitor: false,
  suspended: false,
  is_evaluator: false,
  expired: false,
  dependency: false,
}

export function lists(data){
  return data
}