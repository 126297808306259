/* eslint-disable no-throw-literal */
/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  getPersonId,
  upsertCompletion,
  completionFieldsFragment,
} from "../queriesAndMutations";
import { useQuery, useMutation } from "react-apollo-hooks";
import { StoreContext } from "../store";
import { Typography, Paper, Divider, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import { Formik, Field, Form } from "formik";
import Button from "@material-ui/core/Button";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import { filter } from "graphql-anywhere";
import SavedSnack from "./SavedSnack";
import ReactMarkdown from "react-markdown";
import { logAction, LogType } from "../log";
import Upload from "./Upload";

const styles = {
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 15,
    marginRight: 15,
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
};

const UpsertControl = observer(
  ({
    classes,
    completion,
    personId,
    activityId,
    activityType,
    activityTypeName,
    upload,
    activityDate,
  }) => {
    const store = useContext(StoreContext);
    var currentDate = new Date().toISOString().split("T")[0];
    if (store.ui.turma.deadline) {
      // eslint-disable-next-line no-unused-vars
      var classDeadline = store.ui.turma.deadline;
    }
    if (activityDate) {
      var activityDeadline = activityDate;
    }
    const [snackOpen, setSnackOpen] = useState(false);
    const [update] = useMutation(upsertCompletion);
    var [msg, setMsg] = useState("Atividade Registrada");
    const [count, setCount] = useState();
    ["comment", "date_done"].forEach((k) => {
      if (completion[k] === null) {
        completion[k] = "";
      }
    });
    completion.person = personId;
    completion.activity = activityId;
    const initVals = filter(completionFieldsFragment, completion);
    return (
      <Fragment>
        <SavedSnack msg={msg} open={snackOpen} setOpen={setSnackOpen} />
        <Formik
          initialValues={initVals}
          onSubmit={async (values, actions) => {
            // copy object to avoid nulls warnings
            const newValues = { ...values };
            // update empty strings as null for UNIQUE fields
            ["comment", "date_done"].forEach((k) => {
              if (newValues[k] === "") {
                newValues[k] = null;
              }
            });
            try {
              if (
                (activityType === "Produzir Conteúdo" ||
                  activityType === "Produzir Conteúdo - workshop" ||
                  (activityTypeName === "Atividade Prática 1" &&
                    store.ui.turma.course.includes("FAP")) ||
                  (activityTypeName === "Atividade Prática 2" &&
                    store.ui.turma.course.includes("FAP")) ||
                  activityType.includes("Projeto")) &&
                values.comment.trim().length < 10 &&
                !values.file
              ) {
                throw "Para registrar, envie/escreva o conteúdo.";
              }

              const variables = { ...newValues };
              if (activityType === "ETC") {
                variables.file_video_etc = values.file_video_etc;
                variables.file_folder_etc = values.file_folder_etc;
                variables.file_summary_etc = values.file_summary_etc;
              }

              await update({ variables });
              setMsg("Atividade Registrada");
            } catch (e) {
              setMsg(JSON.stringify(e));
            }
            actions.setSubmitting(false);
            setSnackOpen(true);
            if (store.ui.refetch) store.ui.refetch();
          }}
          render={({
            errors,
            status,
            touched,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <Form>
              <Grid item xs={12}>
                <Paper className={classes.root} elevation={2}>
                  {completion.monitor_file && (
                    <>
                      <p>
                        <u>
                          <a
                            href={`https://drive.google.com/uc?id=${completion.monitor_file}`}
                            target="_blank"
                          >
                            Arquivo de resposta
                          </a>
                        </u>
                      </p>
                    </>
                  )}
                  {completion.monitor_comment && (
                    <>
                      <p>
                        <u>
                          {"Comentário do monitor: " +
                            completion.monitor_comment}
                        </u>
                      </p>
                    </>
                  )}
                  {(activityType === "ETC" && upload) ? (
                    <>
                    <p>Envie seu vídeo</p>
                      <Upload
                        setFieldValue={setFieldValue}
                        prefix={store.email}
                        oldFile={initVals.file_video_etc}
                        folder={activityType}
                        turma={store.ui.turma.category_name}
                        field="file_video_etc"
                      />
                      <p>Envie seu folder</p>
                      <Upload
                        setFieldValue={setFieldValue}
                        prefix={store.email}
                        oldFile={initVals.file_folder_etc}
                        folder={activityType}
                        turma={store.ui.turma.category_name}
                        field="file_folder_etc"
                      />
                      <p>Envie seu resumo</p>
                      <Upload
                        setFieldValue={setFieldValue}
                        prefix={store.email}
                        oldFile={initVals.file_summary_etc}
                        folder={activityType}
                        turma={store.ui.turma.category_name}
                        field="file_summary_etc"
                      />
                    </>
                  ) : upload && (
                    <Upload
                      setFieldValue={setFieldValue}
                      prefix={store.email}
                      oldFile={initVals.file}
                      folder={activityType}
                      turma={store.ui.turma.category_name}
                      field="file"
                    />
                  )
                  }

                  <Field
                    Label={{ label: "Completei esta atividade" }}
                    name="completed"
                    component={CheckboxWithLabel}
                    defaultChecked={completion.completed}
                  />
                  <p />
                  <Field
                    type="text"
                    label="Observação"
                    fullWidth
                    name="comment"
                    multiline={true}
                    component={TextField}
                    inputProps={
                      !store.ui.turma.max_activity_words &&
                        store.ui.turma.max_activity_characters
                        ? { maxLength: store.ui.turma.max_activity_characters }
                        : ""
                    }
                    onInput={(e) => {
                      var limit = store.ui.turma.max_activity_words;
                      var words = e.target.value.split(" ");
                      setCount({
                        chars: e.target.value.length,
                        words: words.length,
                      });
                      if (limit) {
                        if (words.length > limit) {
                          e.target.value = words.slice(0, limit).join(" ");
                          setCount({
                            chars: e.target.value.length,
                            words: limit,
                          });
                          setMsg("Limite de palavras atingido");
                          setSnackOpen(true);
                        }
                      }
                    }}
                  />
                  {count && (
                    <div style={{ fontSize: "small" }}>
                      palavras: {count.words}, caracteres: {count.chars}
                    </div>
                  )}
                  <p />
                  <Field
                    type="date"
                    label="Data que completou"
                    name="date_done"
                    InputLabelProps={{ shrink: true }}
                    component={TextField}
                  />
                </Paper>
              </Grid>
              <p />
              <Button
                variant="contained"
                color="primary"
                disabled={
                  currentDate > activityDeadline
                    ? true
                    : activityType === "Upload" ||
                      activityType.includes("Projeto") || activityType === "TCC"
                      ? !values.file || isSubmitting
                      : isSubmitting
                }
                type="submit"
              >
                Registrar Atividade
              </Button>
              {currentDate > activityDeadline && (
                <p style={{ color: "red", fontSize: 18, textAlign: "center" }}>
                  {" "}
                  Encerrado o prazo para envio dessa atividade{" "}
                </p>
              )}
            </Form>
          )}
        />
      </Fragment>
    );
  }
);

const ViewActivity = observer(({ classes, location, history }) => {
  const store = useContext(StoreContext);
  const { data, error, loading } = useQuery(getPersonId, {
    variables: { email: store.email },
  });
  if (!location.state) {
    history.push("/listActivities");
    return <p>Erro ao carregar atividade, tente novamente.</p>;
  }
  const { activity, lesson } = location.state;
  const completion = activity.activity_completions[0]
    ? activity.activity_completions[0]
    : { comment: null, completed: false, date_done: null };
  let hasPlayed = false;
  const [update] = useMutation(upsertCompletion);
  useEffect(() => {
    logAction(
      LogType.ActivityView,
      `Lição ${lesson.number}, ${activity.type}:${activity.title}`
    );
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  const personId = data.person[0] ? data.person[0].id : null;
  // eslint-disable-next-line no-unused-vars
  const logPlay = () => {
    if (!hasPlayed) {
      logAction(
        LogType.VideoPlay,
        `Played ${lesson.number}:${activity.type}:${activity.title}`
      );
      hasPlayed = true;
    }
  };
  let upload =
    activity.activity_type.upload ||
    activity.activity_type.group === "Produzir Conteúdo" ||
    activity.activity_type.group === "Projeto" ||
    activity.activity_type.group === "Exercício Prático" ||
    activity.activity_type.group === "Pergunta" ||
    activity.activity_type.group === "TCC" ||
    activity.activity_type.group === "ETC" ||
    activity.activity_type.group === "Produzir Conteúdo - workshop";
  return (
    <Fragment>
      <Paper elevation={2} className={classes.root}>
        <Typography variant="caption">
          Lição {lesson.number} ({lesson.title})
        </Typography>
        <Typography variant="h6">
          {activity.type}: {activity.title}
        </Typography>
        <ReactMarkdown>{activity.description}</ReactMarkdown>
        {activity.video_url && (
          <Fragment>
            <Divider />
            <br />
            <div
              style={{
                ...styles.playerWrapper,
                paddingTop: activity.video_aspect_ratio,
              }}
            >
              <ReactPlayer
                className={classes.reactPlayer}
                url={
                  activity.video_url?.includes(".")
                    ? activity.video_url
                    : `https://player.vimeo.com/video/${activity.video_url.replace(
                      "/",
                      "?h="
                    )}`
                }
                //onPlay={logPlay}
                width="100%"
                height="100%"
                controls={true}
                onEnded={async () => {
                  await update({
                    variables: {
                      person: personId,
                      activity: activity.id,
                      completed: true,
                      date_done: new Date().toISOString().split("T")[0],
                    },
                  });
                }}
              />
            </div>
          </Fragment>
        )}
        {activity.slides_download_link && (
          <Link
            component={Button}
            target="_blank"
            href={activity.slides_download_link}
          >
            Baixar os slides como PDF
          </Link>
        )}
      </Paper>
      <br />
      <UpsertControl
        classes={classes}
        completion={completion}
        personId={personId}
        activityId={activity.id}
        activityType={activity.activity_type.group}
        activityTypeName={activity.activity_type.name}
        upload={upload}
        activityDate={activity.deadline}
      />
    </Fragment>
  );
});

export default withStyles(styles)(ViewActivity);
