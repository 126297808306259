/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { Formik, Field, Form, FieldArray } from "formik";
import {
  Button,
  Dialog,
  IconButton,
  Input,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { observer } from "mobx-react-lite";
import { TextField } from "formik-material-ui";

const styles = {
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    margin: 15,
  },
};

const Materials = observer((props) => {
  const store = useContext(StoreContext);
  var course = store.ui.turma.course;
  if (course === "Formação ACT") course = "Especialização ACT";
  if (course === "Formação FAP") course = "Especialização FAP";
  if (course === "Formação MF") course = "Especialização MF";
  if (course === "Curso Completo RFT")
    course = "Curso Completo RFT";
  if (course === "Módulo 1 RFT")
    course = "Curso Completo RFT";
  const [update] = useMutation(mutation);
  const [remove] = useMutation(removal);
  const [edit, setEdit] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    i: null,
    item: {},
  });
  const openEditor = (item, i) => {
    setDialog({ open: true, item, i });
  };
  var { data, error, loading } = useQuery(query, {
    variables: { course },
  });

  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (MaterialsEdit.jsx): {error.message}</p>;
  var list = JSON.parse(JSON.stringify(data.material));
  var removed = [];
  return (
    <>
      <Paper style={styles.root}>
        {store.admin && (
          <Tooltip title="editar">
            <IconButton
              onClick={() => {
                setEdit(true);
              }}
              style={{
                color: "#66BB6A",
                float: "right",
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        <Formik
          initialValues={{ list }}
          onSubmit={async (values, actions) => {
            let objects = values.list;
            objects.forEach((item, i) => {
              item.order = i;
              delete item.__typename;
              item.course = course;
            });
            try {
              await update({ variables: { objects } });
              removed.forEach(async (item) => {
                if (item.id) await remove({ variables: { id: item.id } });
              });
              alert("Concluído");
              //await refetch();
              setEdit(false);
              window.location.reload();
            } catch (e) {
              alert(e);
            }
            actions.setSubmitting(false);
          }}
          render={({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <FieldArray
                name="list"
                render={(arrayHelpers) => (
                  <div>
                    {values.list.map((item, i) => {
                      let inner = item.link ? (
                        <a href={item.link} target="_blank">
                          {item.text}
                        </a>
                      ) : (
                        item.text
                      );
                      if (item.title) inner = <h2>{inner}</h2>;
                      return (
                        <>
                          <div
                            className="p5"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div>
                              <p>{inner}</p>
                            </div>
                            {edit && (
                              <>
                                <Toolbar
                                  i={i}
                                  arrayHelpers={arrayHelpers}
                                  remove={() => removed.push(item)}
                                  openEditor={() => openEditor(item, i)}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    float: "right",
                                    marginLeft: "20px",
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </>
                      );
                    })}
                    <Dialog
                      open={dialog.open}
                      onClose={() => setDialog({ ...dialog, open: false })}
                    >
                      <div className="p30" style={{ width: "60VH" }}>
                        <Editor
                          i={dialog.i}
                          item={dialog.item}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </Dialog>
                  </div>
                )}
              />
              {edit && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Salvar
                </Button>
              )}
            </Form>
          )}
        />
      </Paper>
    </>
  );
});

const Toolbar = ({ i, arrayHelpers, remove, openEditor }) => (
  <>
    <Tooltip title="editar">
      <IconButton
        onClick={() => {
          openEditor();
        }}
        style={{
          color: "#66BB6A",
        }}
      >
        <EditOutlinedIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="mover para cima">
      <IconButton
        onClick={() => {
          arrayHelpers.move(i, i - 1);
        }}
      >
        <ArrowDropUpIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="remover (necessário salvar)">
      <IconButton
        onClick={() => {
          remove();
          arrayHelpers.remove(i);
        }}
        style={{
          float: "right",
          color: "red",
        }}
      >
        <HighlightOffTwoToneIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="mover para baixo">
      <IconButton
        onClick={() => {
          arrayHelpers.move(i, i + 1);
        }}
      >
        <ArrowDropDownIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="novo">
      <IconButton
        onClick={() => {
          arrayHelpers.insert(i + 1, { text: "", link: "", title: false });
        }}
        style={{
          color: "#66BB6A",
        }}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  </>
);

const Editor = ({ i, item, setFieldValue }) => (
  <>
    <InputLabel shrink>text</InputLabel>
    <Field
      name="text"
      label="text"
      fullWidth
      component={Input}
      multiline
      defaultValue={item.text}
      onBlur={(e) => setFieldValue(`list[${i}].text`, e.target.value)}
    />
    <InputLabel shrink>link</InputLabel>
    <Field
      name="link"
      label="link"
      fullWidth
      component={Input}
      multiLine
      defaultValue={item.link}
      onBlur={(e) => setFieldValue(`list[${i}].link`, e.target.value)}
    />
    <Field
      name="title"
      label="Título?"
      type="checkbox"
      inputProps={{ defaultChecked: item.title }}
      fullWidth
      component={TextField}
      defaultValue={item.title}
      onInput={(e) => {
        setFieldValue(`list[${i}].title`, e.target.value);
      }}
    />
  </>
);

const query = gql`
  query MyQuery($course: String) {
    material(
      where: { course: { _eq: $course }, deleted_at: { _is_null: true } }
      order_by: { order: asc }
    ) {
      id
      course
      link
      text
      title
    }
  }
`;

const mutation = gql`
  mutation MyMutation($objects: [material_insert_input!]!) {
    insert_material(
      objects: $objects
      on_conflict: {
        constraint: material_pkey
        update_columns: [link, text, title, order]
      }
    ) {
      affected_rows
    }
  }
`;

const removal = gql`
  mutation MyMutation($id: Int!) {
    delete_material_by_pk(id: $id) {
      course
    }
  }
`;

export default Materials;
