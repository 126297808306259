import * as lesson from "./Lesson";
import * as person from "./Person";
import * as turma from "./Turma";
import * as activity from "./Activity";
import * as enrollment from "./Enrollment";
import * as quiz from "./Quiz";
import * as question from "./Question";
import * as question_option from "./Question_Option";
import * as quiz_relation from "./QuizRelation";
import * as live_schedule from "./Live_Schedule";
import * as disciplina from "./Disciplina";
import * as payment_plan from "./Payment_Plan";
import * as register from "./Register";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  lesson,
  person,
  turma,
  activity,
  enrollment,
  quiz,
  question,
  question_option,
  quiz_relation,
  live_schedule,
  disciplina,
  payment_plan,
  register,
};
