/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { Paper } from "@material-ui/core";
import "./Quiz.css";

const query = gql`
  query MyQuery($person: Int, $quiz: Int) {
    quiz(where: { id: { _eq: $quiz }, deleted_at: { _is_null: true } }) {
      id
      name
      img
      shuffle_questions
      questions {
        id
        img
        pontos
        text
        null_question
        question_options {
          id
          text
          correct
        }
      }
      quiz_attempts(
        where: { person: { _eq: $person }, deleted_at: { _is_null: true } }
      ) {
        id
        quiz
        question_completions {
          id
          answer
          question_option {
            id
            correct
            questionByQuestion {
              pontos
              null_question
            }
          }
        }
      }
    }
  }
`;

var shuffled = false;
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}
const Quiz = observer((props) => {
  const quizId = props.quiz;
  const store = useContext(StoreContext);
  var { data, error, loading } = useQuery(query, {
    variables: { quiz: quizId, person: store.personId },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  data = data.quiz[0];
  //shuffling questions and answers:

  if (!shuffled && data.shuffle_questions) {
    data.questions = shuffle(data.questions);
    data.questions.forEach((q) => {
      q.question_options = shuffle(q.question_options);
    });
    shuffled = true;
  }

  const filteredQuestions = data.questions.filter(
    (question) => !question.null_question
  );
  const numQuestions = filteredQuestions.length;
  const pointsPerQuestion = 10 / numQuestions; // Total de pontos é 10
  filteredQuestions.forEach((question) => {
    question.pontos = parseFloat(pointsPerQuestion.toFixed(2));
  });

  var bestAttempt = {};
  data.quiz_attempts.forEach((at) => {
    var nota = 0;
    var answers = {};

    at.question_completions.forEach((answer) => {
      answers[answer.answer] = answer.question_option.correct;
      if (answer.question_option.correct) {
         nota += pointsPerQuestion;
      }
    });

    nota = parseFloat(nota.toFixed(2));

    if (!bestAttempt.nota || bestAttempt.nota <= nota) {
      bestAttempt = at;
      bestAttempt.nota = nota;
      bestAttempt.answers = answers;
      bestAttempt.total = 10;

      bestAttempt.nota = parseFloat(bestAttempt.nota.toFixed(2));
    }
  });

  return (
    <>
      <div>
        <Paper elevation={2} p={2} style={{ background: "#ffffdd" }}>
          <div style={{ padding: 30 }}>
            <h2>{"Correção do quiz: " + data.name}</h2>
            <h3>Nota: {bestAttempt.nota + "/" + 10}</h3>
            {data.img && (
              <img
                src={"https://drive.google.com/uc?id=" + data.img}
                style={{ margin: "10px" }}
              />
            )}
            <div>
              {filteredQuestions.map((question) => {
                return (
                  <>
                    <div>
                      <p style={{ marginTop: "40px" }} />
                      <h3>
                        {question.text + " (" + question.pontos + " pontos)"}
                      </h3>
                      {question.img && (
                        <img
                          src={"https://drive.google.com/uc?id=" + question.img}
                          style={{ margin: "10px" }}
                        />
                      )}
                      {question.question_options.map((opt) => {
                        var color = "gray";
                        var checked = false;
                        if (
                          Object.keys(bestAttempt.answers).includes(
                            opt.id.toString()
                          )
                        ) {
                          checked = true;
                          color = bestAttempt.answers[opt.id] ? "green" : "red";
                        } else if (opt.correct) {
                          color = "green";
                        }
                        return (
                          <p style={{ color: color }}>
                            <input
                              type="radio"
                              name={question.id}
                              value={opt.id}
                              checked={checked}
                              color="blue"
                            />
                            {opt.text}
                          </p>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
});

export default Quiz;
