import gql from "graphql-tag";

export const pendingProduzirConteudo = gql`
  query producaoConteudoPendente($turma: Int, $monitor: Int) {
    person(
      where: {
        enrollments: { registers: { turma: { _eq: $turma } } }
        monitores: { monitor: { _eq: $monitor } }
        activity_completions: {
          completed: { _eq: true }
          activityByActivity: {
            type: { _eq: "Produzir Conteúdo" }
            lessonByLesson: { turma: { _eq: $turma } }
          }
          monitor_comment: { _is_null: true }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc }
    ) {
      first_name
      last_name
      activity_completions(
        where: {
          completed: { _eq: true }
          activityByActivity: {
            type: { _eq: "Produzir Conteúdo" }
            lessonByLesson: { turma: { _eq: $turma } }
          }
          monitor_comment: { _is_null: true }
          deleted_at: { _is_null: true }
        }
      ) {
        monitor_comment
        activityByActivity {
          type
          lessonByLesson {
            number
            turma
          }
        }
      }
    }
  }
`;

export const pendingProduzirAtividade1 = gql`
  query producaoAtividade1Pendente($turma: Int, $monitor: Int) {
    person(
      where: {
        enrollments: { registers: { turma: { _eq: $turma } } }
        monitores: { monitor: { _eq: $monitor } }
        activity_completions: {
          completed: { _eq: true }
          activityByActivity: {
            type: { _eq: "Atividade Prática 1" }
            lessonByLesson: { turma: { _eq: $turma } }
          }
          monitor_comment: { _is_null: true }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc }
    ) {
      first_name
      last_name
      activity_completions(
        where: {
          completed: { _eq: true }
          activityByActivity: {
            type: { _eq: "Atividade Prática 1" }
            lessonByLesson: { turma: { _eq: $turma } }
          }
          monitor_comment: { _is_null: true }
          deleted_at: { _is_null: true }
        }
      ) {
        monitor_comment
        activityByActivity {
          type
          lessonByLesson {
            number
            turma
          }
        }
      }
    }
  }
`;

export const pendingProduzirAtividade2 = gql`
  query producaoAtividade2Pendente($turma: Int, $monitor: Int) {
    person(
      where: {
        enrollments: { registers: { turma: { _eq: $turma } } }
        monitores: { monitor: { _eq: $monitor } }
        activity_completions: {
          completed: { _eq: true }
          activityByActivity: {
            type: { _eq: "Atividade Prática 2" }
            lessonByLesson: { turma: { _eq: $turma } }
          }
          monitor_comment: { _is_null: true }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc }
    ) {
      first_name
      last_name
      activity_completions(
        where: {
          completed: { _eq: true }
          activityByActivity: {
            type: { _eq: "Atividade Prática 2" }
            lessonByLesson: { turma: { _eq: $turma } }
          }
          monitor_comment: { _is_null: true }
          deleted_at: { _is_null: true }
        }
      ) {
        monitor_comment
        activityByActivity {
          type
          lessonByLesson {
            number
            turma
          }
        }
      }
    }
  }
`;
