/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery, useMutation } from "react-apollo-hooks";
import { Formik, Form, FieldArray } from "formik";
import * as Quizzes from "./GQL/Quiz";
import { StoreContext } from "../store";
import Button from "@material-ui/core/Button";
import { Dialog, Paper } from "@material-ui/core";
import "./Quiz.css";
import ShowQuizResult from "./ShowQuizResult";
import axios from "axios";

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const img_style = { margin: "10px", maxWidth: "60VW" };

const Quiz = observer((props) => {
  const quizId = props.match.params.id;
  const store = useContext(StoreContext);
  const [state, setState] = useState();
  const [accept, setAccept] = useState(false);
  const [shuffled, setShuffled] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const createQuizAttempt = Quizzes.NEWATTEMPT;
  const [newQuizAttempt] = useMutation(createQuizAttempt);
  var { data, error, loading, refetch } = useQuery(Quizzes.GET, {
    variables: { id: quizId, person: store.personId },
  });
  /*useEffect(() => {
    logAction(
      LogType.ActivityView,
      `Lição ${lesson.number}, ${activity.type}:${activity.title}`
    );
  });*/
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  var count = data.quiz_attempt.length + 1;
  if (count > 3) return <ShowQuizResult quiz={quizId} />;
  const at = data.quiz_attempt;
  if (at.length > 0) {
    const allowedAttempt = new Date(at[at.length - 1].created_at);
    allowedAttempt.setDate(allowedAttempt.getDate() + 1);
    if (allowedAttempt > new Date())
      return (
        <>
          <p>
            Só é possível fazer uma nova tentativa 24h após a última. Próxima
            tentativa libera às {allowedAttempt.toISOString().split("T")[1]}
          </p>
          <Options
            count={count}
            quiz={quizId}
            person={store.personId}
            newQuizAttempt={newQuizAttempt}
          />
        </>
      );
  }
  data = data.quiz[0];

  const filteredQuestions = data.questions.filter((q) => !q.null_question);
  const numQuestions = filteredQuestions.length;
  const pointsPerQuestion = 10 / numQuestions;
  

  //shuffling questions and answers:
  if (!shuffled && data.shuffle_questions) {
    data.questions = shuffle(data.questions);
    data.questions.forEach((q) => {
        q.question_options = shuffle(q.question_options);
    });
    setShuffled(true);
}
  return (
    <>
      <Dialog open={!accept && store.ui.turma.course.includes("MF")}>
        <div className="p30">
          <h2 style={{ textAlign: "center" }}>
            TERMO DE CIÊNCIA E COMPROMISSO
          </h2>
          <p>
            A Escola de Mindfulness Funcional - EMF estabelece uma relação de
            confiança com a(o)s aluna(o)s e valoriza a ética no contexto
            educacional, abrangendo o ambiente virtual de aprendizagem.
          </p>
          <p>
            Não é tolerado colar (pescar) na realização das avaliações de
            conhecimentos das disciplinas do curso. Um dos objetivos da
            avaliação é determinar o nível de conhecimento individual. A cola
            (pesca) serve para mascarar a falta de conhecimentos e de
            habilidades. A verificação de que houve cola na avaliação de alguma
            disciplina acarretará sanções, que podem ser desde a zeragem da nota
            dos envolvidos até a rescisão do contrato (desligamento do curso).
          </p>
          <p>
            Está igualmente vedada a divulgação parcial ou integral das questões
            ou respostas das avaliações.
          </p>
          <div style={{ fontWeight: "bold" }}>
            <input
              type="checkbox"
              onClick={(e) => {
                if (e.target.checked) setAccept(true);
              }}
            />
            Declaro que li, estou ciente e me comprometo a cumprir essas normas,
            sob pena de me sujeitar às sanções corretivas e até rescisórias de
            contrato.
          </div>
        </div>
      </Dialog>
      <div>
        <Formik
          initialValues={data}
          onSubmit={async (values, actions) => {
            var answers = [];
            var nota = 0;
            var total = 10;
            if (!state || Object.keys(state).length != filteredQuestions.length) {
              alert("É necessário responder todas as perguntas.");
            } else {
              filteredQuestions.forEach((q) => {
                if (state && state[q.id]) {
                  answers.push({ answer: state[q.id] });
                  var correctAnswer = 0;
                  q.question_options.forEach((o) => {
                    if (o.correct) correctAnswer = o.id;
                  });
                  if (state[q.id] == correctAnswer)
                    nota += pointsPerQuestion;
                }
              });
              var answer = {
                quiz: quizId,
                person: store.personId,
                question_completions: {
                  data: answers,
                },
              };
              try {
                await newQuizAttempt({
                  variables: { objects: [{ ...answer }] },
                });
                axios
                  .post("/.netlify/functions/update-grades")
                  .then((response) => {
                    console.log(response.data.message);
                  });
                if (count < 3) {
                  alert("Respostas registradas. Nota: " + nota.toFixed(2) + "/" + total.toFixed(2));
                  window.location.replace("/grades-student");
                } else {
                  //mostra gabarito
                  alert(
                    "Respostas registradas. Nota: " +
                      nota +
                      "/" +
                      10 +
                      "\nVocê será redirecionado para o gabarito"
                  );
                  refetch();
                }
              } catch (err) {
                alert(err);
              }
            }
            actions.setSubmitting(false);
          }}
          render={({ values, isSubmitting }) => (
            <Form>
              <Paper elevation={2} p={2}>
                <div style={{ padding: 30 }}>
                  <h2>{values.name}</h2>
                  <div className="info">Tentativa {count}/3</div>
                  {values.img ? (
                    values.img.includes("drive.google") ? ( //drive file link
                      <img
                        src={
                          "https://drive.google.com/uc?id=" +
                          values.img.split("/d/")[1].split("/")[0]
                        }
                        style={img_style}
                      />
                    ) : values.img.includes("http") ? ( //any other weblink
                      <img src={values.img} style={img_style} />
                    ) : (
                      <img
                        src={"https://drive.google.com/uc?id=" + values.img}
                        style={img_style}
                      />
                    )
                  ) : (
                    ""
                  )}
                  <FieldArray
                    name="quiz"
                    render={(arrayHelpers) => (
                      <div>
                        {}
                        {filteredQuestions.map((question) => {
                          return (
                            <>
                              <p style={{ marginTop: "40px" }} />
                              <h3>
                                {question.text + " (" + pointsPerQuestion.toFixed(2) + " pontos)"}
                              </h3>
                              {question.img ? (
                                question.img.includes("drive.google") ? ( //drive file link
                                  <img
                                    src={
                                      "https://drive.google.com/uc?id=" +
                                      question.img.split("/d/")[1].split("/")[0]
                                    }
                                    style={img_style}
                                  />
                                ) : question.img.includes("http") ? ( //any other weblink
                                  <img src={question.img} style={img_style} />
                                ) : (
                                  <img
                                    src={
                                      "https://drive.google.com/uc?id=" +
                                      question.img
                                    }
                                    style={img_style}
                                  />
                                )
                              ) : (
                                ""
                              )}
                              {question.question_options.map((opt) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <input
                                          type="radio"
                                          name={question.id}
                                          value={opt.id}
                                          onChange={handleChange}
                                          style={{ verticalAlign: "middle" }}
                                        />
                                      </div>
                                      <div>
                                        {opt.img && (
                                          <p>
                                            <img
                                              src={
                                                "https://drive.google.com/uc?id=" +
                                                opt.img
                                              }
                                              style={img_style}
                                            />
                                          </p>
                                        )}
                                        <p>{opt.text}</p>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                        <div id="erros" style={{ color: "red" }}></div>
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Salvar
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </Paper>
            </Form>
          )}
        />
      </div>
    </>
  );
});

export default Quiz;

const Options = (props) => {
  return (
    <div className="p30" style={{ textAlign: "center" }}>
      <h3>Verifique sua nota na página de notas</h3>
      <p>
        O gabarito da prova aparece automaticamente após esgotadas todas as
        tentativas. Porém você tem a opção de desistir das tentativas restantes
        e ver o gabarito.
        <br />
      </p>
      <div>
        <Button
          variant="contained"
          color="secondary"
          style={{ textAlign: "center", background: "#FF8888" }}
          onClick={async () => {
            if (
              window.confirm(
                "Tem certeza que quer desistir das tentativas para ver o gabarito?"
              )
            ) {
              try {
                for (var i = props.count; i <= 3; i++) {
                  await props.newQuizAttempt({
                    variables: {
                      objects: [
                        {
                          quiz: props.quiz,
                          person: props.person,
                        },
                      ],
                    },
                  });
                }
                window.location.reload();
              } catch (e) {
                alert(JSON.stringify(e.message));
              }
            }
          }}
        >
          (CUIDADO!) Desistir das tentativas restantes e ver o gabarito
        </Button>
      </div>
    </div>
  );
};
