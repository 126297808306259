import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment questionFields on question {
    quiz
    text
    img
    pontos
    null_question
  }
`;

export const GET = gql`
query getQuestion($id: Int, $limit: Int) {
    question(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
        id
        ...questionFields     
        question_options {
            id
            text
            correct
            img
        }        
    }
}
${FieldsFragment}
`;

export const INSERT = gql`
    mutation newQuestion($objects: [question_insert_input!]!) {
        insert_question(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updateQuestion($_set: question_set_input={}, $id: Int){
    update_question(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`





export function flatten(data){
    data.forEach(question=>{ 
      if(question.question_options)
      question.question_options.forEach((opt,i)=>{
        question['Resposta '+(i+1)]=opt.text
      })
      delete question.question_options
    })    
    return data
}


export function checkData(values){ //check values before sending to DB
  var count = 0;
  values.question_options.forEach(opt=>{
    if(opt.correct) count++
  })
  if(count===1) return ''
  else return 'Erro: as perguntas devem ter exatamente uma resposta correta.'
}

export const fieldtypes = {
  pontos: "number",
  null_question: "checkbox",
  text: "textarea"
}