import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo-hooks";
import { StoreContext } from "../../store";
import axios from "axios";
import imgProjeto from "../../assets/projeto-aplicado.jpg";

const Teste = observer((props) => {
  const enrollmentProject = async () => {
    var objects = [];
    objects.push({
      email: store.email,
      course: course,
      registers: { data: { turma: 217 } },
    });
    try {
      await enrollment({ variables: { objects } });
      showMsg("Atualize a página e acesse o curso no menu à esquerda");
    } catch (err) {
      console.error(err);
    }
  };
  const [enrollment] = useMutation(mutation);
  const showMsg = (msg) => {
    document.getElementById("message").innerHTML = msg;
  };
  // eslint-disable-next-line no-unused-vars
  var forms = {
    "Dados pessoais": ["email", "name", "cpf"],
    "Outras informações": ["utm", "utm_others"],
  };
  const prices = {
    PIX: 290,
    "1x cartão": 290,
    "6x cartão": 330,
  };
  const course = "Projeto Aplicado";
  // eslint-disable-next-line no-unused-vars
  const priceBRL = prices.PIX;
  const store = useContext(StoreContext);
  const { data, loading, error } = useQuery(query, {
    variables: {
      course,
      email: store.email ? store.email : "",
    },
  });
  if (error) return <p>Erro: {error.message}</p>;
  if (loading) return <p>Carregando...</p>;
  var missingRequirements = [];
  data.prerequisite_course.forEach(async (relation) => {
    let courseCompletion = false;
    //check if student completed the course:
    relation.courseByPrerequisite?.enrollments.forEach((enrollment) => {
      let enrollmentCompletion = false;
      enrollment.registers.forEach((register) => {
        try {
          let { data } = axios.get(
            "/.netlify/functions/re_enroll?" +
              `email=${store.email}` +
              `&turma=${register.turmaByTurma.name}` +
              `&course=${course}`
          );
          console.log(data);
          enrollmentCompletion &&= data.passed;
        } catch (e) {
          alert(e);
        }
      });
      courseCompletion ||= enrollmentCompletion; //student has to pass in at least one enrollment of each prerequisite course
    });
    if (!courseCompletion) missingRequirements.push(relation.prerequisite);
  });
  if (missingRequirements.length > 0)
    return (
      <p>
        Erro: Para se inscrever neste curso, falta ser aprovado nos cursos:{" "}
        <b>{missingRequirements.join(", ")}</b>
      </p>
    );
  return (
    <>
      <div className="center">
        <div>
          <h1>{course}</h1>
          <p>
            {" "}
            Nessa disciplina, vamos aprender como elaborar um relato de caso
            sobre Mindfulness Funcional e redigir um artigo para conclusão do
            curso de pós-graduação em Mindfulness Funcional. Qualquer dúvida,
            entre em contato com secretaria@mindfulnessfuncional.com.br. <br />
            Bons estudos!{" "}
          </p>
          <img
            src={imgProjeto}
            alt=""
            style={{ width: 600, marginBottom: 20 }}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={enrollmentProject}
          >
            MATRICULE-SE
          </Button>
        </div>
        <p id="message" style={{ textAlign: "center", fontSize: 18 }} />
      </div>
    </>
  );
});

export default Teste;

const query = gql`
  query Requisites($course: String!, $email: String!) {
    prerequisite_course(
      where: { target: { _eq: $course }, deleted_at: { _is_null: true } }
    ) {
      prerequisite
      courseByPrerequisite {
        name
        enrollments(
          where: { email: { _eq: $email }, deleted_at: { _is_null: true } }
        ) {
          id
          registers {
            turmaByTurma {
              name
            }
          }
        }
      }
    }
  }
`;

const mutation = gql`
  mutation MyMutation($objects: [enrollment_insert_input!]!) {
    insert_enrollment(objects: $objects) {
      affected_rows
    }
  }
`;
