import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery, useMutation } from "react-apollo-hooks";
import { Formik, Form } from "formik";
import { StoreContext } from "../store";
import Button from "@material-ui/core/Button";
import gql from "graphql-tag";

const query = gql`
query disciplina($disc:Int){
  disciplina(where:{id:{_eq:$disc},deleted_at: {_is_null: true}}){
    id
    name
    teacher
  }
}
`;

const mutation = gql`
mutation feedback($object: feedback_insert_input!) {
  insert_feedback_one(object: $object, on_conflict: {constraint: feedback_person_disciplina_key, update_columns: [comment,rating]}) {
    id
  }
}`;

const Survey = observer((props) => {
  const params = new URLSearchParams(props.location.search)
  const disciplina = params.get('disciplina');
  const store = useContext(StoreContext);
  const person = store.personId;
  const [rating, setRating] = useState();
  const [teacherScore, setTeacherScore] = useState();
  var rates=[...Array(11).keys()];
  const [upsert] = useMutation(mutation);
  var { data, error, loading } = useQuery(query,{variables:{disc:disciplina}});
  if(loading) return <p>Carregando...</p>;
  if(error) return <p>Erro: {error}</p>;
  var titulo = data.disciplina[0].name
  var teacher = data.disciplina[0].teacher
  return(
    <>
    <div style={{textAlign:'center',marginTop:'30px'}} id="geral">
      <h3>Olá! Qual seu índice de satisfação com a disciplina {titulo}?</h3>
      {
        rates.map(r=>{
          var cor = r>5? 5 : r;
          return (
            <Button
              style={rating && rating!==r?
                {background:'lightgray',margin:'2px'}
              :{
                background:'#'+((5+cor-r)*50).toString(16).padStart(2,'0')+''+(cor*50).toString(16).padStart(2,'0')+'00',
                margin:'2px'
              }}
              onClick={()=>setRating(r)}
            >{r}</Button>
          )
        })
      }
      <h3>Qual seu índice de satisfação com o(a) professor(a) {teacher}?</h3>
      {
        rates.map(r=>{
          var cor = r>5? 5 : r;
          return (
            <Button
              style={teacherScore && teacherScore!==r?
                {background:'lightgray',margin:'2px'}
              :{
                background:'#'+((5+cor-r)*50).toString(16).padStart(2,'0')+''+(cor*50).toString(16).padStart(2,'0')+'00',
                margin:'2px'
              }}
              onClick={()=>setTeacherScore(r)}
            >{r}</Button>
          )
        })
      }
      <Formik
        onSubmit={async (values, actions) => {
          var object = {
            person: person,
            comment: values.comment,
            disciplina: disciplina,
            rating: rating,
            teacher_score: teacherScore
          }
          try{
            await upsert({ variables: { object: object} });
            document.getElementById("geral").innerHTML="<h3>Feedback enviado. Obrigado!</h3>"
          }catch(err){
            alert(err);
          }
          actions.setSubmitting(false); 
        }}
        render={({ values, setValues, isSubmitting }) => (
          <Form>
            <p><textarea
              onChange={e=>{
                setValues({comment:e.target.value})
              }}
              style={{margin:20}}
              placeholder="Observações"
              rows={10}
              cols={100}
            /></p>
            <Button 
              variant="contained" 
              color="primary" 
              type="submit"
              disabled={!rating || !teacherScore || isSubmitting}
            >Enviar</Button>
          </Form>
        )}

      />
    </div>
    </>
  );
})
  
export default Survey;
  