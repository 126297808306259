export default function CheckRecovery(quiz_relations, grades) {
  const nonRecoveryQuizzes = quiz_relations.filter(
    (relation) =>
      !relation.quizByQuiz.name.toUpperCase().includes("RECUPERAÇÃO")
  );
  const allQuizzesAttempted = nonRecoveryQuizzes.every((relation) =>
    relation.quizByQuiz.quiz_attempts.find((attempt) => attempt)
  );
  let retake = false;
  if (grades) {
    if (allQuizzesAttempted) {
      grades.disciplina_grades.forEach((grade) => {
        if (grade.grade < 7) {
          retake = true;
        }
      });
    }
  }

  return retake;
}
