import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import { store } from "../store";

const query = gql`
  query Version {
    sysinfo_by_pk(key: "version") {
      value
    }
  }
`;

export var checkVersion = () => {};

const Bar = (props) => {
  let version = "";
  const { data, refetch } = useQuery(query);
  checkVersion = refetch;
  if (store.expired) return "";
  if (data) version = data.sysinfo_by_pk.value;
  if (!version || !store.version || store.version === version) return "";
  return (
    <>
      <div className="update-bar" onClick={() => window.location.reload()}>
        Nova versão disponível, clique para atualizar.
      </div>
    </>
  );
};

export default Bar;

export const storeVersion = async (store) => {
  let { data } = await store.apolloClient.query({ query });
  store.version = data.sysinfo_by_pk.value;
};
