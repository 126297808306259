import axios from 'axios';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import {Button} from "@material-ui/core";

const Upload = (props) =>{  
	var [state, setState] = useState({
	  selectedFile: null
	});	
  const folders = {
    parent: '1iwvT13Ksyzc-wNrHvrPJCi3o25U5ZPt6',
    projetos: '1n37Zk1NwQiEwJm0MpXGqMXfWCx8Epw9B',
    atividades: '1apD082NAd-fkYZK-3Wy-nfRteyIN5IK9',
    inscricao: '14Uwcch3eLUIkbWb26EyFT26Ov4HDJpAc'
  };
  const accepted_extentions = props.turma && (props.turma.includes('ACT') || props.turma.includes('FAP')) && props.folder===("Produzir Conteúdo" || "Produzir Conteúdo - workshop")?
  ['mpg','mp4','avi','gif','png','jpg','jpeg','ogg','m4a','mp3','wav']
  :['doc','pdf','rtf','docx','txt','mpg','mp4','avi','gif','png','jpg','jpeg','ogg','m4a','mp3','wav','ppt', 'pptx']
  
	// On file select (from the pop up)
	const onDrop = files => {	
    document.getElementById('msg').innerHTML='';
    var file = files[0];
    if(file){
      var extension = file.name.split('.');
      extension=extension[extension.length-1].toLowerCase();
      var size = file.size;
      if(!accepted_extentions.includes(extension)) {
        document.getElementById('msg').innerHTML='Tipo de arquivo não permitido: '+extension;
      }else if(size>260*1024*1024){
        document.getElementById('msg').innerHTML='Arquivo maior que o limite de 40MB';
      }else{
        // Update the state
        setState({ selectedFile: file });
      }
    }	
  };	
	// On file upload (click the upload button)
	const onFileUpload = async () => {	
    var field = props.field;
    document.getElementById('upload'+field).innerHTML='Enviando arquivo...'
    var file = state.selectedFile;
    var oldfile = props.oldFile;
    var token = await axios.get('/.netlify/functions/drive?action=send')
    token = token.data
    var config = {headers:{
      Authorization:'Bearer '+token} 
    };    
    axios.post("https://www.googleapis.com/upload/drive/v3/files?uploadType=media",file,config)
    .then(res=>{
      var id = res.data.id;
      var name = props.prefix;
      if(name) {
        name+=' - '
      }else{
        name=''
      };
      var meta={
        name:name+file.name
      };
      var folder = props.folder;
      if(folder && folder.toUpperCase().includes('PROJETO')){
        folder=folders.projetos;
      }else if(folder && folder.toUpperCase().includes('INSCRIÇÃO')){
        folder=folders.inscricao;
      }else{
        folder=folders.atividades;
      };
      axios.patch("https://www.googleapis.com/drive/v3/files/"+id+"?addParents="+folder,meta,config)
      .then(r=>{        
        if(props.setFieldValue) {
          props.setFieldValue(props.field,id);
          if(
            props.folder.toUpperCase().includes('PROJETO')
            || props.folder.toUpperCase().includes('ENVIO')
            || props.folder.toUpperCase().includes('UPLOAD')
            || props.folder.toUpperCase().includes('PRODUZIR CONTEÚDO')
            || props.folder.toUpperCase().includes('Produzir Conteúdo - workshop')
            || props.folder.toUpperCase().includes('PERGUNTA')
            || props.folder.toUpperCase().includes('Não obrigatória')
            || props.folder.toUpperCase().includes('TCC')
            ) props.setFieldValue("file_date", (new Date()).toISOString().split('T')[0])
        }
        if(folder===folders.inscricao){
          document.getElementById('upload'+field).innerHTML='Arquivo enviado.';
        }else{
          document.getElementById('upload'+field).innerHTML='Arquivo enviado. Clique em Registrar Atividade para salvar a alteração.';
        }
        axios.post('https://www.googleapis.com/drive/v3/files/'+id+'/permissions',{role:'reader',type:'anyone'},config);
        if(oldfile) axios.delete('https://www.googleapis.com/drive/v3/files/'+oldfile,config);
      })
      .catch(err=>{
        console.log(err);
      })
    })
	};	

  var file = state.selectedFile
  const files = file? (
    <>
    <li key={file.name}>
      {file.name} - {Math.round(file.size/1024)} kB
    </li>
    <Button color='primary' variant='contained' onClick={onFileUpload}>Enviar arquivo</Button>
    </>
  ):'';
  return (
    <div id={"upload"+props.field} style={{background:'#F4F4F4', minHeight:"90px"}}>
      <Dropzone onDrop={onDrop} maxFiles={1}>
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div style={{border:'dashed',paddingLeft:'20px',borderColor:'lightgray',background:'#F4F4F4'}} {...getRootProps({className: 'dropzone'})}>
              {props.label}
              <input {...getInputProps()} />
              {props.oldFile?
              <p>ID do arquivo registrado: {props.oldFile}</p> :''}
              <h3>Enviar arquivo?</h3>
              <p style={{color:'gray'}}>Arraste e solte arquivos ou clique aqui para buscar. Após selecionar o arquivo, clique no botão ENVIAR para fazer o upload do arquivo.</p>
              <p id="msg" style={{color:'#850b03'}}></p>
            </div>
            <ul>{files}</ul>
          </section>
        )}
      </Dropzone>          
    </div>
  );
}

export default Upload;
