import gql from "graphql-tag";

export const GET = gql`
  query getlive_schedule($limit: Int) {
    live_schedule(limit: $limit, order_by: { turma: desc }) {
      turma
      day
      turmaByTurma {
        name
      }
    }
    turma {
      id
      name
    }
  }
`;

export const INSERT = gql`
  mutation newlive_schedule($objects: [live_schedule_insert_input!]!) {
    insert_live_schedule(objects: $objects) {
      affected_rows
    }
  }
`;

export const EDIT = gql`
  mutation updatelive_schedule($_set: live_schedule_set_input!, $id: Int) {
    update_live_schedule(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
    }
  }
`;

export function flatten(data) {
  data.forEach((d) => {
    d.turma_name = d.turmaByTurma.name;
    delete d.turmaByTurma;
    d.day = translate[d.day];
  });
  return data;
}

export function lists(data) {
  data.day = Object.keys(translate).map((pair) => ({
    id: pair,
    name: translate[pair],
  }));
  return data;
}

const translate = {
  Sun: "Domingo",
  Mon: "Segunda",
  Tue: "Terça",
  Wed: "Quarta",
  Thu: "Quinta",
  Fri: "Sexta",
  Sat: "Sábado",
};
