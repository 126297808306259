/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useMutation, useQuery } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { textLabels } from "./dataTablesLocalization";
import { StoreContext } from "../store";
import { Button, Dialog, Grid } from "@material-ui/core";
import gql from "graphql-tag";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

const query = gql`
  query students($turma: Int) {
    person(
      where: {
        enrollments: {
          registers: { turma: { _eq: $turma } }
          deleted_at: { _is_null: true }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc, last_name: asc }
    ) {
      id
      first_name
      last_name
      email
      city
      state
      monitores {
        id
        monitor
        personByMonitor {
          first_name
          last_name
          email
        }
      }
      enrollments(
        where: {
          registers: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
          deleted_at: { _is_null: true }
        }
      ) {
        registers(
          where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
        ) {
          is_professor
          is_tutor
          is_team_member
          is_monitor
          is_audit
          is_evaluator
          suspended
          cancelled
          enrollmentByEnrollment {
            person {
              first_name
              last_name
              email
            }
          }
        }
      }
    }
  }
`;

const Table = observer((props) => {
  const store = useContext(StoreContext);
  const [dialog, setDialog] = useState({
    open: false,
    data: {
      student: null,
      person_list: [],
    },
  });
  var { data, error, loading, refetch } = useQuery(query, {
    variables: { turma: store.ui.turma.id },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (Alunos_Monitores.jsx): {error.message}</p>;
  data = data.person;
  var dataView = JSON.parse(JSON.stringify(data)); //clone data to preserve original object
  var titles = {};
  var monitors = [];
  dataView.forEach((d) => {
    delete d["__typename"];
    d.nome = d.first_name + " " + d.last_name;
    if (d.monitores[0] && d.monitores[0].personByMonitor) {
      d.monitor = d.monitores
        .map(
          (monitor) =>
            monitor.personByMonitor?.first_name ??
            `(Nome não encontrado. id:${monitor.id})` +
              (monitor.personByMonitor?.last_name ??
                "(Sobrenome não encontrado)")
        )
        .join(", ");
      d.monitor_email = d.monitores
        .map(
          (monitor) =>
            monitor.personByMonitor?.email ?? "(Email não encontrado)"
        )
        .join(", ");
      d.monitor_relation = d.monitores[0].id;
    }
    let register = d.enrollments[0].registers[0];
    if (register) {
      let title = [];
      if (register.suspended) title.push("SUSPENSO");
      if (register.cancelled) title.push("CANCELADO");
      if (register.is_tutor) title.push("Tutor");
      if (register.is_monitor) {
        title.push("Monitor");
        monitors.push(d);
      }
      if (register.is_professor) title.push("Professor");
      if (register.is_team_member) title.push("Equipe");
      if (register.is_audit) title.push("Ouvinte");
      if (register.is_evaluator) title.push("Avaliador");
      title = title.length > 0 ? ` (${title.join(", ")})` : "";
      titles[d.nome] = title;
    }
  });
  const columns = [
    {
      name: "nome",
      options: {
        setCellProps: () => ({ style: { minWidth: "300px" } }),
        // eslint-disable-next-line no-dupe-keys
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            //zIndex: 100
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            //zIndex: 101
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) =>
          value + titles[value],
      },
    },
    "email",
    "city",
    "state",
    "monitor",
    "monitor_email",
  ];
  return (
    <>
      <Grid container>
        <div
          style={{
            display: "table",
            tableLayout: "fixed",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <MUIDataTable
            title="Alunos da turma"
            data={dataView}
            columns={columns}
            options={{
              responsive: "scroll",
              rowsPerPageOptions: [50, 100, 200],
              rowsPerPage: 200,
              selectableRows: false,
              filterType: "multiselect",
              textLabels,
              onCellClick: (_, cmeta) => {
                if (store.admin) {
                  const person = dataView[cmeta.dataIndex];
                  setDialog({
                    open: true,
                    data: {
                      student: person,
                      person_list: monitors,
                    },
                  });
                }
              },
            }}
          />
          <Dialog
            open={dialog.open}
            onClose={() => setDialog({ ...dialog, open: false })}
          >
            <MonitorControl data={dialog.data} refetch={refetch} />
          </Dialog>
        </div>
      </Grid>
    </>
  );
});

export default Table;

const MonitorControl = ({ data, refetch }) => {
  const mutationInsert = gql`
    mutation insertMonitorRelation($object: monitor_relation_insert_input!) {
      insert_monitor_relation_one(object: $object) {
        id
      }
    }
  `;
  const mutationUpdate = gql`
    mutation updateMonitorRelation($id: Int!, $monitor: Int) {
      update_monitor_relation_by_pk(
        pk_columns: { id: $id }
        _set: { monitor: $monitor }
      ) {
        id
      }
    }
  `;
  const [insert] = useMutation(mutationInsert);
  const [update] = useMutation(mutationUpdate);
  return (
    <div className="p30 center">
      <Formik
        onSubmit={async (values, actions) => {
          try {
            if (values.replace) {
              var res = await update({
                variables: {
                  id: data.student.monitor_relation,
                  monitor: values.monitor,
                },
              });
            } else {
              // eslint-disable-next-line no-redeclare
              var res = await insert({
                variables: {
                  object: {
                    student: data.student.id,
                    monitor: values.monitor,
                  },
                },
              });
            }
            alert("Concluído.");
            refetch();
          } catch (e) {
            alert(e.message);
          }
          actions.setSubmitting(false);
        }}
        initialValues={{ student: data.student.id }}
        render={({ values, isSubmitting }) => (
          <Form>
            {data.student.monitor && (
              <Field
                type="checkbox"
                label="substituir monitor?"
                name="replace"
                component={TextField}
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ margin: "10px" }}
              />
            )}
            <Field
              select={true}
              label="monitor"
              name="monitor"
              component={TextField}
              fullWidth
              style={{ margin: "10px" }}
            >
              {data.person_list.map((person) => (
                <option value={person.id} className="p5">
                  {person.nome}
                </option>
              ))}
            </Field>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Atribuir monitor
            </Button>
          </Form>
        )}
      />
    </div>
  );
};
