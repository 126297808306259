import gql from "graphql-tag";

export const GET = gql`
query getQuizRelation($id: Int, $limit: Int) {
    quiz_relation(order_by: {quiz: desc}) {
        quiz
        turma  
        turmaByTurma{
          name
        }   
        quizByQuiz{
          name
        }    
    }
    quiz{
      id
      name
    }
    turma{
      id
      name
    }
}
`;

export const INSERT = gql`
    mutation newquiz_relation($objects: [quiz_relation_insert_input!]!) {
        insert_quiz_relation(objects: $objects) {
        affected_rows
        }
    }
`

export const EDIT = gql`
  mutation updateQuizRelation($_set: quiz_relation_set_input={}, $id: Int){
    update_quiz_relation(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`

export function lists(data){
  data.quiz.forEach(quiz=>{
    quiz.name = quiz.id+' - '+quiz.name
  })
  return data
}

export function flatten(data){
  data.forEach(quiz_relation=>{
    if(quiz_relation.turmaByTurma) quiz_relation.turma_name = quiz_relation.turmaByTurma.name;
    if(quiz_relation.quizByQuiz) quiz_relation.quiz_name = quiz_relation.quizByQuiz.name;
    delete quiz_relation.turmaByTurma;
    delete quiz_relation.quizByQuiz;
  })
  return data;
}