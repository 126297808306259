/* eslint-disable no-redeclare */
import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { Formik, Field, Form } from "formik";
import { Button, IconButton } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import axios from "axios";
import "../../css/Apply.css";
import { questions, forms } from "../Apply";
import { forms as forms2 } from "../Apply-N2";
import { forms as forms3 } from "../Apply-N3";
import SavedSnack from "../SavedSnack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ApplicationList from "./ApplicationList";
import { StoreContext } from "../../store";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { query } from "./ApplicationList";
import EditIcon from "@material-ui/icons/Edit";

const mutation = gql`
  mutation MyMutation($_set: application_set_input!, $id: Int!) {
    update_application_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      person
    }
  }
`;
const mutationP = gql`
  mutation updatePerson($_set: person_set_input!, $id: Int!) {
    update_person_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;
const mutationPP = gql`
  mutation InsertPlan($object: payment_plan_insert_input!) {
    insert_payment_plan_one(object: $object) {
      id
    }
  }
`;
const queryBolsa = gql`
  query MyQuery(
    $bolsa: Int
    $course: String
    $lote: Int
    $matricula: Int
    $parcelas: Int
    $coupon: String
  ) {
    course_plan(
      where: {
        bolsa: { _eq: $bolsa }
        course: { _eq: $course }
        lote: { _eq: $lote }
        matricula: { _eq: $matricula }
        parcelas: { _eq: $parcelas }
        deleted_at: { _is_null: true }
      }
    ) {
      lote
      matricula
      parcelas
      valor_parcela
      valor_total
    }
    coupon(where: { code: { _eq: $coupon }, deleted_at: { _is_null: true } }) {
      percent_value
    }
  }
`;

const Apply = (props) => {
  var { data, loading, error, refetch } = useQuery(query, {
    variables: { id: props.id },
  });
  var consultor = "";
  var tipo = "";
  let cursoDescricao = "";
  var course = "";
  var bolsa = false;
  var semestre = "";
  const windowSize = useWindowWidth();
  var perguntas = null;
  const [update] = useMutation(mutation);
  const [updatePerson] = useMutation(mutationP);
  const [insertPaymentPlan] = useMutation(mutationPP);
  const store = useContext(StoreContext);
  const [readOnly, setReadOnly] = useState(true);
  var variables = {};
  const [list, setList] = useState(false);
  const [snack, setSnack] = useState({ open: false, msg: "" });
  const showMsg = (msg) => {
    setSnack({ open: true, msg });
  };
  if (data) {
    data = data.application[0];
    consultor = data.consultant
      ? data.consultant.personByPerson.first_name
      : "Automático";
    tipo = data.tipo;
    switch (tipo) {
      case "formacao":
        cursoDescricao += "Formação";
        break;
      case "modulo-1":
        cursoDescricao += "Módulo 1";
        break;
      case "completo":
        cursoDescricao += "Curso Completo RFT";
        break;
      case "basico":
        cursoDescricao +=
          "A prática clínica da ACT com crianças e adolescentes - Módulo Básico";
        break;
      case "avancado":
        cursoDescricao +=
          "A prática clínica da ACT com crianças e adolescentes - Módulo Avançado";
        break;
      default:
        cursoDescricao += "Especialização";
        break;
    }
    course = data.course;
    bolsa = data.bolsa;
    semestre = data.semestre;
    var bolsa_aprovada = data.bolsa_aprovada;
    if (bolsa_aprovada) bolsa_aprovada = bolsa_aprovada.match(/[0-9]/);
    if (bolsa_aprovada) bolsa_aprovada = bolsa_aprovada[0];
    else bolsa_aprovada = 0;
    let forma_pagamento;
    switch (true) {
      case data.course.includes("MF"):
        if (data.course.includes("Formação")) {
          forma_pagamento = data.forma_pagamento_for;
        } else if (data.nivel === 1) {
          forma_pagamento = data.forma_pagamento_esp;
        } else {
          forma_pagamento = data.forma_pagamento_esp_n2;
        }
        break;
      case data.course === "Módulo 1 RFT":
        forma_pagamento = data.forma_pagamento_RFT_modulo1;
        break;
      case data.course === "Curso Completo RFT":
        forma_pagamento = data.forma_pagamento_RFT_completo;
        break;
      case data.course === "Básico - ACT Crianças e Adolescentes":
        forma_pagamento =
          data.forma_pagamento_act_para_criancas_e_adolescentes_basico;
        break;
      case data.course === "Completo - ACT Crianças e Adolescentes":
        forma_pagamento =
          data.forma_pagamento_act_para_criancas_e_adolescentes_completo;
        break;
      case data.course === "Conceituação de caso na FAP":
        forma_pagamento = data.forma_pagamento_ffc;
        break;
      case data.course === "Especialização FAP":
        forma_pagamento =
          data.forma_pagamento_pre_inscricao_fap ?? data.forma_pagamento_esp;
        break;
      case data.course === "Formação FAP":
        forma_pagamento =
          data.forma_pagamento_pre_inscricao_fap ?? data.forma_pagamento_esp;
        break;
      default:
        forma_pagamento = data.forma_pagamento_esp;
        break;
    }
    var matricula =
      forma_pagamento && forma_pagamento.includes("Matrícula") ? 1 : 0;
    var parcelas = forma_pagamento ? forma_pagamento.match(/[0-9]+/g) : "";
    parcelas =
      parcelas && parcelas.length > 0 ? parcelas[parcelas.length - 1] : 1;
    var lote = getLote(course, data.created_at);
    let coupon = data.cupom ? data.cupom.toUpperCase() : "";
    variables = {
      bolsa: bolsa_aprovada,
      course,
      lote,
      matricula,
      parcelas,
      coupon,
    };
  }
  var {
    data: plan,
    loading: loadingPlan,
    error: errorPlan,
    refetch: refetchPlan,
  } = useQuery(queryBolsa, {
    variables: variables,
  });
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error.message}</p>;
  if (loadingPlan) return <p>Carregando...</p>;
  if (errorPlan) return <p>Erro: {error}</p>;
  let discount = plan.coupon[0];
  plan = plan.course_plan[0];
  if (plan) {
    plan = JSON.parse(JSON.stringify(plan));
  } else {
    plan = {
      lote,
      matricula: parseInt(matricula),
      parcelas: parseInt(parcelas),
      valor_parcela: 0,
      valor_total: 0,
    };
  }
  let venc = lotes[course][plan.lote];
  plan.venc1 = venc;
  venc = new Date(venc);
  venc.setMonth(venc.getMonth() + 1);
  venc.setDate(data.vencimento);
  venc.setHours(0);
  venc = venc.toISOString().split("T")[0];
  plan.venc2 = venc;
  venc = venc.split("-");
  plan.segundo_vencimento = data.vencimento + "/" + venc[1] + "/" + venc[0];
  plan.criar_fatura = true;
  plan.criar_contrato = true;
  if (course === "Especialização MF" || course === "Formação MF")
    plan = descontoNNordeste(plan, data.personByPerson.state);
  else if (
    course === "Especialização ACT" ||
    course === "Formação ACT" ||
    course === "Especialização FAP" ||
    course === "Formação FAP" ||
    course === "Completo - ACT Crianças e Adolescentes" ||
    course === "Básico - ACT Crianças e Adolescentes" ||
    course === "Conceituação de caso na FAP"
  )
    plan = descontoACBS(plan, data.associado);

  if (discount) plan = descontoCupom(plan, discount.percent_value);
  if (!data.nivel || data.nivel === 1) {
    if (forms[course]) {
      if (bolsa) perguntas = forms[course].bolsa;
      else perguntas = forms[course].normal;
    } else {
      console.log(course);
    }
  } else if (data.nivel === 2) {
    perguntas = forms2[tipo];
  } else if (data.nivel === 3) {
    perguntas = forms3[tipo];
  }
  data.personByPerson.name =
    data.personByPerson.first_name + " " + data.personByPerson.last_name;
  if (list) return <ApplicationList />;
  if ((!consultor && tipo !== "auto") || !perguntas || !semestre)
    return <h2>Erro: Faltam parâmetros nos dados.</h2>;
  var aprov = aprovacao[course];
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <IconButton
          onClick={() => {
            setList(true);
            //data.refetch();
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        {store.admin && (
          <FormControlLabel
            control={
              <Switch
                checked={!readOnly}
                onChange={(e) => setReadOnly(!readOnly)}
                value="editar"
                color="primary"
              />
            }
            label="Editar"
          />
        )}
      </div>
      <Paper
        elevation={2}
        style={{ padding: 30, background: "#e0c9a6" }}
        id="paper"
      >
        <Formik
          onSubmit={async (values, actions) => {
            var newValues = JSON.parse(JSON.stringify(values));
            let person = newValues.personByPerson;
            delete newValues.personByPerson;
            let personId = person.id;
            delete person.id;
            let names = person.name.split(" ");
            person.first_name = names.shift();
            person.last_name = names.join(" ");
            delete person.name;
            delete person.data;
            let to = newValues.consultant.email;
            if (!to) to = newValues.consultant.personByPerson.email;
            delete newValues.consultant;
            delete person.__typename;
            delete newValues.__typename;
            let id = newValues.id;
            delete newValues.id;
            delete newValues.created_at;
            delete newValues.paymentPlanByPaymentPlan;
            let oldBolsa = data.bolsa_aprovada;
            try {
              await updatePerson({ variables: { _set: person, id: personId } });
              await update({ variables: { id, _set: newValues } });
              showMsg("Registrado.");
              //enviar email para a consultora:
              if (newValues.bolsa_aprovada !== oldBolsa) {
                let body = {
                  to,
                  //subject: "Painel do Aluno - Aprovação de Bolsa",
                  text:
                    "Alteração feita no formulário de " +
                    person.first_name +
                    " " +
                    person.last_name +
                    ". Bolsa: " +
                    values.bolsa_aprovada,
                };
                await axios.post(
                  "/.netlify/functions/mailnotification",
                  body,
                  config
                );
              }
              await refetch();
              await refetchPlan();
            } catch (e) {
              showMsg(JSON.stringify(e));
            }
            actions.setSubmitting(false);
          }}
          initialValues={data}
          render={({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <h1>
                Respostas ao formulário de inscrição {course} {semestre} nível{" "}
                {data.nivel}:
              </h1>
              <h2>
                {
                  (cursoDescricao = bolsa
                    ? "Bolsa - " + cursoDescricao
                    : cursoDescricao)
                }
              </h2>
              {data.nivel > 1 && <h3>Última turma: {data.ultimaTurma}</h3>}
              <h3>Consultor(a): {consultor}</h3>
              {Object.keys(perguntas).map((section, i) => {
                return (
                  <>
                    <div className="section">
                      <h2
                        className="section-title"
                        id={"title" + i}
                        onClick={() => {
                          document
                            .getElementById("title" + i)
                            .classList.toggle("active");

                          let content = document.getElementById("body" + i);
                          if (content.style.display === "none") {
                            content.style.display = "block";
                          } else {
                            content.style.display = "none";
                          }
                        }}
                      >
                        {section}
                      </h2>
                      <div className="section-body" id={"body" + i}>
                        {perguntas[section].map((k) => {
                          return (
                            <p>
                              {questions[k].fieldtype !== "file" ? (
                                <Field
                                  type={questions[k].fieldtype}
                                  label={
                                    windowSize > 1100 ? questions[k].text : ""
                                  }
                                  helperText={
                                    windowSize <= 1100 ? questions[k].text : ""
                                  }
                                  name={
                                    section === "Dados pessoais"
                                      ? "personByPerson." + k
                                      : k
                                  }
                                  fullWidth={true}
                                  component={TextField}
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ readOnly: readOnly }}
                                  variant="outlined"
                                  onInput={
                                    questions[k].onInput
                                      ? (e) =>
                                          questions[k].onInput(e, setFieldValue)
                                      : ""
                                  }
                                  select={questions[k].select}
                                  SelectProps={{ native: true }}
                                  validate={questions[k].validate}
                                >
                                  {questions[k].options &&
                                    questions[k].options.map((o) => {
                                      return (
                                        <option value={o} className="p5">
                                          {o}
                                        </option>
                                      );
                                    })}
                                </Field>
                              ) : values[k] ? (
                                // eslint-disable-next-line react/jsx-no-target-blank
                                <a
                                  href={
                                    "https://drive.google.com/uc?id=" +
                                    values[k]
                                  }
                                  target="_blank"
                                >
                                  {questions[k].text}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="section">
                <h2
                  className="section-title"
                  style={{ background: "darkgreen" }}
                >
                  Aprovação de Bolsa
                </h2>
                {bolsa && (
                  <div>
                    <Field
                      select
                      style={{ margin: "10px", minWidth: "200px" }}
                      label="Bolsa aprovada"
                      name="bolsa_aprovada"
                      component={TextField}
                      variant="outlined"
                      inputProps={{ readOnly: !store.admin }}
                    >
                      {aprov.map((text) => {
                        return <option value={text}>{text}</option>;
                      })}
                    </Field>
                    <Field
                      type="date"
                      style={{ margin: "10px" }}
                      InputLabelProps={{ shrink: true }}
                      label="Data"
                      name="data_aprovacao"
                      component={TextField}
                      variant="outlined"
                      inputProps={{ readOnly: !store.admin }}
                    />
                    <Field
                      type="text"
                      style={{ margin: "10px" }}
                      label="Observação"
                      name="bolsa_obs"
                      fullWidth
                      component={TextField}
                      variant="outlined"
                      inputProps={{ readOnly: !store.admin }}
                    />
                  </div>
                )}
                <Field
                  type="text"
                  style={{ margin: "10px" }}
                  label="Status da inscrição"
                  name="status"
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  component={TextField}
                  variant="outlined"
                  inputProps={{ readOnly: !store.admin }}
                >
                  <option value={null}></option>
                  <option value="matriculado">matriculado</option>
                  <option value="desistiu">desistiu</option>
                  <option value="cancelado">cancelado</option>
                </Field>
                <Field
                  type="checkbox"
                  style={{ margin: "10px" }}
                  InputLabelProps={{ shrink: true }}
                  label="Aprovado no Serasa??"
                  name="serasa_aprovado"
                  component={TextField}
                  variant="outlined"
                  fullWidth={true}
                  inputProps={{
                    readOnly: !store.admin,
                    defaultChecked: values.serasa_aprovado,
                  }}
                />
                {store.admin && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disable={isSubmitting}
                  >
                    Salvar informações
                  </Button>
                )}
              </div>
            </Form>
          )}
        />
        <Formik
          onSubmit={async (values, actions) => {
            let criar_contrato = values.criar_contrato;
            delete values.criar_contrato;
            let criar_fatura = values.criar_fatura;
            delete values.criar_fatura;
            let _set = {};
            let msg = "";
            if (criar_contrato) {
              let res = await contract(
                { ...values, ...data, ...data.personByPerson },
                course
              );
              msg += JSON.stringify(res) + "\n";
              if (res.id) _set.contrato = res.id;
              let object = {
                valor_parcela: values.valor_parcela,
                valor_total: values.valor_total,
                parcelas: values.matricula + values.parcelas,
                dia_vencimento: values.venc2.split("-")[2],
              };
              let payment_plan = await insertPaymentPlan({
                variables: { object },
              });
              if (payment_plan.data)
                _set.payment_plan =
                  payment_plan.data.insert_payment_plan_one.id;
            }
            if (criar_fatura) {
              let res = await invoice(
                { ...values, ...data, ...data.personByPerson },
                course,
                props.id
              );
              msg += JSON.stringify(res);
              if (res.id) {
                _set.fatura_id = res.id;
                _set.fatura_status = "pending";
              }
            }
            showMsg(msg);
            try {
              if (Object.keys(_set).length > 0) {
                let variables = {
                  id: data.id,
                  _set,
                };
                await update({ variables });
                refetch();
              }
            } catch (e) {
              alert(JSON.stringify(e));
            }
            actions.setSubmitting(false);
          }}
          initialValues={plan}
          render={({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <div className="section">
                <h2
                  className="section-title"
                  style={{ background: "darkgreen" }}
                >
                  Contrato
                </h2>
                <Field
                  type="number"
                  style={{ margin: "10px", minWidth: "100px" }}
                  label="Parcelas de matrícula"
                  name="matricula"
                  component={TextField}
                  variant="outlined"
                  onChange={(e) => {
                    let val = parseInt(e.target.value);
                    setFieldValue("matricula", val);
                    setFieldValue(
                      "valor_total",
                      (val + values.parcelas) * values.valor_parcela
                    );
                  }}
                  validate={(value) => {
                    if (!Number.isInteger(value))
                      return "Número deve ser valor inteiro";
                  }}
                />
                <Field
                  type="number"
                  style={{ margin: "10px", minWidth: "100px" }}
                  label="Parcelas"
                  name="parcelas"
                  component={TextField}
                  variant="outlined"
                  onChange={(e) => {
                    let val = parseInt(e.target.value);
                    setFieldValue("parcelas", val);
                    setFieldValue(
                      "valor_total",
                      (val + values.matricula) * values.valor_parcela
                    );
                  }}
                  validate={(value) => {
                    if (!Number.isInteger(value))
                      return "Número deve ser valor inteiro";
                  }}
                />
                <Field
                  type="number"
                  style={{ margin: "10px", minWidth: "100px" }}
                  InputLabelProps={{ shrink: true }}
                  label="Valor da parcela (R$)"
                  name="valor_parcela"
                  component={TextField}
                  variant="outlined"
                  onChange={(e) => {
                    let val = parseFloat(e.target.value);
                    setFieldValue("valor_parcela", val);
                    setFieldValue(
                      "valor_total",
                      (values.matricula + values.parcelas) * val
                    );
                  }}
                  validate={(value) => {
                    if (!Number.isInteger(value))
                      return "Número deve ser valor inteiro";
                  }}
                />
                <p />
                <Field
                  type="number"
                  style={{ margin: "10px", minWidth: "100px" }}
                  InputLabelProps={{ shrink: true }}
                  label="Valor total (R$)"
                  name="valor_total"
                  component={TextField}
                  variant="outlined"
                  validate={(value) => {
                    if (!Number.isInteger(value))
                      return "Número deve ser valor inteiro";
                  }}
                />
                <Field
                  type="date"
                  style={{ margin: "10px", minWidth: "100px" }}
                  InputLabelProps={{ shrink: true }}
                  label="Primeiro vencimento"
                  name="venc1"
                  component={TextField}
                  variant="outlined"
                />
                <Field
                  type="date"
                  style={{ margin: "10px", minWidth: "100px" }}
                  InputLabelProps={{ shrink: true }}
                  label="Segundo vencimento"
                  name="venc2"
                  component={TextField}
                  variant="outlined"
                />
                <p />
                {(data.fatura_id || data.contrato) && (
                  <div
                    className="m10 p5"
                    style={{ border: "groove", borderRadius: 10 }}
                  >
                    {data.contrato ? `Contrato: ${data.contrato}` : ""}
                    <p />
                    {data.fatura_id ? (
                      <>
                        Fatura da matrícula: {data.fatura_id} - status:{" "}
                        {data.fatura_status}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disable={isSubmitting}
                >
                  Criar
                </Button>
                <Field
                  type="checkbox"
                  style={{ margin: "10px" }}
                  InputLabelProps={{ shrink: true }}
                  label="Contrato"
                  name="criar_contrato"
                  component={TextField}
                  variant="outlined"
                  fullWidth={true}
                  inputProps={{ defaultChecked: values.criar_contrato }}
                />
                <Field
                  type="checkbox"
                  style={{ margin: "10px" }}
                  InputLabelProps={{ shrink: true }}
                  label="Fatura"
                  name="criar_fatura"
                  component={TextField}
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{ defaultChecked: values.criar_fatura }}
                />
              </div>
            </Form>
          )}
        />
        {data.paymentPlanByPaymentPlan ? (
          <>
            <div className="section">
              <IconButton
                onClick={() => {
                  props.history.push(
                    "/editDB/payment_plan/" + data.payment_plan
                  );
                }}
                style={{ float: "right" }}
              >
                <EditIcon />
              </IconButton>
              <p />
              <h3>
                Plano de pagamento: {data.paymentPlanByPaymentPlan.frequencia}
              </h3>
              <h3>
                Total: R${data.paymentPlanByPaymentPlan.valor_total} <br />
                {data.paymentPlanByPaymentPlan.parcelas}x de R$
                {data.paymentPlanByPaymentPlan.valor_parcela} <br />
                dia de vencimento:{" "}
                {data.paymentPlanByPaymentPlan.dia_vencimento} <br />
                {data.paymentPlanByPaymentPlan.obs}
              </h3>
            </div>
          </>
        ) : (
          ""
        )}
      </Paper>
      <SavedSnack
        open={snack.open}
        setOpen={(open) => setSnack({ ...snack, open })}
        msg={snack.msg}
      />
    </>
  );
};
export default Apply;

const aprovacao = {
  "Especialização MF": [
    "Aprovada (AD) v.1",
    "Aprovada (AL) v.1",
    "Aprovada (AD) v.2",
    "Aprovada (AL) v.2",
    "Recusada",
  ],
  "Formação MF": [
    "Aprovada (AD) v.1",
    "Aprovada (AL) v.1",
    "Aprovada (AD) v.2",
    "Aprovada (AL) v.2",
    "Recusada",
  ],
  "Especialização ACT": ["Aprovada v.1", "Aprovada v.2", "Recusada"],
  "Formação ACT": ["Aprovada v.1", "Aprovada v.2", "Recusada"],
  "Especialização FAP": ["Aprovada v.1", "Aprovada v.2", "Recusada"],
  "Formação FAP": ["Aprovada v.1", "Aprovada v.2", "Recusada"],
  "Módulo 1 RFT": ["Aprovada v.1", "Recusada"],
  "Curso Completo RFT": ["Aprovada v.1", "Recusada"],
  "Básico - ACT Crianças e Adolescentes": ["Aprovada v.1", "Recusada"],
  "Completo - ACT Crianças e Adolescentes": ["Aprovada v.1", "Recusada"],
  "Formação em Conceituação de caso na FAP": ["Aprovada v.1", "Aprovada v.2", "Recusada"],
};

const lotes = {
  "Especialização ACT": {
    1: "2024-08-16",
    2: "2024-09-16",
    3: "2024-09-30",
  },
  "Formação ACT": {
    1: "2024-08-16",
    2: "2024-09-16",
    3: "2024-09-30",
  },
  "Especialização MF": {
    1: "2022-09-07",
    2: "2022-09-17",
    3: "2022-09-23",
  },
  "Formação MF": {
    1: "2022-09-07",
    2: "2022-09-17",
    3: "2022-09-23",
  },
  "Especialização FAP": {
    1: "2024-08-11",
    2: "2024-08-25",
    3: "2024-09-01",
  },
  "Formação FAP": {
    1: "2024-08-16",
    2: "2024-09-16",
    3: "2024-09-30",
  },
  "Módulo 1 RFT": {
    1: "2023-04-14",
    2: "2023-04-26",
    3: "2023-05-05",
  },
  "Curso Completo RFT": {
    1: "2023-04-14",
    2: "2023-04-28",
    3: "2023-05-05",
  },
  "Completo - ACT Crianças e Adolescentes": {
    1: "2023-06-25",
    2: "2023-07-24",
    3: "2023-08-24",
  },
  "Básico - ACT Crianças e Adolescentes": {
    1: "2023-06-25",
    2: "2023-07-24",
    3: "2023-08-24",
  },
  "Formação em Conceituação de caso na FAP": {
    1: "2024-08-11",
    2: "2024-08-25",
    3: "2024-09-01",
  },
  "Fundamentos da FAP": {
    1: "2024-08-16",
    2: "2024-09-16",
    3: "2024-09-30",
  },
};

function getLote(course, date) {
  var now = date.split("T")[0];
  if (lotes[course][0] >= now) return 0;
  else if (lotes[course][1] >= now) return 1;
  else if (lotes[course][2] >= now) return 2;
  else return 3;
}

const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("auth0:id_token"),
  },
};

const contract = async (values, course) => {
  values.primeiro_vencimento =
    values.venc1.split("-")[2] +
    "/" +
    values.venc1.split("-")[1] +
    "/" +
    values.venc1.split("-")[0];
  if (values.venc2)
    values.segundo_vencimento =
      values.venc2.split("-")[2] +
      "/" +
      values.venc2.split("-")[1] +
      "/" +
      values.venc2.split("-")[0];

  switch (course) {
    case "Especialização ACT":
      var res = await contract_act(values);
      break;
    case "Formação ACT":
      var res = await contract_act(values);
      break;
    case "Especialização MF":
      var res = await contract_mf(values);
      break;
    case "Formação MF":
      var res = await contract_mf(values);
      break;
    case "Especialização FAP":
      var res = await contract_fap(values);
      break;
    case "Formação FAP":
      var res = await contract_fap(values);
      break;
    case "Curso Completo RFT":
      var res = await contract_rft(values);
      break;
    case "Módulo 1 RFT":
      var res = await contract_rft(values);
      break;
    case "Completo - ACT Crianças e Adolescentes":
      var res = await contract_actca(values);
      break;
    case "Básico - ACT Crianças e Adolescentes":
      var res = await contract_actca(values);
      break;
    default:
      return "Erro: curso não habilitado";
  }
  return res;
};

const contract_act = async (values) => {
  try {
    let { data } = await axios.post(
      "/.netlify/functions/contrato_act",
      values,
      config
    );
    return data;
  } catch (e) {
    return JSON.stringify(e.response.data);
  }
};

const contract_fap = async (values) => {
  try {
    let { data } = await axios.post(
      "/.netlify/functions/contrato_fap",
      values,
      config
    );
    return data;
  } catch (e) {
    return JSON.stringify(e.response.data);
  }
};

const contract_rft = async (values) => {
  try {
    let { data } = await axios.post(
      "/.netlify/functions/contrato_rft",
      values,
      config
    );
    return data;
  } catch (e) {
    return JSON.stringify(e.response.data);
  }
};

const contract_actca = async (values) => {
  try {
    let { data } = await axios.post(
      "/.netlify/functions/contrato_actca",
      values,
      config
    );
    return data;
  } catch (e) {
    return JSON.stringify(e.response.data);
  }
};

const contract_mf = async (values) => {
  if (!checkSerasa(values)) return "Necessita aprovação no Serasa";
  try {
    let { data } = await axios.post(
      "/.netlify/functions/contrato_mf",
      values,
      config
    );
    return data;
  } catch (e) {
    return JSON.stringify(e.response.data);
  }
};

const invoice = async (values, course, id) => {
  let person = values.personByPerson;
  let personId = person.id;
  let address = person.address1 + " " + person.address2;
  let num = person.n_address;
  let body = {
    application_id: id,
    personId,
    email: person.email,
    name: person.name,
    cpf_cnpj: person.cpf,
    zip_code: person.postal_code.match(/[0-9]+/g).join(""),
    address_street: address.replace(/,* *[0-9]+.*/, ""),
    number: num,
    district: person.district,
  };
  body.due_date = values.venc1;
  let valor = values.valor_parcela;
  if (course === "Especialização MF" || course === "Formação MF") {
    if (!checkSerasa(values)) return "Necessita aprovação no Serasa";
    if (course.includes("Especialização")) {
      body.payable_with = ["pix", "bank_slip", "credit_card"];
      body.max_installments_value = values.parcelas <= 3 ? 3 : 1;
      var description = `Especialização MF N${values.nivel} - ${values.semestre}`;
      description +=
        values.parcelas <= 3
          ? ` (parc 1/${values.parcelas})`
          : " - matrícula P" + values.parcelas;
    } else {
      let pay = [];
      if (values.forma_pagamento_for.includes("cartão de crédito"))
        pay.push("credit_card");
      if (values.forma_pagamento_for.includes("boleto"))
        pay = pay.concat[("bank_slip", "pix")];
      body.payable_with = pay;
      body.max_installments_value = 1;
      var description = `Formação MF N${values.nivel} ${values.semestre} (parc 1/${values.parcelas})`;
      if (values.parcelas === 12) {
        //cartão 12x
        body.max_installments_value = 12;
        valor = values.valor_total;
        description = `Formação MF N${values.nivel} ${values.semestre} (cartão 12x)`;
      }
    }
  } else if (
    course === "Especialização ACT" ||
    course === "Formação ACT" ||
    course === "Formação em Conceituação de caso na FAP"
  ) {
    body.payable_with = ["pix", "bank_slip", "credit_card"];
    body.max_installments_value = 1;
    var description =
      course === "Formação em Conceituação de caso na FAP"
        ? "Formação em Conceituação de caso na FAP"
        : "Pós-graduação em Terapia de Aceitação e Compromisso";
    if (values.parcelas === 3) {
      description += " 1/3";
    } else {
      description += " - matrícula + " + values.parcelas;
    }
  } else if (course === "Especialização FAP" || course === "Formação FAP") {
    body.payable_with = ["pix", "bank_slip", "credit_card"];
    if (values.parcelas === 12) {
      //cartão 12x
      body.max_installments_value = 12;
      valor = values.valor_total;
    }
    var description = "Pós-graduação em Psicoterapia Analítica Funcional";
    if (values.parcelas === 3) {
      description += " 1/3";
    } else {
      description += " - matrícula + " + values.parcelas;
    }
  } else if (course === "Curso Completo RFT" || course === "Módulo 1 RFT") {
    body.payable_with = ["pix", "bank_slip", "credit_card"];
    if (values.parcelas === 9) {
      valor = values.valor_total;
    }
    var description = "Teoria das Molduras Relacionais";
    if (values.parcelas === 1) {
      description += " - matrícula - À vista";
    }
    if (values.parcelas === 6) {
      description += " - matrícula + " + values.parcelas;
    }
    if (values.parcelas === 18) {
      description += " - matrícula + " + values.parcelas;
    }
    if (values.parcelas === 12) {
      description += " - matrícula + " + values.parcelas;
    }
  } else if (
    course === "Básico - ACT Crianças e Adolescentes" ||
    course === "Completo - ACT Crianças e Adolescentes"
  ) {
    body.payable_with = ["pix", "bank_slip", "credit_card"];
    var description = "A prática clínica da ACT com crianças e adolescentes";
    if (values.parcelas === 5) {
      description += " - matrícula + " + values.parcelas;
    }
    if (values.parcelas === 11) {
      description += " - matrícula + " + values.parcelas;
    }
    if (values.parcelas === 17) {
      description += " - matrícula + " + values.parcelas;
    }
    if (values.parcelas === 3) {
      description += " - matrícula + " + values.parcelas;
    }
    if (values.parcelas === 8) {
      description += " - matrícula + " + values.parcelas;
    }
  }
  body.items = [
    {
      description: description, //editar descrição
      quantity: 1,
      price_cents: valor * 100,
    },
  ];

  try {
    let { data } = await axios.post(
      "/.netlify/functions/invoicesAsaas?action=create&turma=" + course,
      body,
      config
    );
    if (typeof data === "string") {
      data = JSON.parse(data);
    }

    const responseData = data.data || data;
    if (responseData.id) {
      return {
        msg: "Fatura criada",
        id: responseData.id,
        link: responseData.invoiceUrl,
      };
    } else {
      return "Erro ao criar fatura: " + JSON.stringify(responseData);
    }
  } catch (e) {
    return (
      "Erro ao processar fatura: " +
      JSON.stringify(e.response ? e.response.data : e.message)
    );
  }
};

const descontoNNordeste = (plan, estado) => {
  //norte e nordeste
  let estados = [
    "AM",
    "RR",
    "AP",
    "PA",
    "TO",
    "RO",
    "AC",
    "MA",
    "PI",
    "CE",
    "RN",
    "PE",
    "PB",
    "SE",
    "AL",
    "BA",
  ];
  if (estados.includes(estado)) {
    plan.valor_parcela = Math.ceil(plan.valor_parcela * 0.9);
    plan.valor_total = plan.valor_parcela * (plan.matricula + plan.parcelas);
  }
  return plan;
};

const checkSerasa = (values) => {
  if (values.tipo === "especializacao") {
    if (values.parcelas === 24) return values.serasa_aprovado;
  } else {
    //formação
    let month = new Date().getMonth();
    if (
      values.forma_pagamento_for.includes("boleto") &&
      (month % 6) + values.parcelas >= 6
    )
      return values.serasa_aprovado;
  }
  return true;
};

const descontoACBS = (plan, associado) => {
  if (associado === "Sim") {
    plan.valor_parcela = Math.ceil(plan.valor_parcela * 0.9);
    plan.valor_total = plan.valor_parcela * (plan.matricula + plan.parcelas);
  }
  return plan;
};

const descontoCupom = (plan, percent) => {
  plan.valor_parcela = Math.ceil(plan.valor_parcela * (1 - percent / 100));
  plan.valor_total = plan.valor_parcela * (plan.matricula + plan.parcelas);
  return plan;
};
