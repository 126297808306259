import { Button } from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "react-apollo-hooks";
import React from "react";

const mutation = gql`
  mutation MyMutation($objects: [material_insert_input!]!) {
    insert_material(objects: $objects) {
      affected_rows
    }
  }
`;

// eslint-disable-next-line no-unused-vars
const query = gql`
  query MyQuery {
    huggy_message(
      distinct_on: chat
      where: {
        send_at: { _gte: "2021-08-01", _lt: "2021-09-01" }
        deleted_at: { _is_null: true }
      }
    ) {
      huggy_chat {
        id
        customer_phone
      }
      send_at
    }
  }
`;

const Teste = () => {
  const [update] = useMutation(mutation);
  var list = material.split("\n");
  var objects = [];
  list.forEach((line) => {
    if (line) {
      let title = line[0] === "#";
      while (line[0] === "#" || line[0] === " ")
        line = line.substring(1, line.length);
      let text = line;
      let link = null;
      if (line.match(/\[.+\]\(.+\)/g)) {
        link = line
          .match(/\[.+\]\(.+\)/)[0]
          .split("(")[1]
          .split(")")[0];
        text = line
          .replace(`(${link})`, "")
          .replace("[", "")
          .replace("]", "");
      }
      objects.push({ text, link, title, curso: "FAP" });
    }
  });
  objects.forEach((line, i) => {
    line.order = i;
  });
  console.log(objects);
  return (
    <>
      {JSON.stringify(objects)}
      <Button
        onClick={() => {
          update({ variables: { objects } });
        }}
      >
        Enviar
      </Button>
    </>
  );
};
export default Teste;

var material = `# Lista de Materiais de Suporte - FAP

Novos materiais serão acrescentados à esta lista durante o curso. Se você está visualizando a versão em PDF desta lista, os links abaixo são clicáveis (visualize em um computador para acessar os links).

## Aula 2. Epistemologia

[Exercício](https://drive.google.com/file/d/1d7gZIGvIXrXLiyDs1poKkih9W2MHRmMf/view?usp=sharing)

[Slides](https://drive.google.com/file/d/12qJae5ikTYElT3dCkITbSvtpO7Vlkb8e/view?usp=sharing)

Leituras indicadas:

Skinner, B. F. (1981). Seleção por conseqüências. Revista Brasileira de Terapia Comportamental e Cognitiva, 9(1), 129–137.
 
[Hayes, S. C., Barnes-Holmes, D., &amp; Wilson, K. G. (2012).Contextual Behavioral Science: Creating a science more adequate to the challenge of the human condition. Journal of Contextual Behavioral Science, 1(1), 1–16.](https://doi.org/10.1016/j.jcbs.2012.09.004)

Leituras opcionais:

Cap1. Tsai, M., Kohlenberg, R. J., Kanter, J. W., Kohlenberg, B., Follette, W. C., &amp; Callaghan, G. M. (2008). A Guide to Functional Analytic Psychotherapy: Awareness, Courage, Love, and Behaviorism. Springer Science &amp; Business Media.

Cap 1. Kohlenberg, R. J., &amp; Tsai, M. (1991). Functional Analytic Psychotherapy: Creating Intense and Curative Therapeutic Relationships. Springer. Cap. 2 e 3: Holman, G. I., Kanter, J., Tsai, M., Kohlenberg, R. J., &amp; Hayes, S. C. (2017). Functional Analytic Psychotherapy Made Simple (1 o ed). New Harbinger Publications.

[Cap. 2 e 3: Hayes, S. C., &amp; Hofmann, S. G. (2018). Process-Based CBT: The Science and Core Clinical Competencies of Cognitive Behavioral Therapy. New Harbinger Publications](https://www.newharbinger.com/process-based-cbt)

Leituras de aprofundamento:

Catania, A. C. (1999). Aprendizagem: Comportamento, Linguagem e Cognição. Porto Alegre: Artmed.

Baum, W. M. (2006). Compreender o Behaviorismo: Comportamento, Cultura e Evolução. Porto Alegre: Artmed.

Moreira, M. B., &amp; Medeiros, C. A. de. (2018). Princípios Básicos de Análise do Comportamento (2 o ed). Porto Alegre / RS: Artmed.

Skinner, B. F. (1974). Sobre o Behaviorismo. São Paulo: Cultrix.

Hübner, M. M. C., &amp; Moreira, M. B. (Orgs.). (2012). Fundamentos de Psicologia TEMAS CLÁSSICOS DA PSICOLOGIA SOB A ÓTICA DA ANÁLISE DO COMPORTAMENTO.

Série Behaviorismos de Diego Zilio e Kester Carrara

## Aula 3. Princípios básicos do comportamento

[Slides](https://drive.google.com/file/d/1ADtwqJHxN7-aIavy0tgjedmxxWqBOJSY/view?usp=sharing)

Leituras indicadas:

[Costa, S. E. G. de C., & Marinho, M. L. (2002). Um modelo de apresentação de análise funcionais do comportamento. Estudos de Psicologia (Campinas), 19, 43–54.](https://doi.org/10.1590/S0103-166X2002000300005)

[Cap 1: de-Farias, A. K. C. R., Fonseca, & Nery, L. B. (Orgs.). (2018). Teoria e Formulação de Casos em Análise Comportamental Clínica. ARTMED.](http://loja.grupoa.com.br/livros/psicologia-cognitiva-comportamental-e-neurociencias/teoria-e-formulacao-de-casos-em-analise-comportamental-clinica/9788582714720)

Leituras opcionais

[Haworth, K., Kanter, J. W., Tsai, M., Kuczynski, A. M., Rae, J. R., & Kohlenberg, R. J. (2015). Reinforcement matters: A preliminary, laboratory-based component-process analysis of Functional Analytic Psychotherapy’s model of social connection. Journal of Contextual Behavioral Science, 4(4), 281–291.](https://doi.org/10.1016/j.jcbs.2015.08.003)

Cap. 3: Holman, G. I., Kanter, J., Tsai, M., Kohlenberg, R. J., & Hayes, S. C. (2017). Functional Analytic Psychotherapy Made Simple (1o ed). New Harbinger Publications.

Leituras de aprofundamento

Baum, W. M. (2006). Compreender o Behaviorismo: Comportamento, Cultura e Evolução. Artmed.

Catania, A. C. (1999). Aprendizagem: Comportamento, Linguagem e Cognição. Artmed.

Moreira, M. B., & Medeiros, C. A. de. (2018). Princípios Básicos de Análise do Comportamento (2o ed). Artmed.

Ramnerö, J., & Törneke, N. (2008). The ABCs of Human Behavior: Behavioral Principles for the Practicing Clinician (Context Press; 1 edition). New Harbinger Publications.

Skinner, B. F. (1953). Ciência e Comportamento Humano. Martins Fontes.

## Aula 4

Bibliografia Básica:

Kohlenberg, R., Tsai, M., Kanter, J. (2011). O que é Psicoterapia Analítica Funcional (FAP)?. In: Tsai, M., Kohlenberg, R. J., Kanter, J. W., Kohlenberg, B., Follete, W. C., &amp; Callaghan, G. M. (2011). Um guia para a Psicoterapia Analítica Functional (FAP): consciência, coragem, amor e behaviorismo (F. Conte, &amp; M. Z. Brandão, trads.). Santo André, SP: ESETEc (Obra publicada originalmente em 2009).

Bibliografia complementar:

Capítulos 1, 2 e 7: Kohlenberg, R. J., &amp; Tsai, M. (2001). Psicoterapia Analítica Funcional (F. Conte, M. Delliti, M. Z. Brandão, P. R. Derdyk, R. R. Kerbauy, R. C. Wielenska, R. A. Banaco, R. Starling, trads.). Santo André, SP: ESETEc (Obra publicada originalmente em 1991).

Cap. 5: Holman, G. I., Kanter, J., Tsai, M., Kohlenberg, R. J., &amp; Hayes, S. C. (2017). Functional Analytic Psychotherapy Made Simple (1 o ed). New Harbinger Publications.

## Aula 5

Bibliografia básica

Kanter, J., Weeks, C., Bonow, J., Landes, S., Callaghan, G., Follete, W. (2011). Avaliação e formulação de caso (pp. 76-81). In: Tsai, M., Kohlenberg, R. J., Kanter, J. W., Kohlenberg, B., Follete, W. C., &amp; Callaghan, G. M. (2011). Um guia para a Psicoterapia Analítica Functional (FAP): consciência, coragem, amor e behaviorismo (F. Conte, &amp; M. Z. Brandão, trads.). Santo André, SP: ESETEc (Obra publicada originalmente em 2009).

Bibliografia complementar

[Hamza, C. A., Ewing, L., Heath, N. L., &amp; Goldstein, A. L. (2021). When social isolation is nothing new: A longitudinal study on psychological distress during COVID-19 among university students with and without preexisting mental health concerns. Canadian Psychology/Psychologie canadienne, 62(1), 20-30.](https://drive.google.com/file/d/1B-osoFtmhvaYDU-7fYlt6F7mN2RpKnle/view?usp=sharing)

[N. Oexle, L. Sheehan. (2020). Perceived social support and mental health after suicide loss. Crisis, 41, 65-69. 10.1027/0227-5910/a000594](https://drive.google.com/file/d/1IeJFoz7c1zUtIsVYTTB-JgRAd4ZxyS2a/view?usp=sharing)
`;
