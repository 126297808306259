export const textLabels = {
  body: {
    noMatch: "Desculpe, não achamos nenhuma entrada correspondente",
    toolTip: "Sort"
  },
  pagination: {
    next: "Próxima Página",
    previous: "Página Anterior",
    rowsPerPage: "Linhas por página",
    displayRows: "de"
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Baixar CSV",
    print: "Imprimir",
    viewColumns: "Ver Colunas",
    filterTable: "Filtrar Tabela"
  },
  filter: {
    all: "Todos",
    title: "FILTROS",
    reset: "RESETAR"
  },
  viewColumns: {
    title: "Mostrar Colunas",
    titleAria: "Mostar/Esconder Colunas"
  },
  selectedRows: {
    text: "rows(s) selected",
    delete: "Delete",
    deleteAria: "Delete Selected Rows"
  }
};
