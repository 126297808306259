/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import "../../css/Apply.css";
import { useQuery } from "react-apollo-hooks";

const PixPage = ({ id }) => {
  const { data, error, loading, refetch } = useQuery(
    gql`
      query MyQuery($id: Int!) {
        application_by_pk(id: $id) {
          fatura_qrcode
        }
      }
    `,
    { variables: { id } }
  );
  if (error) return <p>Erro: {JSON.stringify(error.message)}</p>;
  if (loading) return <p>Carregando...</p>;
  const qrcode = data.application_by_pk
    ? data.application_by_pk.fatura_qrcode
    : "";
  console.log(qrcode);
  return (
    <>
      <Paper>
        <div className="p30">
          <h1>
            Fatura enviada para criação. Faça o pagamento através do QR code
            abaixo:
          </h1>
          <h2>
            Após o sistema identificar o pagamento, o processo continuará
            automaticamente.
          </h2>
        </div>
        <div className="center">
          {qrcode ? (
            qrcode.includes("http") ? (
              <img src={qrcode} />
            ) : (
              <img src={"data:image/png;base64," + qrcode} />
            )
          ) : (
            "Carregando..."
          )}
        </div>
        {!qrcode &&
          setTimeout(() => {
            refetch();
          }, 5000)}
      </Paper>
    </>
  );
};

export default PixPage;
