import gql from "graphql-tag";

export const GET = gql`
query getPayment_plan($id: Int, $limit: Int) {
  payment_plan(where: {id: {_eq: $id},deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
    id
    parcelas
    valor_parcela
    valor_total
    regular_plan
    frequencia
    dia_vencimento
    obs         
  }
}
`;

export const INSERT = gql`
  mutation newPayment_plan($objects: [payment_plan_insert_input!]!) {
    insert_payment_plan(objects: $objects) {
      affected_rows
    }
  }
`

export const EDIT = gql`
  mutation updatePayment_plan($_set: payment_plan_set_input, $id: Int){
    update_payment_plan(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`
export const fieldtypes = {
  regular_plan: "checkbox"
}