import axios from "axios";
import React, { useState } from "react";
import Dropzone from "react-dropzone";

const UploadDocuments = ({ field, oldFile, onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  // ID da pasta de inscrição
  const FOLDER_ID = "1GWnvU6ZpaStlsTEHSyLaFdUdfpA_Cttt";
  const acceptedExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "pdf",
    "doc",
    "docx",
  ];

  const onDrop = (files) => {
    document.getElementById("msg").innerHTML = "";
    const file = files[0];
    if (file) {
      const extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      const size = file.size;
      if (!acceptedExtensions.includes(extension)) {
        document.getElementById(
          "msg"
        ).innerHTML = `Tipo de arquivo não permitido: ${extension}`;
      } else if (size > 20 * 1024 * 1024) {
        document.getElementById("msg").innerHTML =
          "Arquivo maior que o limite de 20MB";
      } else {
        setSelectedFile(file);
        document.getElementById(
          "msg"
        ).innerHTML = `Arquivo selecionado: ${file.name}`;
      }
    }
  };

  const onFileUploadHandler = async () => {
    if (!selectedFile) {
      document.getElementById(`upload${field}`).innerHTML =
        "Nenhum arquivo selecionado.";
      return;
    }

    document.getElementById(`upload${field}`).innerHTML = "Enviando arquivo...";
    const file = selectedFile;
    let token;
    try {
      const response = await axios.get("/.netlify/functions/drive?action=send");
      token = response.data;
    } catch (error) {
      document.getElementById(`upload${field}`).innerHTML =
        "Erro ao obter o token de autenticação.";
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const uploadResponse = await axios.post(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=media",
        file,
        config
      );
      const id = uploadResponse.data.id;
      const meta = {
        name: file.name,
      };

      await axios.patch(
        `https://www.googleapis.com/drive/v3/files/${id}?addParents=${FOLDER_ID}`,
        meta,
        config
      );
      document.getElementById(`upload${field}`).innerHTML = "Arquivo enviado.";
      await axios.post(
        `https://www.googleapis.com/drive/v3/files/${id}/permissions`,
        { role: "reader", type: "anyone" },
        config
      );

      if (onFileUpload) {
        onFileUpload(id);
      }
    } catch (error) {
      document.getElementById(`upload${field}`).innerHTML =
        "Erro ao enviar o arquivo.";
    }
  };

  return (
    <div
      id={`upload${field}`}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Dropzone
        onDrop={onDrop}
        maxFiles={1}
        accept={acceptedExtensions.map((ext) => `.${ext}`).join(", ")}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <input {...getInputProps()} />
            <button
              style={{
                backgroundColor: "#850b03",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            >
              {selectedFile ? "Arquivo Selecionado" : "Selecionar Arquivo"}
            </button>
          </div>
        )}
      </Dropzone>
      {selectedFile && (
        <button
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            padding: "10px 20px",
            cursor: "pointer",
            marginLeft: "10px",
            borderRadius: "10px",
          }}
          onClick={onFileUploadHandler}
        >
          Enviar Arquivo
        </button>
      )}
      <p id="msg" style={{ marginLeft: "10px", color: "#850b03" }}></p>
    </div>
  );
};
export default UploadDocuments;
