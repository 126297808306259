import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment personFields on person {
    first_name
    last_name
    email
    cell_phone
    dob
    gender
    cpf
    rg
    occupation
    address1
    address2
    n_address
    district
    city
    state
    postal_code
    country
  }
`;

export const GET = gql`
  query getStudent($id: Int, $limit: Int) {
    person(where: {id: {_eq: $id}, _or: [{enrollments: {id: {_is_null: false}}}, {applications: {id: {_is_null: false}}}],deleted_at: {_is_null: true}}, limit: $limit, order_by: {id: desc}) {
      id
      ...personFields
    }
  }
  ${FieldsFragment}
`;


export const INSERT = gql`
    mutation newPerson($objects: [person_insert_input!]!) {
        insert_person(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updatePerson($_set: person_set_input={}, $id: Int){
    update_person(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`

export const fieldtypes = { //mudar tipos dos campos
  email: "email",
  dob: "date"
}
