/* eslint-disable no-mixed-operators */
/* eslint-disable default-case */
import React, { useState } from "react";
import { Button, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CloseIcon from "@material-ui/icons/Close";
import { observer } from "mobx-react-lite";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

const InvoiceTable = observer((props) => {
  const [state, setState] = useState({
    open: false,
  });
  var columns = [];
  var payments = [];
  const query = gql`
    query payment($plan: Int, $personId: Int) {
      payment(
        where: {
          _or: [
            { payment_plan: { _eq: $plan } }
            { person: { _eq: $personId } }
          ]
          deleted_at: { _is_null: true }
        }
      ) {
        id
        payment_id
        disabled
        payment_plan
        value
        status
        due_date
        description
        link
        org
        paid_at
        platform
      }
    }
  `;
  let plan = props.payment_plan;
  if (!plan) plan = 0;
  var { data, error, loading, refetch } = useQuery(query, {
    variables: { plan, personId: props.personId },
  });
  if (loading) {
    columns = ["msg"];
    payments = [{ msg: "Buscando no BD..." }];
  }
  if (error) {
    columns = ["Erro"];
    payments = [{ Erro: JSON.stringify(error.message) }];
  }
  if (data) {
    columns = [
      "id",
      "vencimento",
      "valor",
      {
        name: "status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            var style = {
              borderRadius: "5px",
              paddingLeft: "5px",
              paddingRight: "5px",
              textAlign: "center",
            };
            switch (value) {
              case "paid":
                style.background = "lightgreen";
                break;
              case "pending":
                style.background = "yellow";
                break;
              case "expired":
                style.background = "lightgray";
                break;
            }
            return (
              <>
                <div style={style}>{value}</div>
              </>
            );
          },
        },
      },
      {
        name: "atraso",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <div style={{ color: "red" }}>{value}</div>
              </>
            );
          },
        },
      },
      "descrição",
      "data_pagamento",
      "link",
      "plano_atual",
    ];
    payments = data.payment.map((payment) => {
      var atraso = null;
      var today = new Date().toISOString().split("T")[0];
      var current_plan =
        payment.payment_plan && payment.payment_plan === props.payment_plan
          ? "SIM"
          : null;
      if (
        (payment.status === "pending" ||
          (payment.status === "expired" && !payment.disabled)) &&
        today > payment.due_date
      )
        atraso = "ATRASO";
      var valor = "R$ " + payment.value;
      return {
        id: payment.payment_id,
        vencimento: payment.due_date,
        valor,
        status: payment.status,
        atraso,
        descrição: payment.description,
        data_pagamento: payment.paid_at,
        link: payment.link,
        plano_atual: current_plan,
        org: payment.org,
        payment_id: payment.id,
        value: payment.value,
        platform: payment.platform,
      };
    });
  }

  return (
    <>
      <IconButton
        onClick={() => {
          refetch();
        }}
        style={{ float: "right" }}
      >
        <RefreshIcon />
      </IconButton>
      <p />
      <MUIDataTable
        title={"Dados do Banco de Dados"}
        data={payments}
        columns={columns}
        options={{
          responsive: "scroll",
          rowsPerPageOptions: [10, 50, 100],
          rowsPerPage: 100,
          selectableRows: "multiple",
          filterType: "multiselect",
          sortOrder: {
            name: "vencimento",
            direction: "asc",
          },
          onCellClick: (_, cmeta) => {
            const fatura = payments[cmeta.dataIndex];
            if (Object.keys(fatura)[cmeta.colIndex] === "link") {
              window.open(fatura.link, "_blank");
            } else {
              console.log(fatura);
              setState({ ...state, fatura: fatura, open: true });
            }
          },
          customToolbarSelect: (selectedRows) => (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  window.confirm("Cancelar faturas?") &&
                    selectedRows.data.forEach(async (row) => {
                      let fatura = payments[row.dataIndex];
                      await props.hidePayment(fatura);
                    });
                }}
              >
                Cancelar faturas
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  selectedRows.data.forEach(async (row) => {
                    let fatura = payments[row.dataIndex];
                    await props.setPlan(fatura, props.payment_plan);
                  });
                }}
              >
                Incluir no plano atual
              </Button>
            </>
          ),
        }}
      />
      <Dialog
        open={state.open}
        onClose={() => {
          setState({ ...state, open: false });
        }}
      >
        <div className="m10" style={{ textAlign: "center" }}>
          <IconButton
            onClick={() => {
              setState({ ...state, open: false });
            }}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
          <p />
          <DialogTitle>Opções da fatura</DialogTitle>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={async () => {
              await props.hidePayment(state.fatura);
              refetch();
            }}
          >
            Cancelar fatura
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
            onClick={async () => {
              await props.setPlan(state.fatura, props.payment_plan);
              refetch();
            }}
          >
            Incluir no plano atual
          </Button>
        </div>
      </Dialog>
    </>
  );
});
export default InvoiceTable;
