import React, { useState } from "react";
import Papa from "papaparse";
import moment from "moment";
import "../css/AttendanceCheck.css";
import { Button } from "@material-ui/core";

function AttendanceCheck() {
  const [file, setFile] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [requiredTime, setRequiredTime] = useState("");
  const [userList, setUserList] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const calculatePresence = (event) => {
    event.preventDefault();
    if (!moment(startTime, "DD/MM/YYYY hh:mm:ss A").isValid()) {
      alert("Início da reunião inválido. Use o formato DD/MM/YYYY hh:mm:ss A.");
      return;
    }
    if (!moment(endTime, "DD/MM/YYYY hh:mm:ss A").isValid()) {
      alert("Fim da reunião inválido. Use o formato DD/MM/YYYY hh:mm:ss A.");
      return;
    }

    let reader = new FileReader();
    reader.onload = function(event) {
      let data = event.target.result;

      let result = Papa.parse(data, {
        header: true,
        skipEmptyLines: true,
      });

      let userTime = {};
      let reuniaoInicio = moment(startTime, "DD/MM/YYYY hh:mm:ss A");
      let reuniaoFim = moment(endTime, "DD/MM/YYYY hh:mm:ss A");
      result.data.forEach((row) => {
        if (!row.hasOwnProperty("Nome (Nome original)")) {
          console.error('Linha sem a chave "Nome (Nome original)":', row);
          return;
        }

        let nome = row["Nome (Nome original)"].trim();
        let entrada = moment(
          row["Horário de entrada"],
          "DD/MM/YYYY hh:mm:ss A"
        );
        let saida = moment(row["Horário de saída"], "DD/MM/YYYY hh:mm:ss A");

        if (entrada.isBefore(reuniaoInicio)) {
          entrada = reuniaoInicio;
        }

        if (saida.isAfter(reuniaoFim)) {
          saida = reuniaoFim;
        }

        if (
          entrada.isSameOrBefore(reuniaoFim) &&
          saida.isSameOrAfter(reuniaoInicio)
        ) {
          if (nome in userTime) {
            userTime[nome] += saida.diff(entrada, "minutes");
          } else {
            userTime[nome] = saida.diff(entrada, "minutes");
          }
        }
      });

      let list = [];
      for (let user in userTime) {
        let status = userTime[user] >= requiredTime ? "Presença" : "Falta";
        list.push({
          name: user,
          time: userTime[user],
          status: status,
        });
      }

      list.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setUserList(list);
      setShowTable(true);
    };

    reader.readAsText(file);
  };

  return (
    <div className="container-csv">
      <p>
        Os dados de início e fim da reunião devem seguir esse formato:{" "}
        <b>25/04/2023 08:00:00 AM</b>
      </p>
      <form onSubmit={calculatePresence} className="form-csv">
        <label>
          Arquivo CSV:
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            required
          />
        </label>
        <label>
          Início da reunião:
          <input
            type="text"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            required
          />
        </label>
        <label>
          Fim da reunião:
          <input
            type="text"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            required
          />
        </label>
        <label>
          Tempo necessário para presença:(em minutos)
          <input
            type="number"
            value={requiredTime}
            onChange={(e) => setRequiredTime(e.target.value)}
            required
          />
        </label>
        <Button type="submit" color="primary" variant="contained">
          Calcular presença
        </Button>
      </form>
      {showTable && (
        <table className="table-csv">
          <thead>
            <tr className="tr-csv">
              <th className="th-csv">Nome</th>
              <th className="th-csv">Tempo</th>
              <th className="th-csv">Status</th>
            </tr>
          </thead>
          <tbody className="tbody-csv">
            {userList.map((user, index) => (
              <tr key={index}>
                <td className="td-csv">{user.name}</td>
                <td className="td-csv">{user.time} minutos</td>
                <td
                  className={
                    user.status === "Falta" ? "td-falta-csv" : "td-csv-presenca"
                  }
                >
                  {user.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AttendanceCheck;
